import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setTargetCoin,
  setTradeType,
} from "../../../redux/trade-form/tradeFormSlice";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import ApplyAiSuggestion from "./ApplyAiSuggestion";
import SectionLabel from "./SectionLabel";

function SelectTradeType() {
  const {
    tradeForm: { tradeType, targetPortfolioStatus },
    exchangeState: { availableCoinsStatus },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const selectTradeType = (type) => {
    return () => {
      // dispatch(setTargetCoin(""));
      dispatch(setTradeType(type));
    };
  };

  return (
    <div className={styles.box}>
      <SectionLabel label="Trade Type" style={{ marginBottom: ".5rem" }}>
        <ApplyAiSuggestion field="tradeType" style={{ marginLeft: ".2rem" }} />
      </SectionLabel>
      <div className={styles.tradeTypeBtns}>
        <span
          className={`${styles.tradeType} ${tradeType === "buy" ? styles.selectedTradeType : ""}`}
          onClick={selectTradeType("buy")}
        >
          <FaArrowCircleUp />
          Buy
        </span>
        <span
          className={`${styles.tradeType} ${tradeType === "sell" ? styles.selectedTradeType : ""}`}
          onClick={selectTradeType("sell")}
        >
          <FaArrowCircleDown />
          Sell
        </span>
      </div>

      {(targetPortfolioStatus !== "success" ||
        availableCoinsStatus === "loading") && (
        <div className={styles.overlayMask}></div>
      )}
    </div>
  );
}

export default SelectTradeType;
