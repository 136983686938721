import useAssetAmount from "../../hooks/useAssetAmount";
import ErrorMessage from "./ErrorMessage";
import useCheckPortfolio from "../../hooks/useCheckPortfolio";

function NotAvailableAsset() {
  const { isAssetAvailable } = useAssetAmount();
  const { isPortfolioEmpty } = useCheckPortfolio();

  if (isAssetAvailable || isPortfolioEmpty) return null;

  return <ErrorMessage>This asset is not in your portfolio.</ErrorMessage>;
}

export default NotAvailableAsset;
