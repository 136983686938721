import { Skeleton } from "antd";
import styles from "../../../styles/Components/trading-assistant/tradeAssistantCards.module.sass";

function TradeAssistantSkeletonCard() {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <Skeleton.Input style={{ width: 120, height: 24 }} active />
      </div>
      <Skeleton.Button style={{ width: "100%", height: 32 }} active />
      <div className={styles.signalSection}>
        <div className={styles.signal}>
          <span className={styles.signalLabel}>1H Signal:</span>
          <div className={styles.signalInfo}>
            <Skeleton.Input style={{ width: 200, height: 20 }} active />
          </div>
        </div>
        <div className={styles.signal}>
          <span className={styles.signalLabel}>15m Signal:</span>
          <div className={styles.signalInfo}>
            <Skeleton.Input style={{ width: 200, height: 20 }} active />
          </div>
        </div>
      </div>
      <div className={styles.infoSection}>
        {[...Array(4)].map((_, i) => (
          <div key={i} className={styles.infoItem}>
            <Skeleton.Input style={{ width: "50%", height: 20 }} active />
            <Skeleton.Input style={{ width: "50%", height: 20 }} active />
          </div>
        ))}
      </div>
      <Skeleton.Button style={{ width: "100%", height: 40 }} active />
    </div>
  );
}

export default TradeAssistantSkeletonCard;
