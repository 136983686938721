/** @format */

import { InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  setStopLossTriggerPrice,
  setTakeProfitTriggerPrice,
} from "../../../../redux/trade-form/tradeFormSlice";
import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import useCheckOrderLimits from "../../hooks/useCheckOrderLimits";

function LimitOrderTriggerPrice({ limitType, title = true, style = {} }) {
  const {
    tradeForm: {
      targetFee,
      assetPrice,
      assetPriceStatus,
      slTriggerPrice,
      tpTriggerPrice,
    },
  } = useSelector((state) => state);

  const [percentage, setPercentage] = useState(limitType === "high" ? 2 : 1);
  const [triggerPrice, setTriggerPrice] = useState();

  const { amountPrecision, pricePrecision } = useCheckOrderLimits();

  const minimumPercentage = limitType === "high" ? targetFee : 0.01;
  const maximumPercentage = limitType === "high" ? 99.99 : 99.99;

  const dispatch = useDispatch();

  const disabled = !assetPrice && assetPriceStatus !== "success";

  // console.log(pricePrecision, maximumPercentage, minimumPercentage, percentage);

  const changePercentage = (value) => {
    if (
      !value ||
      Number(value) > maximumPercentage ||
      Number(value) < minimumPercentage
    )
      return;

    setPercentage(value);

    // const adjustedValue = Math.max(value, minimumPercentage);
    // setPercentage(roundToPrecision(adjustedValue, 2));

    if (limitType === "high") {
      // dispatch(setTakeProfitTriggerPrice(adjustedValue / 100));
      dispatch(setTakeProfitTriggerPrice(value / 100));
      setTriggerPrice(
        // roundToPrecision(, pricePrecision),
        assetPrice * (1 + value / 100),
      );
      // setTriggerPrice(
      //   roundToPrecision(
      //     assetPrice * (1 + adjustedValue / 100),
      //     pricePrecision,
      //   ),
      // );
    } else {
      // dispatch(setStopLossTriggerPrice(adjustedValue / 100));
      dispatch(setStopLossTriggerPrice(value / 100));
      setTriggerPrice(
        // roundToPrecision(, pricePrecision),
        assetPrice * (1 - value / 100),
      );
      // setTriggerPrice(
      //   roundToPrecision(
      //     assetPrice * (1 - adjustedValue / 100),
      //     pricePrecision,
      //   ),
      // );
    }
  };

  // set default percentage and trigger price
  useEffect(() => {
    if (assetPrice) {
      if (limitType === "high" && !tpTriggerPrice) {
        const defaultPercentage = 2;
        setPercentage(defaultPercentage);
        setTriggerPrice(
          assetPrice * (1 + defaultPercentage / 100),
          // roundToPrecision(
          //   assetPrice * (1 + defaultPercentage / 100),
          //   pricePrecision,
          // ),
        );
        dispatch(setTakeProfitTriggerPrice(defaultPercentage / 100));
      }

      if (limitType === "low" && !slTriggerPrice) {
        const defaultPercentage = 1;
        setPercentage(defaultPercentage);
        setTriggerPrice(
          // roundToPrecision(
          //   assetPrice * (1 - defaultPercentage / 100),
          //   pricePrecision,
          // ),
          assetPrice * (1 - defaultPercentage / 100),
        );
        dispatch(setStopLossTriggerPrice(defaultPercentage / 100));
      }
    }
  }, [
    limitType,
    assetPrice,
    dispatch,
    slTriggerPrice,
    tpTriggerPrice,
    pricePrecision,
  ]);

  useEffect(() => {
    if (assetPrice) {
      if (limitType === "high" && tpTriggerPrice) {
        setPercentage(tpTriggerPrice * 100);
        setTriggerPrice(
          // roundToPrecision(assetPrice * (1 + tpTriggerPrice), pricePrecision),
          assetPrice * (1 + tpTriggerPrice),
        );
      }

      if (limitType === "low" && slTriggerPrice) {
        setPercentage(slTriggerPrice * 100);
        setTriggerPrice(
          // roundToPrecision(assetPrice * (1 - slTriggerPrice), pricePrecision),
          assetPrice * (1 - slTriggerPrice),
        );
      }
    }
  }, [limitType, assetPrice, tpTriggerPrice, slTriggerPrice, pricePrecision]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", width: "fit-content" }}
    >
      <div
        className={styles.setAmountBox}
        style={{ marginTop: ".5rem", ...style }}
      >
        {/*{title && (*/}
        {/*  <h4>*/}
        {/*    <AiFillCaretRight />*/}
        {/*    Trigger price*/}
        {/*    <ApplyAiSuggestion field={limitType === "high" ? "tp" : "sl"} />*/}
        {/*  </h4>*/}
        {/*)}*/}
        <InputNumber
          // min={minimumPercentage}
          // max={maximumPercentage}
          min={Number(targetFee * 100)}
          max={99.99}
          onChange={changePercentage}
          step={0.01}
          disabled={disabled}
          value={percentage}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace("%", "")}
          className={styles.input}
        />
      </div>
      {triggerPrice && (
        <div
          style={{
            alignSelf: "flex-end",
            fontSize: "12px",
            marginTop: "0.1rem",
            color: "#666",
          }}
        >
          ${triggerPrice}
        </div>
      )}
    </div>
  );
}

export default LimitOrderTriggerPrice;
