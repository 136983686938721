import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import styles from "../../styles/pages/alert-system/index.module.sass";
import TrackedSignals from "../../components/alert-system/TrackedSignals";
import { useDispatch, useSelector } from "react-redux";
import {
  getAlertStatus,
  getPreviousEmails,
  getTrackedSignal,
} from "../../redux/alert-system/actions";
import PreviousEmails from "../../components/tables/PreviousEmails";
import AlertSystem2 from "../../components/alert-system/AlertSystem2";
import { useParams } from "react-router";
import ServiceStatusBar from "../../components/mini-app/shared/ServiceStatusBar";
import DisplayRatingModal from "../../shared/DisplayRatingModal";
import usePageTitle from "../../hooks/usePageTitle";

const AlertSystem = () => {
  const dispatch = useDispatch();
  const {
    main: { userData },
    alertSystem: {
      trackedSignals,
      loading,
      error,
      alertStatus,
      previousEmails,
    },
    profile: { profileData },
  } = useSelector((state) => state);

  const { section } = useParams();

  const [showDemo, setShowDemo] = useState(true);
  usePageTitle("Alert System");

  useEffect(() => {
    if (userData?.["cognito:username"]) {
      if (!section && !alertStatus?.STATUS && !loading) {
        dispatch(
          getAlertStatus(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
          ),
        );
      }
      if (section === "tracked-signals") {
        dispatch(
          getTrackedSignal(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
          ),
        );
      }
      if (section === "previous-emails") {
        dispatch(
          getPreviousEmails(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
          ),
        );
      }
    }
  }, [userData, section, dispatch]);

  return (
    <DashboardLayout
      alertSystem
      error={error}
      loading={loading}
      hasDemo={showDemo}
    >
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <ServiceStatusBar
            status={profileData?.alert_active}
            expirationDate={profileData?.alert_active_until}
            service={"alert"}
            platform
          />
          {!section && (
            <AlertSystem2
              data={alertStatus}
              showDemo={() => setShowDemo(true)}
              hideDemo={() => setShowDemo(false)}
            />
          )}
          {section === "tracked-signals" && (
            <TrackedSignals data={trackedSignals?.LIST_SIGNALS} />
          )}
          {section === "previous-emails" && (
            <PreviousEmails data={previousEmails?.Items} />
          )}
        </div>
      </div>

      {/* <DisplayRatingModal
        page="alert"
        showRatingModal={showRatingModal}
        setVisitedPage={setVisitedPage}
        condition={Object.keys(alertStatus).length > 0}
      /> */}
    </DashboardLayout>
  );
};

export default AlertSystem;
