import styles from "../../../styles/Components/trade-assistant/assistant/AiAssistantTableActionButtons.module.css";
import ActionButtonsContainer from "../../../ui/ActionButtonsContainer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import useDownloader from "react-use-downloader";
import {
  getAllTradeSuggestion,
  getTradeAssistant,
  setAiAssistantMode,
  setAIMarketType,
} from "../../../redux/trading-assistant/actions";
import { FiRefreshCcw } from "react-icons/fi";
import {
  FaBalanceScale,
  FaBell,
  FaBitcoin,
  FaChartLine,
  FaExclamationTriangle,
  FaLink,
  FaMoneyBillWave,
  FaRobot,
  FaShieldAlt,
} from "react-icons/fa";
import { BsExclamationTriangleFill, BsPeople } from "react-icons/bs";
import { HiRocketLaunch } from "react-icons/hi2";
import { AiTwotoneFire } from "react-icons/ai";
import { updateProfile } from "../../../redux/profile/actions";
import { BiCategory } from "react-icons/bi";
import { MdShowChart } from "react-icons/md";
import { getBotList } from "../../../redux/bot/actions";
import { getAlertStatus } from "../../../redux/alert-system/actions";
import { Link } from "react-router-dom";

const categoryItems = [
  {
    key: "null",
    label: "All Categories",
    icon: <BiCategory />, // General category icon
  },
  {
    key: "only_ai",
    label: "AI",
    icon: <AiTwotoneFire />, // Represents AI and innovation
  },
  {
    key: "only_social",
    label: "Social",
    icon: <BsPeople />, // Represents social networks
  },
  {
    key: "only_on_chain",
    label: "On Chain",
    icon: <FaLink />, // Represents blockchain and connectivity
  },
  {
    key: "only_ta",
    label: "Technical Indicators",
    icon: <MdShowChart />, // Represents charts and technical analysis
  },
];

const riskOptions = [
  {
    label: "Very Cautious",
    key: "1",
    icon: <FaShieldAlt />,
  },
  {
    label: "Cautious",
    key: "2",
    icon: <BsExclamationTriangleFill />,
  },
  {
    label: "Balanced",
    key: "3",
    icon: <FaBalanceScale />,
  },
  {
    label: "Adventurous",
    key: "4",
    icon: <HiRocketLaunch />,
  },
  {
    label: "Very Adventurous",
    key: "5",
    icon: <AiTwotoneFire />,
  },
];

const marketItems = [
  {
    label: "Crypto",
    key: "crypto",
    icon: <FaBitcoin />, // Represents cryptocurrency
  },
  {
    label: "Forex",
    key: "forex",
    icon: <FaMoneyBillWave />, // Represents currency exchange
  },
  {
    label: "Stock",
    key: "asx",
    icon: <FaChartLine />, // Represents stock market trends
    disabled: true,
  },
];

function AiAssistantTableActionButtons() {
  const {
    profile: { profileData = {} },
    tradingAssistant: {
      tradeAssistant: { export_links } = {},
      getTradeAssistantLoading,
      aiMarketType,
      selectedPortfolio,
      aiAssistantMode,
    },
    main: { userData },
    botState: { getBotListLoading, fetchedBotList, getBotListError, botList },
    alertSystem: { loading, alertStatus },
  } = useSelector((state) => state);

  const [risk, setRisk] = useState(
    profileData?.ai_assistant_risk_appetite || "1",
  );

  const botStatus = botList?.find((bot) => bot?.bot_status === "active")
    ? "Active"
    : "Inactive";

  const alert = alertStatus?.STATUS === "True" ? "On" : "Off";

  const currentCategory =
    categoryItems?.find((item) => item.key === aiAssistantMode)?.label || "All";

  const currentRisk = riskOptions?.find((item) => item.key === risk)?.label;

  const currentMarket = marketItems?.find(
    (item) => item.key === aiMarketType,
  )?.label;

  const dispatch = useDispatch();

  const isServiceActive = profileData?.myais_active === "True";

  const disabledStyle = {
    opacity: `${getTradeAssistantLoading ? 0.8 : 1}`,
    cursor: `${getTradeAssistantLoading ? "not-allowed" : "pointer"}`,
  };

  const handleRefresh = () => {
    if (getTradeAssistantLoading) return;
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio.toLowerCase(),
        aiMarketType,
        isServiceActive,
        aiAssistantMode,
      ),
    );
    dispatch(
      getAllTradeSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        aiMarketType,
        isServiceActive,
      ),
    );
  };

  const handleRiskAppetite = (e) => {
    let attribute_names = ["ai_assistant_risk_appetite"];
    setRisk(e.key);

    dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        attribute_names,
        [e.key],
      ),
    );
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio,
        aiMarketType,
        isServiceActive,
        aiAssistantMode,
      ),
    );
  };

  const handleChangeCategory = (e) => {
    dispatch(setAiAssistantMode(e.key));
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio,
        aiMarketType,
        isServiceActive,
        e.key,
      ),
    );
  };

  const handleChangeMarketType = (e) => {
    dispatch(setAIMarketType(e.key));
    dispatch(
      getTradeAssistant(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio?.toLowerCase() || "primary",
        e.key,
        isServiceActive,
        aiAssistantMode,
      ),
    );
  };

  const riskMenu = useMemo(() => {
    return {
      items: riskOptions,
      onClick: handleRiskAppetite,
      selectedKeys: [risk],
    };
  }, [handleRiskAppetite, risk]);

  const categoryMenu = {
    items: categoryItems,
    onClick: handleChangeCategory,
    selectedKeys: [aiAssistantMode || "null"],
  };

  const marketMenu = {
    items: marketItems,
    onClick: handleChangeMarketType,
    defaultSelectedKeys: [aiMarketType || "null"],
  };

  useEffect(() => {
    if (
      userData?.email &&
      // !botList.length &&
      !fetchedBotList &&
      !getBotListLoading &&
      !getBotListError
    ) {
      dispatch(
        getBotList(
          userData["custom:custom_username"],
          userData["cognito:username"],
        ),
      );
    }
  }, [dispatch, fetchedBotList, getBotListError, getBotListLoading, userData]);

  useEffect(() => {
    if (userData?.email && !alertStatus?.STATUS && !loading) {
      dispatch(
        getAlertStatus(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [alertStatus?.STATUS, dispatch, loading, userData]);

  return (
    <div className={styles.container}>
      {/*{isServiceActive && (*/}
      {/*  <Dropdown menu={exportMenu} trigger={["click"]}>*/}
      {/*    <li className={styles.button} style={disabledStyle}>*/}
      {/*      <span>*/}
      {/*        <TbFileExport />*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*  </Dropdown>*/}
      {/*)}*/}
      <li className={styles.button}>
        <Button loading={getTradeAssistantLoading} onClick={handleRefresh}>
          {!getTradeAssistantLoading && <FiRefreshCcw color="#2A86FF" />}
          Refresh
        </Button>
      </li>

      <li className={`${styles.button} ${styles.displayOnly}`}>
        <Link to="/bot-automation">
          <FaRobot color="#2A86FF" size={15} />
          Bot: <span className={styles.status}>{botStatus}</span>
        </Link>
      </li>

      <li className={`${styles.button} ${styles.displayOnly}`}>
        <Link to="/alert-system">
          <FaBell color="#2A86FF" size={15} />
          Alert: <span className={styles.status}>{alert}</span>
        </Link>
      </li>

      <Dropdown
        menu={riskMenu}
        trigger={["click"]}
        disabled={getTradeAssistantLoading}
      >
        <li className={styles.button} style={disabledStyle}>
          <FaExclamationTriangle color="#2A86FF" />
          Risk:
          <span className={styles.status}>{currentRisk}</span>
        </li>
      </Dropdown>
      <Dropdown
        menu={categoryMenu}
        trigger={["click"]}
        disabled={getTradeAssistantLoading}
      >
        <li className={styles.button} style={disabledStyle}>
          <BiCategory color="#2A86FF" />
          Category:
          <span className={styles.status}>{currentCategory}</span>
        </li>
      </Dropdown>

      <Dropdown
        menu={marketMenu}
        trigger={["click"]}
        disabled={getTradeAssistantLoading}
      >
        <li className={styles.button} style={disabledStyle}>
          <FaChartLine color="#2A86FF" />
          Market type:
          <span className={styles.status}>{currentMarket}</span>
        </li>
      </Dropdown>
    </div>
  );
}

export default AiAssistantTableActionButtons;
