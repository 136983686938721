import logo from "../asset/Images/cryptocrispyLogo.png";
import styles from "../styles/pages/WelcomePage.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getProfileData } from "../redux/profile/actions";
import ErrorBox from "../components/mini-app/shared/ErrorBox";
import { LazyLoadImage } from "react-lazy-load-image-component";

const WelcomePage = () => {
  const {
    main: { userData },
    profile: { profileData, loading, error },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (profileData?.user) {
      if (profileData?.answer_date) {
        navigate("/dashboard");
      } else {
        navigate("/ai-assistant/assistant");
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (
      userData?.email &&
      !loading &&
      !Object.keys(profileData).length &&
      !error
    ) {
      dispatch(
        getProfileData(
          userData?.email,
          userData?.["custom:custom_username"],
          "fast",
        ),
      );
    }
  }, [profileData, loading, userData, dispatch, error]);

  return (
    <div className={styles.welcomeContainer}>
      {!error && (
        <>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              <LazyLoadImage
                src={logo}
                alt="cryptocrispyLogo"
                width="103px"
                height="46px"
                effect="blur"
              />
            </div>
            <div className={styles.loader}></div>
          </div>
          <div className={styles.welcomeText}>
            <p>WELCOME TO CRYPTOCRISPY</p>
          </div>
        </>
      )}
      {error && <ErrorBox />}
    </div>
  );
};

export default WelcomePage;
