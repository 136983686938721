import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import styles from "../../styles/pages/trading-assistant/index.module.sass";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTradeSuggestion,
  getTradeAssistant,
  setVirtualPortfolio,
} from "../../redux/trading-assistant/actions";
import { useNavigate, useParams } from "react-router-dom";
import Portfolio from "../../components/trading-assistant/Portfolio";
import History from "../../components/trading-assistant/History";
import Assistant from "../../components/trading-assistant/Assistant";
import ServiceStatusBar from "../../components/mini-app/shared/ServiceStatusBar";
import DisplayRatingModal from "../../shared/DisplayRatingModal";
import usePageTitle from "../../hooks/usePageTitle";
import useServiceStatus from "../../hooks/useServiceStatus";

const Index = () => {
  const dispatch = useDispatch();
  const {
    main: { userData },
    tradingAssistant: {
      loading,
      error,
      selectedPortfolio,
      aiMarketType,
      allSuggestion,
      tradeAssistant,
      allTradeSuggestionData,
    },
    profile: { profileData },
  } = useSelector((state) => state);
  const [portfolio, setPortfolio] = useState(
    selectedPortfolio?.toLowerCase() || "primary",
  );

  const { isActive } = useServiceStatus("aiAssistant");

  const navigate = useNavigate();
  const { section } = useParams();
  const [cache, setCache] = useState({
    p1: false,
    p2: false,
    s1: false,
    s2: false,
    3: false,
  });

  const [modeKey, setModeKey] = useState("");
  usePageTitle("AI Assistant");

  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      allTradeSuggestionData?.length === 0 &&
      !tradeAssistant?.risk_taking
    ) {
      if (
        section === "assistant" &&
        !cache?.["3"] &&
        !localStorage.getItem("assistantAtatus") &&
        profileData?.email
      ) {
        dispatch(
          getTradeAssistant(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            portfolio,
            aiMarketType,
            isActive,
          ),
        );
        dispatch(
          getAllTradeSuggestion(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            aiMarketType,
            isActive,
          ),
        );
        setCache((prev) => ({
          ...prev,
          3: true,
        }));
      }
    }
  }, [
    cache,
    dispatch,
    portfolio,
    section,
    userData,
    aiMarketType,
    profileData,
    isActive,
    allTradeSuggestionData,
    tradeAssistant,
  ]);
  useEffect(() => {
    if (section === "assistant") {
      localStorage.setItem("assistantAtatus", "loaded");
    }
  }, [section]);

  useEffect(() => {
    if (portfolio) {
      let selectedPortfolio = portfolio === "primary" ? "Primary" : "Secondary";
      dispatch(setVirtualPortfolio(selectedPortfolio));
    }
  }, [dispatch, portfolio]);

  useEffect(() => {
    if (selectedPortfolio) {
      const portfolio =
        selectedPortfolio.toLowerCase() === "primary" ? "primary" : "secondary";
      setPortfolio(portfolio);
    }
  }, [selectedPortfolio]);

  // useEffect(() => {
  //
  // }, [aiMarketType]);

  useEffect(() => {
    if (!section) {
      navigate("/ai-assistant/assistant");
    }
  }, [navigate, section]);

  return (
    <DashboardLayout aiLayout loading={loading} error={error} hasDemo>
      <div className={styles.tradeAssistantWrapper}>
        {/*<Navbar*/}
        {/*  changePortfolio={setPortfolio}*/}
        {/*  proUser={proUser}*/}
        {/*  portfolio={portfolio}*/}
        {/*/>*/}
        <div className={styles.main}>
          {section !== "assistant" && (
            <ServiceStatusBar
              status={profileData?.myais_active}
              expirationDate={profileData?.myais_active_until}
              service={"myais"}
              platform
            />
          )}

          {section === "portfolio" && <Portfolio portfolio={portfolio} />}
          {section === "trade-history" && (
            <History portfolio={portfolio} section={section} />
          )}
          {section === "assistant" && (
            <Assistant
              portfolio={portfolio}
              market={aiMarketType}
              setAIMode={setModeKey}
              aiMode={modeKey}
              // showRatingModal={showRatingModal}
              // setVisitedPage={setVisitedPage}
            />
          )}
        </div>
      </div>

      {/* <DisplayRatingModal
        page="ai"
        showRatingModal={showRatingModal}
        setVisitedPage={setVisitedPage}
        condition={
          allSuggestion.length > 0 && tradeAssistant?.risk_taking && isActive
        }
      /> */}
    </DashboardLayout>
  );
};

export default Index;
