import styles from "../../../../styles/Components/bot/bot-configuration/CreateBotSteps.module.css";
import { FaCaretRight } from "react-icons/fa";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import StepTitle from "./StepTitle";
import { useSelector } from "react-redux";

function CreateBotStep2({ botData, changeBotData }) {
  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      portfolioLoading,
      selectedPortfolioExchange,
    },
    botState: { createBotLoading, createBotResponse },
    main: { userData },
  } = useSelector((state) => state);

  const exchangeList = [...integratedData]
    .filter((item) => item?.status === "Active")
    .sort((a, b) => (b?.id === "cryptocrispy" ? 1 : -1));

  useEffect(() => {
    if (integratedData.length > 0) {
      changeBotData("selected_exchange", exchangeList[0]?.id);
    }
  }, [integratedData]);

  return (
    <>
      <StepTitle title={"Exchange Selection"} />
      <div className={styles.stepContent}>
        <h4 className={styles.stepTitle}>
          <FaCaretRight />
          Select the exchange you want to trade? (you can skip this step)
        </h4>
        {integratedDataLoading ? (
          <Spin tip={"Fetching integrated exchanges..."} />
        ) : (
          <>
            {!integratedData.length && (
              <p>
                No exchange integrated yet.{" "}
                <Link
                  className={styles.integrationLink}
                  to={"/integration"}
                  target={"_blank"}
                >
                  Integrate now!
                </Link>
              </p>
            )}

            {integratedData.length > 0 && (
              <ul className={styles.options}>
                {exchangeList.map((item) => (
                  <li
                    className={`${styles.optionItem} ${
                      botData.selected_exchange === item.id
                        ? styles.selectedOption
                        : ""
                    } ${styles.exchange} ${
                      item.id === "cryptocrispy" ? styles.virtual : ""
                    }`}
                    key={item.id}
                    onClick={() => changeBotData("selected_exchange", item.id)}
                  >
                    <img
                      src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${item.id}.svg`}
                      alt={item.id}
                    />
                    <span>{item.name}</span>
                  </li>
                ))}
                <li
                  className={`${styles.optionItem} ${
                    botData.selected_exchange === "None"
                      ? styles.selectedOption
                      : ""
                  }`}
                  onClick={() => changeBotData("selected_exchange", "None")}
                >
                  None
                </li>
              </ul>
            )}
          </>
        )}
      </div>
      {/*<div className={styles.stepContent}>*/}
      {/*  <h4 className={styles.stepTitle}>*/}
      {/*    <FaCaretRight />*/}
      {/*    Do you want to do trades in virtual portfolio 1 too?*/}
      {/*  </h4>*/}
      {/*  <ul className={styles.options}>*/}
      {/*    <li*/}
      {/*      className={`${styles.optionItem} ${*/}
      {/*        botData.trade_in_virtual_portfolio === "True"*/}
      {/*          ? styles.selectedOption*/}
      {/*          : ""*/}
      {/*      }`}*/}
      {/*      onClick={() => changeBotData("trade_in_virtual_portfolio", "True")}*/}
      {/*    >*/}
      {/*      Yes*/}
      {/*    </li>*/}
      {/*    <li*/}
      {/*      className={`${styles.optionItem} ${*/}
      {/*        botData.trade_in_virtual_portfolio === "False"*/}
      {/*          ? styles.selectedOption*/}
      {/*          : ""*/}
      {/*      }`}*/}
      {/*      onClick={() => changeBotData("trade_in_virtual_portfolio", "False")}*/}
      {/*    >*/}
      {/*      No*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </>
  );
}

export default CreateBotStep2;
