import axios from "axios";
import { axiosErrorHandler } from "../../utility/action-helper";
// import { GET_ALL_TRADE_SUGGESTION_SUCCESS } from "../trading-assistant/consts";
import { getLiveSuggestion } from "../live-suggestion/liveSuggestionSlice";
import alert from "../../components/alerts/Alert";

const initialState = {
  analyze: [],
  status: "",
  errorMessage: "",
};

export const otherCoinsAnalyze = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "analyze_other_coins_request":
      return {
        ...state,
        status: "loading",
        errorMessage: "",
      };

    case "analyze_other_coins_success":
      // console.log(
      //   "analyze data:",
      //   [payload, ...state.analyze],
      //   state.analyze,
      //   payload,
      // );
      return {
        ...state,
        status: "success",
        analyze: [payload, ...state.analyze],
      };

    case "analyze_other_coins_error":
      return {
        ...state,
        status: "error",
      };
    default:
      return state;
  }
};

export const analyzeOtherCoins =
  (
    user,
    email,
    market_type,
    other_coins_to_analyse,
    exchange_id,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: "analyze_other_coins_request" });

    await axios
      .post(
        "https://hav8w1xk8h.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          user,
          email,
          market_type,
          exchange_id,
          request_type: "get_trade_suggestion",
          other_coins_to_analyse,
        },
      )
      .then((res) => {
        console.log(res);
        const analyzeResult = res?.data?.other_coins_analyse;

        // dispatch({
        //   type: "analyze_other_coins_success",
        //   payload: analyzeResult,
        // });
        // dispatch({
        //   type: GET_ALL_TRADE_SUGGESTION_SUCCESS,
        //   payload: res?.data,
        // });
        const selectedCoins = [...other_coins_to_analyse];

        if (analyzeResult?.length) {
          const processedCoins = analyzeResult?.map(
            (coin) => coin?.SYMBOL_NAME,
          );

          processedCoins.forEach((coin) => {
            // console.log(
            //   "selected coin:",
            //   selectedCoins,
            //   "processed coin",
            //   coin,
            // );
            if (selectedCoins?.includes(coin)) {
              const coinAnalyze = analyzeResult?.find(
                (item) => item?.SYMBOL_NAME === coin,
              );
              // console.log("coin analyze", coinAnalyze);

              dispatch({
                type: "analyze_other_coins_success",
                payload: coinAnalyze,
              });

              dispatch(getLiveSuggestion(user, email, exchange_id, coin));
            }
          });

          alert("", "Successfully processed", "success", {
            cancel: "Close",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            analyzeOtherCoins(
              user,
              email,
              market_type,
              other_coins_to_analyse,
              exchange_id,
              retryCount + 1,
            ),
          "analyze_other_coins_error",
          user,
          email,
          "get_trade_suggestion/other_coins_to_analyse",
        );
      });
  };
