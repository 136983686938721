import React from "react";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { AiFillCaretRight } from "react-icons/ai";

function SectionLabel({ children, label, style = {} }) {
  return (
    <div className={styles.limitBoxHeader} style={style}>
      <AiFillCaretRight />
      {label}
      {children}
    </div>
  );
}

export default SectionLabel;
