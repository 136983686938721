import React, { useState, useEffect } from "react";
import styles from "../../styles/Components/players/videoPlayer.module.sass";
import ReactPlayer from "react-player";
import { motion } from "framer-motion/dist/framer-motion";
import { GrClose } from "react-icons/gr";

const VideoPlayer = ({ show = false, hide, url, title, clickHandler }) => {
  const [videoUrl, setVideoUrl] = useState(url);

  useEffect(() => {
    if (show) {
      setVideoUrl(url); // Reset URL when showing the player
    }
  }, [show, url]);

  const handleClose = () => {
    setVideoUrl(""); // Clear URL to reset the player
    hide(false);
    if (clickHandler) clickHandler();
  };

  return (
    <motion.div
      className={`${styles.vedioPlayerWrapper} ${!show ? styles.hide : ""}`}
      whileInView={{ opacity: 1 }}
      style={{ zIndex: "10" }}
    >
      <span className={styles.closeBtn} onClick={handleClose}>
        <GrClose />
      </span>
      <ReactPlayer
        width={"75%"}
        style={{ minWidth: "320px" }}
        height={"auto"}
        playing={show}
        url={videoUrl}
        controls
      />
    </motion.div>
  );
};

export default VideoPlayer;
