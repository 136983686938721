import useCheckPortfolio from "../../hooks/useCheckPortfolio";
import { MdWarningAmber } from "react-icons/md";
import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { useSelector } from "react-redux";

function EmptyPortfolio() {
  const {
    tradeForm: { tradeType, targetPortfolioStatus },
  } = useSelector((state) => state);
  const { isPortfolioEmpty } = useCheckPortfolio();

  if (
    tradeType !== "sell" ||
    !isPortfolioEmpty ||
    targetPortfolioStatus === "loading"
  )
    return null;

  return (
    <div className={styles.balanceWarning}>
      <MdWarningAmber
        size={16}
        // color="#f1c40f"
      />
      <p
      // style={{ color: "#f1c40f" }}
      >
        You have no assets to sell!
      </p>
    </div>
  );
}

export default EmptyPortfolio;
