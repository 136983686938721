import { useSelector } from "react-redux";

function UseAssetAmount() {
  const {
    tradeForm: { targetPortfolio, targetCoin, tradeType },
  } = useSelector((state) => state);

  const coinData = targetPortfolio?.find(
    (asset) => asset?.assetName === targetCoin,
  );

  const validBalance =
    parseFloat(coinData?.availableBalance) > 0 ||
    parseFloat(coinData?.stakingBalance) > 0;

  const isAssetAvailable =
    tradeType !== "sell" ||
    targetCoin === "" ||
    (targetCoin && coinData && validBalance);

  const nativeAmount =
    (parseFloat(coinData?.stakingBalance) || 0) +
    parseFloat(coinData?.availableBalance);
  const usdAmount = +coinData?.availableBalanceUSD;

  return {
    nativeAmount,
    usdAmount,
    isAssetAvailable,
    isAssetInPortfolio: coinData && validBalance,
  };
}

export default UseAssetAmount;
