import styles from "../../styles/shared/live-suggestion/LiveSuggestionModal.module.css";

function Tabs({ changeTab, activeTab, style = {} }) {
  return (
    <ul className={styles.tabs} onClick={changeTab} style={style}>
      <li
        className={`${activeTab === "info" ? styles.activeTab : ""}`}
        id="info"
      >
        Info
      </li>
      <li
        className={`${activeTab === "chart" ? styles.activeTab : ""}`}
        id="chart"
      >
        Chart
      </li>
      <li
        className={`${activeTab === "history" ? styles.activeTab : ""}`}
        id="history"
      >
        History
      </li>
    </ul>
  );
}

export default Tabs;
