import styles from "../../../styles/Components/trade-assistant/portfolio/VirtualPortfolioTableActionButtons.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FiRefreshCcw } from "react-icons/fi";
import { Button } from "antd";
import { getTestExchangePortfolio } from "../../../redux/test-exchange/testExchangeSlice";
import TradeButton from "../../../shared/trade-form/TradeButton";

function VirtualPortfolioTableActionButtons() {
  const {
    main: { userData },
    tradingAssistant: { getPortfolioDataLoading },
    testExchange: { portfolioStatus },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.container}>
        <TradeButton mode="trade-btn" defaultStyle>
          Buy / Sell
        </TradeButton>
        <Button
          className={styles.refreshBtn}
          loading={portfolioStatus === "loading"}
          // loading={getPortfolioDataLoading}
          onClick={() => {
            if (portfolioStatus === "loading") return;
            // if (getPortfolioDataLoading) return;
            dispatch(
              getTestExchangePortfolio(
                userData?.["custom:custom_username"],
                userData?.["cognito:username"],
                "cryptocrispy",
              ),
            );
          }}
        >
          {!getPortfolioDataLoading && <FiRefreshCcw />}
          <span className={styles.desktopVersion}>Refresh</span>
        </Button>
      </div>
    </>
  );
}

export default VirtualPortfolioTableActionButtons;
