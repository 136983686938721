import SkeletonContainer from "./SkeletonContainer";
import { Link } from "react-router-dom";
import { notify } from "../../utility/Toastify/toastify";

import styles from "../../styles/Components/dashboard/TopCryptoPrediction.module.css";

// ICONS
import down from "../../asset/Icons/down.gif";
import up from "../../asset/Icons/up.gif";
import prediction from "../../asset/Icons/prediction.gif";
import ContentContainer from "./ContentContainer";
import DashboardCardTitle from "./DashboardCardTitle";
import TradeButton from "../../shared/trade-form/TradeButton";

const TopCryptoPrediction = ({ loading, data }) => {
  return (
    <div className={styles.container}>
      {loading && !data ? (
        <SkeletonContainer />
      ) : (
        data && (
          <ContentContainer>
            <DashboardCardTitle
              text={"Top Crypto Predictions"}
              icon={prediction}
            />
            <div className={styles.predictContainer}>
              <div className={styles.predictBox}>
                <h4 className={styles.predictTitle}>
                  Going up (Buy)
                  <img src={up} className={styles.predictIcon} alt="buy" />
                </h4>
                {data.increasing.map(([name, value, action]) => (
                  <div className={styles.coin} key={name}>
                    <img
                      className={styles.coinImage}
                      src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${name}.png`}
                      alt={name}
                    />
                    <TradeButton
                      btnClassName={styles.coinName}
                      data={{ coin: name }}
                    >
                      {name}
                    </TradeButton>
                    <div style={{ color: value > 0 ? "green" : "red" }}>
                      {value}%
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.predictBox}>
                <h4 className={styles.predictTitle}>
                  Going down (Sell)
                  <img src={down} className={styles.predictIcon} alt="sell" />
                </h4>
                {data.decreasing.map(([name, value, action]) => (
                  <div className={styles.coin} key={name}>
                    <img
                      className={styles.coinImage}
                      src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${name}.png`}
                      alt={name}
                    />
                    <TradeButton
                      btnClassName={styles.coinName}
                      data={{ coin: name }}
                    >
                      {name}
                    </TradeButton>
                    <div style={{ color: value > 0 ? "green" : "red" }}>
                      {value}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.linkBtns}>
              <Link
                onClick={() =>
                  notify("You are redirecting to the Insights page.", "success")
                }
                className={styles.link}
                to="/ai-assistant"
              >
                See more signals
              </Link>
              <Link
                onClick={() =>
                  notify(
                    "You are redirecting to the AI Assistant page.",
                    "success",
                  )
                }
                className={styles.link}
                to="/ai-assistant/portfolio"
              >
                Manage Portfolio
              </Link>
            </div>
          </ContentContainer>
        )
      )}
    </div>
  );
};

export default TopCryptoPrediction;
