import { InputNumber, Slider, Spin } from "antd";
import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import useAssetAmount from "../../hooks/useAssetAmount";
import useCheckPortfolio from "../../hooks/useCheckPortfolio";
import { setTradeSize } from "../../../../redux/trade-form/tradeFormSlice";
import LimitOrderTriggerPrice from "./LimitOrderTriggerPrice";
import SetLimitForBuyOrder from "./SetLimitForBuyOrder";
import ApplyAiSuggestion from "../ApplyAiSuggestion";
import useCheckOrderLimits from "../../hooks/useCheckOrderLimits";
import { roundToPrecision } from "../../utils/helper";
import SectionLabel from "../SectionLabel";

function SetTradeAmount() {
  const {
    tradeForm: {
      tradeType,
      tradeNativeAmount = "",
      tradeUsdAmount = "",
      assetPrice,
      assetPriceStatus,
      finalTradeType,
      tradeAmountPercentage,
    },
  } = useSelector((state) => state);

  // const [sliderValue, setSliderValue] = useState(50);
  const [isLimitOrderChecked, setIsLimitOrderChecked] = useState({
    takeProfit: true,
    stopLoss: true,
  });

  const { nativeAmount, usdAmount, isAssetInPortfolio } = useAssetAmount();
  const { budget } = useCheckPortfolio();
  const { amountPrecision, minNative, maxNative } = useCheckOrderLimits();

  const maximumNativeForBuy = budget / assetPrice;
  const maximumNativeForSell = nativeAmount;

  const minimumNativeInput = minNative;

  const maximumNativeInput =
    tradeType === "sell"
      ? Math.min(maxNative, maximumNativeForSell)
      : Math.min(maxNative, maximumNativeForBuy);

  const nativeInputStep = amountPrecision;

  const minimumUsd = minimumNativeInput * assetPrice;
  const maximumUsd = maximumNativeInput * assetPrice;
  const usdInputStep = nativeInputStep * assetPrice;

  const minimumSlider = (minimumNativeInput / maximumNativeInput) * 100;
  const maximumSlider = 100;

  const sliderStep = (nativeInputStep / maximumNativeInput) * 100;
  // const maximumUsd =
  //   tradeType === "buy"
  //     ? +budget
  //     : // * 0.9999
  //       nativeAmount * assetPrice;

  const showLimitSettings =
    finalTradeType !== "sell" && finalTradeType !== "buy";

  const disableTrade = minimumNativeInput > maximumNativeInput;

  const errorMessage = disableTrade
    ? "You have not enough balance for trade this coin"
    : "";

  const dispatch = useDispatch();

  const changeNativeAmount = (value) => {
    if (
      (tradeType === "sell" && value > Number(nativeAmount)) ||
      (tradeType === "buy" && value > budget / assetPrice)
      // * 0.9999
    )
      return;

    // if (tradeType === "sell") {
    //   setSliderValue((value / nativeAmount) * 100);
    // } else {
    //   setSliderValue(((value * assetPrice) / budget) * 100);
    // }

    const percentage =
      tradeType === "sell"
        ? (value / nativeAmount) * 100
        : ((value * assetPrice) / budget) * 100;

    dispatch(
      setTradeSize(
        // roundToPrecision(value, amountPrecision),
        value,
        value * assetPrice,
        percentage,
      ),
    );
  };

  const changeUsdAmount = (value) => {
    if (
      (tradeType === "buy" && value > budget) ||
      (tradeType === "sell" && value > usdAmount)
    )
      return;

    // if (tradeType === "sell") {
    //   const percentage = ((value * 100) / nativeAmount) * assetPrice;
    //   setSliderValue(percentage);
    // } else {
    //   const percentage = (value * 100) / budget;
    //   setSliderValue(percentage);
    // }

    const percentage =
      tradeType === "sell"
        ? ((value * 100) / nativeAmount) * assetPrice
        : (value * 100) / budget;

    dispatch(
      setTradeSize(
        // roundToPrecision(value / assetPrice, amountPrecision),
        value / assetPrice,
        value,
        percentage,
      ),
    );
  };

  const changeSliderValue = (value) => {
    if (!value) {
      return assetPrice
        ? tradeType === "sell"
          ? (100.001 / assetPrice) * nativeAmount
          : 100.001 / budget
        : 0.001;
    } else {
      // setSliderValue(value);
      const percentage = value / 100;
      if (tradeType === "sell") {
        const native = nativeAmount * percentage;
        dispatch(
          setTradeSize(
            // roundToPrecision(native, amountPrecision),
            native,
            native * assetPrice,
            value,
          ),
        );
      } else {
        const usd = budget * percentage;
        dispatch(
          setTradeSize(
            // roundToPrecision(usd / assetPrice, amountPrecision),
            usd / assetPrice,
            usd,
            value,
          ),
        );
      }
    }
  };

  useEffect(() => {
    if (assetPrice && !tradeNativeAmount && !tradeUsdAmount) {
      const percentage = tradeAmountPercentage / 100;
      // const percentage = sliderValue / 100;
      if (tradeType === "sell") {
        const nativeValue = nativeAmount * percentage;
        dispatch(
          setTradeSize(
            // roundToPrecision(nativeValue, amountPrecision),
            nativeValue,
            nativeValue * assetPrice,
            tradeAmountPercentage,
          ),
        );
      }
      if (tradeType === "buy") {
        const usd = budget * percentage;
        dispatch(
          setTradeSize(
            // roundToPrecision(usd / assetPrice, amountPrecision),
            usd / assetPrice,
            usd,
            tradeAmountPercentage,
          ),
        );
      }
    }
  }, [
    amountPrecision,
    assetPrice,
    budget,
    dispatch,
    nativeAmount,
    tradeAmountPercentage,
    tradeNativeAmount,
    tradeType,
    tradeUsdAmount,
  ]);

  return (
    <div className={styles.setAmountBox}>
      {(tradeType !== "sell" || isAssetInPortfolio) && (
        <>
          <SectionLabel label="Amount" style={{ marginBottom: ".5rem" }}>
            <ApplyAiSuggestion field="tradeAmount" />
          </SectionLabel>
          <div className={styles.amountInputs}>
            <div className={styles.inputBox}>
              <label htmlFor="amountInput">Native:</label>
              <InputNumber
                value={tradeNativeAmount}
                disabled={!assetPrice}
                max={maximumNativeInput}
                min={minimumNativeInput}
                step={nativeInputStep}
                onChange={changeNativeAmount}
                status={disableTrade ? "error" : ""}
              />
            </div>
            <div className={styles.inputBox}>
              <label htmlFor="amountInput">USD:</label>
              <InputNumber
                value={tradeUsdAmount}
                disabled={!assetPrice}
                onChange={changeUsdAmount}
                max={maximumUsd}
                min={minimumUsd}
                step={usdInputStep}
                formatter={(value) => `${parseFloat(value)?.toFixed(2)}`}
                status={disableTrade ? "error" : ""}
              />
            </div>
          </div>
          {disableTrade && (
            <p style={{ fontSize: "12px", color: "red" }}>
              You have not enough balance for trade this coin
            </p>
          )}

          <div className={styles.sliderBox}>
            <Slider
              disabled={!assetPrice}
              value={tradeAmountPercentage}
              onChange={changeSliderValue}
              max={maximumSlider}
              min={minimumSlider}
              step={sliderStep}
            />
            <InputNumber
              disabled={!assetPrice}
              value={tradeAmountPercentage}
              onChange={changeSliderValue}
              max={maximumSlider}
              min={minimumSlider}
              step={sliderStep}
              formatter={(value) => `${parseFloat(value)?.toFixed(2)}%`}
              parser={(value) => value.replace("%", "")}
              status={disableTrade ? "error" : ""}
            />
          </div>
        </>
      )}

      {showLimitSettings && (
        <>
          <SectionLabel label="Trigger Price" />
          <LimitOrderTriggerPrice
            limitType={
              finalTradeType === "sell_sl" || finalTradeType === "buy_db"
                ? "low"
                : finalTradeType === "buy_bb" || finalTradeType === "sell_tp"
                  ? "high"
                  : ""
            }
          />
        </>
      )}
      <SetLimitForBuyOrder
        setLimit={setIsLimitOrderChecked}
        checkedLimit={isLimitOrderChecked}
      />

      {!assetPrice && <div className={styles.overlayMask}></div>}
    </div>
  );
}

export default SetTradeAmount;
