import { useSelector } from "react-redux";
import useCheckPortfolio from "./useCheckPortfolio";
import useAssetAmount from "./useAssetAmount";

function UseCheckOrderLimits() {
  const {
    tradeForm: {
      targetCoin,
      targetExchange,
      tradeType,
      finalTradeType,
      assetPrice,
      tradeNativeAmount,
      tradeUsdAmount,
      slTriggerPrice: slPercent,
      tpTriggerPrice: tpPercent,
      isLimitSet,
    },
    exchangeState: { availableCoinsData },
  } = useSelector((state) => state);

  const { isEnoughBalance, budget } = useCheckPortfolio();
  const { isAssetInPortfolio, nativeAmount, usdAmount } = useAssetAmount();

  const exchangeLimits = availableCoinsData?.[`${targetExchange}-limits`];

  const coinLimit = exchangeLimits?.find((c) => c?.Coin === targetCoin);

  const minNative = coinLimit?.MinNative;
  const maxNative = coinLimit?.MaxNative;
  const minNotional = coinLimit?.MinNotional;
  const maxNotional = coinLimit?.MaxNotional;
  const amountPrecision = coinLimit?.AmountPrecision;
  const pricePrecision = coinLimit?.PricePrecision;

  const budgetIsInRange =
    (!minNotional || minNotional <= 0 || +budget >= minNotional * 1.02) &&
    (!maxNotional || maxNotional <= 0 || +budget <= maxNotional * 0.98);

  const isAssetValueInRange =
    (!minNative || minNative <= 0 || nativeAmount >= minNative * 1.02) &&
    (!maxNative || maxNative <= 0 || nativeAmount <= maxNative * 0.98) &&
    (!minNotional || minNotional <= 0 || usdAmount >= minNotional * 1.02) &&
    (!maxNotional || maxNotional <= 0 || usdAmount <= maxNotional * 0.98);

  const firstSellValidation = tradeType !== "sell" || isAssetValueInRange;

  const firstBuyValidation =
    tradeType !== "buy" || (isEnoughBalance && budgetIsInRange);

  const firstStepValidation = firstBuyValidation && firstSellValidation;

  const checkNativeLimit =
    (!minNative || minNative <= 0 || tradeNativeAmount >= minNative * 1.02) &&
    (!maxNative || maxNative <= 0 || tradeNativeAmount <= maxNative * 0.98);

  const checkNotionalLimit =
    (!minNotional ||
      minNotional <= 0 ||
      tradeUsdAmount >= minNotional * 1.02) &&
    (!maxNotional || maxNotional <= 0 || tradeUsdAmount <= maxNotional * 0.98);

  const checkCoinMinAndMaxValidation = checkNativeLimit && checkNotionalLimit;

  const buyValidation =
    finalTradeType !== "buy" ||
    (isEnoughBalance && checkCoinMinAndMaxValidation);

  const slTriggerPrice = (1 - slPercent) * assetPrice;
  const tpTriggerPrice = (1 + tpPercent) * assetPrice;

  // check the buy trade validation:
  // 1.final trade type is buy or not
  // 2.check the exchange minimum and maximum limits for this coin
  // 3.if limit order set, check the trigger prices
  const buyValidationPlusLimits =
    finalTradeType !== "buy" ||
    (isEnoughBalance &&
      checkCoinMinAndMaxValidation &&
      (!isLimitSet ||
        (tpTriggerPrice > assetPrice && slTriggerPrice < assetPrice)));

  const checkAssetAmountForSell =
    tradeNativeAmount <= nativeAmount && tradeUsdAmount <= usdAmount;

  // check the sell trade validation:
  // 1.final trade type is sell or not
  // 2.check the asset exist in the portfolio or not
  // 3.check the exchange minimum and maximum limits for this coin
  const sellValidation =
    finalTradeType !== "sell" ||
    (isAssetInPortfolio &&
      checkAssetAmountForSell &&
      checkCoinMinAndMaxValidation);

  const slOrDbValidation =
    (finalTradeType !== "sell_sl" && finalTradeType !== "buy_db") ||
    (slTriggerPrice < assetPrice &&
      checkCoinMinAndMaxValidation &&
      (finalTradeType !== "sell_sl" || checkAssetAmountForSell));

  const tpOrBbValidation =
    (finalTradeType !== "sell_tp" && finalTradeType !== "buy_bb") ||
    (tpTriggerPrice > assetPrice &&
      checkCoinMinAndMaxValidation &&
      (finalTradeType !== "sell_tp" || checkAssetAmountForSell));

  const isTradePermitted =
    buyValidationPlusLimits &&
    sellValidation &&
    slOrDbValidation &&
    tpOrBbValidation;

  // if (!targetCoin) return null;

  const errorMessage =
    tradeType === "buy" && !budgetIsInRange
      ? "Balance mismatch with exchange limits"
      : tradeType === "sell" && !isAssetValueInRange && isAssetInPortfolio
        ? "Asset amount mismatch with exchange limits"
        : "";

  const step2ErrorMessage =
    !assetPrice || !checkCoinMinAndMaxValidation
      ? "Asset amount mismatch with exchange limits"
      : "";

  return {
    isTradePermitted,
    minNative: minNative || 0,
    maxNative: maxNative || Infinity,
    minNotional: minNotional || 0,
    maxNotional: maxNotional || Infinity,
    buyValidation,
    step1Validation: firstStepValidation,
    errorMessage,
    amountPrecision: amountPrecision || 0.00000001,
    pricePrecision: pricePrecision || 0.00000001,
    step2ErrorMessage,
    sellValidation,
    checkCoinMinAndMaxValidation,
    isAssetInPortfolio,
    checkAssetAmountForSell,
  };
}

export default UseCheckOrderLimits;
