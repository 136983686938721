import { Table } from "antd";
import customStyles from "../../styles/shared/live-suggestion/TradesTable.module.css";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { getRelativeTime } from "../../utility/formatter-helper";
import { formatAmount } from "../../utility/helper";

function TradesTable({ data }) {
  const [tableData, setTableData] = useState([]);

  const sortedHistory = [...data]?.sort((a, b) => {
    return moment(b["Trade Date"], "DD MMM YYYY, HH:mm:ss").diff(
      moment(a["Trade Date"], "DD MMM YYYY, HH:mm:ss"),
    );
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (text) => (
        <span style={{ fontSize: "12px" }}>{getRelativeTime(text)}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text) => (
        <span style={{ fontSize: "12px" }}>{formatAmount(text)}</span>
      ),
    },
    {
      title: "Profit/Loss",
      dataIndex: "profitLoss",
      key: "profitLoss",
      align: "center",
      render: (text) => (
        <span style={{ color: text?.includes("-") ? "red" : "green" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Trader",
      dataIndex: "trader",
      key: "trader",
      align: "center",
    },
  ];

  useEffect(() => {
    setTableData(
      sortedHistory?.map((item, index) => {
        return {
          key: index,
          position: item?.Position,
          asset: item?.["Asset"],
          currency: item?.currency,
          amount: item?.Amount,
          action: item?.trade_type,
          price: item?.trigger_price,
          date: item?.["Trade Date"],
          orderMode: item?.order_mode,
          trader: item?.Trader,
          status: item?.Position,
          info: item,
          entryPrice: item?.["Entry Price"],
          exitPrice: item?.["Exit Price"],
          profitLoss: item?.PnL,
        };
      }),
    );
  }, [data]);

  return (
    <Table
      className={customStyles.container}
      dataSource={tableData}
      columns={columns}
      height={200}
      size="small"
      pagination={data?.length > 10}
      scroll={{ x: "50px" }}
    />
  );
}

export default TradesTable;
