import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { IoMdPricetag } from "react-icons/io";
import { Skeleton } from "antd";
import { currencyFormatter } from "../../../../utility/helper";
import { IoWallet } from "react-icons/io5";
import { FaCoins } from "react-icons/fa";
import { useSelector } from "react-redux";
import useCheckPortfolio from "../../hooks/useCheckPortfolio";
import useAssetAmount from "../../hooks/useAssetAmount";
import useAssetPrice from "../../hooks/useAssetPrice";

function StatusBar() {
  const {
    tradeForm: { targetCoin, tradeType, assetPrice, assetPriceStatus },
  } = useSelector((state) => state);

  const { budget } = useCheckPortfolio();
  const { nativeAmount } = useAssetAmount();
  useAssetPrice();

  return (
    <div className={styles.statusBar}>
      <div className={styles.info}>
        <span className={styles.label}>
          <IoMdPricetag color="#0c4cfc" />
          {targetCoin}/USDT :
        </span>
        <span className={styles.amount}>
          {assetPriceStatus === "loading" ? (
            <Skeleton.Node
              className={styles.priceLoading}
              active
              style={{ width: 80, height: "20px" }}
            />
          ) : !assetPrice ? (
            "-"
          ) : (
            `$${assetPrice > 1 ? currencyFormatter(assetPrice) : assetPrice}`
          )}
        </span>
      </div>
      {tradeType === "buy" && (
        <div className={styles.info}>
          <span className={styles.label}>
            <IoWallet color="#0c4cfc" />
            Balance :
          </span>
          <span className={styles.amount}>
            {currencyFormatter(budget)}(USDT)
          </span>
        </div>
      )}

      {tradeType === "sell" && (
        <div className={styles.info}>
          <span className={styles.label}>
            <FaCoins color="#0c4cfc" />
            Native Amount :
          </span>
          <span className={styles.amount}>{nativeAmount || "-"}</span>
        </div>
      )}
    </div>
  );
}

export default StatusBar;
