/** @format */

export const SHOW_RATING_MODAL = "SHOW_RATING_MODAL";
export const HIDE_RATING_MODAL = "HIDE_RATING_MODAL";
export const SET_LAST_SHOWN_DATE = "SET_LAST_SHOWN_DATE";

export const showRatingModal = (page) => ({
	type: SHOW_RATING_MODAL,
	payload: page,
});

export const hideRatingModal = () => ({
	type: HIDE_RATING_MODAL,
});

export const setLastShownDate = (date) => ({
	type: SET_LAST_SHOWN_DATE,
	payload: date,
});
