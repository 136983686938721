import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { RiRobot2Fill } from "react-icons/ri";
import useAISuggestion from "../hooks/useAISuggestion";
import { useDispatch, useSelector } from "react-redux";
import {
  setFinalTradeType,
  setStopLossTriggerPrice,
  setTakeProfitTriggerPrice,
  setTradeSize,
  setTradeType,
} from "../../../redux/trade-form/tradeFormSlice";
import useAssetAmount from "../hooks/useAssetAmount";
import useCheckPortfolio from "../hooks/useCheckPortfolio";
import useCheckOrderLimits from "../hooks/useCheckOrderLimits";
import { roundToPrecision } from "../utils/helper";

// field can be "tradeType", "tradeAmount", "sl", "tp" and limits for both stop loss and take profit field
function ApplyAiSuggestion({ field = "", style = {} }) {
  const {
    tradeForm: { assetPrice, tradeType },
  } = useSelector((state) => state);

  const { prediction, positionSize, sl_percent, tp_percent } =
    useAISuggestion();
  const { nativeAmount, usdAmount } = useAssetAmount();
  const { budget } = useCheckPortfolio();

  const dispatch = useDispatch();

  const { amountPrecision } = useCheckOrderLimits();

  // apply ai suggestion to the related field
  const applySuggestion = () => {
    console.log(field);
    if (field === "tradeType") {
      dispatch(setTradeType(prediction));
      dispatch(setFinalTradeType(prediction));
      return;
    }

    if (field === "tradeAmount") {
      const native =
        tradeType === "buy"
          ? (positionSize * budget) / assetPrice
          : positionSize * nativeAmount;
      const usd =
        tradeType === "buy" ? positionSize * budget : positionSize * usdAmount;

      return dispatch(
        setTradeSize(
          roundToPrecision(native, amountPrecision),
          usd,
          positionSize * 100,
        ),
      );
    }

    if (field === "sl") {
      return dispatch(setStopLossTriggerPrice(sl_percent));
    }

    if (field === "tp") {
      return dispatch(setTakeProfitTriggerPrice(tp_percent));
    }

    if (field === "limits") {
      dispatch(setStopLossTriggerPrice(sl_percent));
      return dispatch(setTakeProfitTriggerPrice(tp_percent));
    }
  };

  if (!prediction) return null;

  if (field === "tradeType" && prediction === "hold") return null;

  return (
    <span
      className={styles.applySuggestion}
      style={style}
      onClick={applySuggestion}
    >
      <RiRobot2Fill size={14} />
      Apply AI
    </span>
  );
}

export default ApplyAiSuggestion;
