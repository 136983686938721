import { useState } from "react";
import { Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getLiveSuggestion } from "../../redux/live-suggestion/liveSuggestionSlice";
import ErrorBox from "../../components/mini-app/shared/ErrorBox";
import SelectExchange from "../SelectExchange";

import styles from "../../styles/shared/live-suggestion/LiveSuggestionModal.module.css";
import AssetDisplay from "../../ui/AssetDisplay";
import { HiOutlineRefresh } from "react-icons/hi";
import InfoBox from "./InfoBox";
import ChartBox from "./ChartBox";
import HistoryBox from "./HistoryBox";
import Tabs from "./Tabs";

function LiveSuggestionModal({ open, onClose, coin }) {
  const {
    liveSuggestion: { suggestions, status: liveStatus, coinStatus },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const coinSuggestion = suggestions?.find((s) => s?.symbol === coin);
  const hasBacktest = coinSuggestion?.backtest_result;

  const [selectedExchange, setSelectedExchange] = useState(
    coinSuggestion?.exchange_id || "kucoin",
  );
  const [activeTab, setActiveTab] = useState("info");

  const chartData = coinSuggestion?.backtest_result?.equity;
  const historyData = coinSuggestion?.backtest_result?.trades;

  const handleExchangeChange = (exchange) => {
    setSelectedExchange(exchange);
    return dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchange,
        coin,
        true,
      ),
    );
  };

  const closeModal = () => {
    setSelectedExchange("kucoin");
    setActiveTab("info");
    onClose();
  };

  const refreshSignal = () => {
    return dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedExchange,
        coin,
        true,
      ),
    );
  };

  const getHistoryAndChartData = () => {
    return dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedExchange,
        coin,
        true,
      ),
    );
  };

  const changeTab = (e) => {
    const targetTab = e.target.closest("li");
    if (targetTab) {
      setActiveTab(targetTab.id);
      if (!hasBacktest && liveStatus !== "loading") {
        return dispatch(
          getLiveSuggestion(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedExchange,
            coin,
            true,
          ),
        );
      }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      footer={null}
      centered
      maskClosable={false}
      width={600}
      title={
        <div className={styles.title}>
          <AssetDisplay
            asset={coin?.split("/USDT")?.[0]}
            className={styles.assetDisplay}
          />
          {coinStatus?.[coin] === "loading" ? (
            <span>
              Loading <Spin size="small" />
            </span>
          ) : (
            <span className={styles.refreshBtn} onClick={refreshSignal}>
              <HiOutlineRefresh />
            </span>
          )}
        </div>
      }
    >
      <div className={styles.box}>
        <div className={styles.selectBox}>
          <h4>Select Exchange:</h4>
          <SelectExchange
            value={selectedExchange}
            onChange={handleExchangeChange}
            loading={liveStatus === "loading"}
            style={{ width: "120px" }}
          />
        </div>

        <p className={styles.text}>
          <strong>NOTE:</strong>
          'KuCoin' is used as the default exchange.
        </p>
      </div>

      <div className={styles.result}>
        <Tabs activeTab={activeTab} changeTab={changeTab} />
        <div className={styles.content}>
          {(liveStatus === "loading" || coinStatus[coin] === "loading") &&
          activeTab !== "info" ? (
            <div className={styles.loader}>
              <Spin />
            </div>
          ) : liveStatus === "error" && !coinSuggestion ? (
            <ErrorBox />
          ) : (
            <>
              {activeTab === "info" && <InfoBox info={coinSuggestion} />}

              {activeTab !== "info" &&
                (coinStatus?.[coin] === "loading" ? (
                  <span>
                    Loading <Spin size="small" />
                  </span>
                ) : (
                  <span
                    className={styles.refreshBtn}
                    style={{ marginBottom: "1rem" }}
                    onClick={refreshSignal}
                  >
                    <HiOutlineRefresh />
                  </span>
                ))}

              {activeTab === "chart" && (
                <ChartBox data={chartData} getData={getHistoryAndChartData} />
              )}
              {activeTab === "history" && (
                <HistoryBox
                  history={historyData}
                  getData={getHistoryAndChartData}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default LiveSuggestionModal;
