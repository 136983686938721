/** @format */

import { useState } from "react";
import { message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import alert from "../components/alerts/Alert";
import { getProfileData } from "../redux/profile/actions";
import { isMobile, isTablet } from "react-device-detect";

const useGeoLocation = (user = "", email = "") => {
	const {
		main: { userData },
	} = useSelector((state) => state);

	const [location, setLocation] = useState({ lat: null, lon: null });
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [permissionDenied, setPermissionDenied] = useState(false);

	const dispatch = useDispatch();

	const getLocation = () => {
		setLoading(true);
		setError(false);
		setPermissionDenied(false);

		message.loading("Checking your location...");
		if (navigator?.geolocation) {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const { latitude, longitude } = position.coords;
					setLocation({ lat: latitude, lon: longitude });

					if (email === "sami.pawl@gmail.com") {
						return console.log(position);
					}

					await axios
						.post(
							"https://jvp2fozucc.execute-api.us-east-1.amazonaws.com/dev/api",
							{
								platform_mode: "profile",
								request_type: "verify_user_location",
								email: email || userData.email,
								user: user || userData?.["custom:custom_username"],
								lat: latitude,
								lon: longitude,
							}
						)
						.then((response) => {
							console.log(response);
							if (!isMobile && !isTablet) {
								dispatch(
									getProfileData(
										userData?.email,
										userData?.["custom:custom_username"]
									)
								);
							}

							alert("", response?.data?.message, "success", {
								cancel: "Close",
							});
						})
						.catch((error) => {
							console.log(error);
							setError(true);
							alert(null, error?.response?.data?.message, "error", error, {
								cancel: "Close",
							});
						})
						.finally(() => setLoading(false));
				},
				(error) => {
					console.warn("Geolocation error:", error);
					setLoading(false);

					if (error.code === error.PERMISSION_DENIED) {
						setPermissionDenied(true);
						message.error(
							"Please allow location access to complete verification"
						);
					} else {
						setError(true);
						message.error("Unable to get your location. Please try again.");
					}
				},
				{ enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
			);
		} else {
			message.info("Geolocation is not supported by this browser.");
			setLoading(false);
			setError(true);
		}
	};

	return { location, loading, getLocation, error, permissionDenied };
};

export default useGeoLocation;
