import { Empty } from "antd";
import EquityChart from "./EquityChart";

export default function ChartBox({ data }) {
  return (
    <>
      {!data || data?.downsampled_adv_equity?.length === 0 ? (
        <>
          <Empty />
        </>
      ) : (
        <EquityChart data={data} />
      )}
    </>
  );
}
