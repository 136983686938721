import { Button, Modal, message } from "antd";
import styles from "../../styles/pages/Auth/ConfirmAlert.module.css";
import { useState } from "react";
import { MdCancel, MdMarkEmailRead } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { checkEmailException } from "../../utility/helper";
import PoolData from "./AWSConfig/PoolData";
import { useNavigate } from "react-router";
import { notify } from "../../utility/Toastify/toastify";
import {
  setToken,
  setUserData,
  signinUpMiniApp,
} from "../../redux/main/action/main";
import axios from "axios";
import { useDispatch } from "react-redux";

function ConfirmAlert({
  show,
  setShow,
  email,
  msg,
  mode,
  password,
  telegramId,
  navigateHandler,
}) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sendCodeHandler = () => {
    setLoading(true);
    const getUser = () => {
      return new CognitoUser({
        Username: checkEmailException(email),
        Pool: PoolData,
      });
    };

    const onSuccess = (data) => {
      setLoading(false);
      setStep(2);
    };
    const onFailure = (err) => {
      notify(err.message || JSON.stringify(err), "error");
      setLoading(false);
    };

    getUser().resendConfirmationCode((err, data) => {
      if (err) {
        onFailure(err);
      } else {
        onSuccess(data);
      }
    });
  };

  const confirmHandler = () => {
    if (!confirmationCode) {
      message.warning("Enter the confirmation code!");
      return;
    }
    setLoading(true);
    const userData = {
      Username: email.toLowerCase(),
      Pool: PoolData,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(
        confirmationCode,
        true,
        function (err, result) {
          if (err) {
            notify(err?.message, "error");
            reject(err);
            setLoading(false);
          } else {
            notify("Your email successfully confirmed.", "success");
            resolve(result);

            if (password) {
              let authDetails = new AuthenticationDetails({
                Username: email.toLowerCase(),
                Password: password,
                ValidationData: { captcha: "rtspa_mehdi_ravanbakhsh_761" },
              });

              cognitoUser.authenticateUser(authDetails, {
                onSuccess: (data) => {
                  dispatch(setToken(data?.idToken?.jwtToken));
                  dispatch(setUserData(data?.idToken?.payload));
                  axios.defaults.headers.common["Authorization"] =
                    `Bearer ${data?.idToken?.jwtToken}`;
                  // window.location.reload();
                  // notify("You have successfully logged in", "success");
                  localStorage.setItem(
                    "aws-amplify-federatedInfo",
                    JSON.stringify({ token: data?.idToken?.jwtToken }),
                  );
                  localStorage.setItem(
                    "aws-amplify-USERDATA",
                    JSON.stringify(data?.idToken?.payload),
                  );
                  setLoading(false);
                  if (telegramId) {
                    dispatch(
                      signinUpMiniApp(
                        telegramId,
                        data?.idToken?.payload["custom:custom_username"],
                        data?.idToken?.payload?.email,
                      ),
                    );
                    navigateHandler();
                    return;
                  }
                  // navigate("/ai-assistant/assistant");
                  navigate("/welcome");
                },

                onFailure: function (err) {
                  window?.grecaptcha?.reset();
                  setLoading(false);
                  if (err?.message.includes("captcha")) return;
                  notify(err?.message, "error");
                },

                newPasswordRequired: (data) => {
                  notify(data, "success");
                  setLoading(false);
                },
              });
            }

            setLoading(false);
            setShow(false);
            if (mode !== "changePass") {
              navigate("/auth/login");
            }
          }
        },
      );
    });
  };
  return (
    <Modal
      open={show}
      // closable={false}
      footer={null}
      onCancel={undefined}
      width={400}
      centered
      children={
        <div className={styles.container}>
          {step === 1 ? (
            <>
              <MdCancel fontSize={60} color="#e74c3c" />
              <h3 className={styles.title}>
                {msg ||
                  "Your email has not been confirmed yet. We can resend the confirmation code to enable you to activate your account."}
              </h3>
              <Button
                onClick={sendCodeHandler}
                loading={loading}
                className={styles.confirmBtn}
              >
                Resend code
              </Button>
            </>
          ) : (
            ""
          )}
          {step === 2 ? (
            <>
              <MdMarkEmailRead fontSize={60} color="#2ecc71" />
              <h3 className={styles.title}>
                A confirmation code has been sent to your email inbox. Please
                check your email and enter the code.
              </h3>
              <input
                className={styles.codeInput}
                value={confirmationCode}
                onChange={(e) => {
                  const input = e.target.value.trim();
                  const numbersOnly = input.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setConfirmationCode(numbersOnly);
                }}
                placeholder="Enter confirmation code"
              />
              <Button
                loading={loading}
                className={styles.confirmBtn}
                onClick={confirmHandler}
              >
                Confirm
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
      }
    />
  );
}

export default ConfirmAlert;
