import styles from "../../styles/shared/live-suggestion/LiveSuggestionModal.module.css";
import { MdAccessTime, MdLightbulb, MdTrendingUp } from "react-icons/md";
import { getRelativeTime } from "../../utility/formatter-helper";

export default function InfoBox({ info, style = {} }) {
  return (
    <ul className={styles.infoList} style={style}>
      <li className={styles.infoItem}>
        <span className={styles.infoTitle}>
          <MdLightbulb color="#f9ca24" size={18} /> Suggestion:
        </span>
        <span>{info?.prediction}</span>
      </li>
      <li className={styles.infoItem}>
        <span className={styles.infoTitle}>
          <MdTrendingUp color="#34a853" size={18} /> Confidence:
        </span>
        <span>{(info?.confidence * 100)?.toFixed(2)}%</span>
      </li>
      <li className={styles.infoItem}>
        <span className={styles.infoTitle}>
          <MdAccessTime color="#4285f4" size={18} /> Date:
        </span>
        <span>{getRelativeTime(info?.signal_timestamp)}</span>
      </li>
    </ul>
  );
}
