import styles from "../../../styles/Components/trading-assistant/tradeAssistantCards.module.sass";
import AssetDisplay from "../../../ui/AssetDisplay";
import TradeButton from "../../../shared/trade-form/TradeButton";
import HideContentIcon from "../../mini-app/shared/HideContentIcon";
import { Popover, Spin } from "antd";
import { getRelativeTime } from "../../../utility/formatter-helper";
import { formatPrice } from "../../../utility/helper";
import { FaArrowDown, FaArrowUp, FaBrain } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LiveSuggestionModal from "../../../shared/live-suggestion/LiveSuggestionModal";
import AIAssistantServiceActivationModal from "../AIAssistantServiceActivationModal";
import { IoPause } from "react-icons/io5";
import SignalMoreInfo from "./SignalMoreInfo";
import { FaCircleInfo } from "react-icons/fa6";
import moment from "moment";
import { getLiveSuggestion } from "../../../redux/live-suggestion/liveSuggestionSlice";

const icons = {
  buy: <FaArrowUp />,
  sell: <FaArrowDown />,
  hold: <IoPause />,
};

function TradeAssistantCard({ data, remainingMinutes }) {
  const {
    tradingAssistant: {
      getTradeAssistantLoading,
      tradeAssistant,
      aiMarketType,
      allTradeSuggestionData,
    },
    main: { userData },
    profile: { profileData, loading },
    liveSuggestion: { suggestions, status, coinStatus },
    otherCoinsAnalyze: { analyze },
  } = useSelector((state) => state);

  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);
  const [showLiveSuggestionModal, setShowLiveSuggestionModal] = useState(false);
  const [liveSuggestionTarget, setLiveSuggestionTarget] = useState(null);

  const dispatch = useDispatch();

  const stopLossCFC = tradeAssistant?.coefficients?.stop_loss;
  const takeProfitCFC = tradeAssistant?.coefficients?.take_profit;
  const isServiceActive = profileData?.myais_active === "True";

  const liveData = suggestions?.find((s) => s?.symbol === data?.SYMBOL_NAME);

  const currentStatus = coinStatus?.[data?.SYMBOL_NAME];

  const prediction =
    aiMarketType === "crypto" &&
    liveData?.prediction &&
    liveData?.prediction !== "Hold"
      ? liveData?.prediction
      : data?.TRADE_TYPE?.split("/")?.[0];

  const tradeIcon = icons?.[prediction?.toLowerCase()];

  const bestSignal =
    aiMarketType !== "crypto" ||
    (liveData?.prediction && liveData?.prediction !== "Hold")
      ? "15m"
      : "1h";

  const availableExchanges = data?.AVAILABLE_EXCHANGES;
  const coin = data?.SYMBOL_NAME;

  const signalDate = liveData?.signal_timestamp;
  const currentDate = moment();
  const differenceInMinutes = currentDate.diff(signalDate, "minutes");

  useEffect(() => {
    if (isServiceActive && userData?.email && differenceInMinutes > 15) {
      dispatch(
        getLiveSuggestion(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          availableExchanges?.[0] || "kucoin",
          coin,
        ),
      );
    }
  }, [
    isServiceActive,
    differenceInMinutes,
    userData,
    dispatch,
    availableExchanges,
    coin,
  ]);

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <AssetDisplay
          className={styles.asset}
          asset={data?.SYMBOL_NAME}
          isForex={aiMarketType === "forex"}
        />
      </div>

      <TradeButton
        btnClassName={`${
          isServiceActive
            ? prediction === "Buy"
              ? styles.buy
              : prediction === "Sell"
                ? styles.sell
                : styles.hold
            : styles.hideContent
        } ${styles.tradeButton}`}
        data={{ coin: data?.SYMBOL_NAME }}
        style={{ textAlign: "center" }}
        service="aiAssistant"
        onClick={() => {
          if (!isServiceActive) {
            return setShowServiceActivationModal(true);
          }
        }}
      >
        {!isServiceActive ? (
          <HideContentIcon style={{ padding: ".5rem" }} />
        ) : (
          <>
            {tradeIcon}
            {prediction}
          </>
        )}
      </TradeButton>

      <div className={styles.signalSection}>
        <div
          className={`${styles.signal} ${
            bestSignal === "1h" ? styles.strongSignal : styles.poorSignal
          }`}
        >
          <span className={styles.signalLabel}>1H Signal:</span>
          <div
            className={styles.signalInfo}
            style={
              !isServiceActive
                ? { alignSelf: "flex-end", marginLeft: "auto" }
                : {}
            }
          >
            {isServiceActive ? (
              <>
                <span
                  className={`${styles.signalType} ${
                    styles?.[
                      `${data?.TRADE_TYPE?.split(
                        "/",
                      )?.[0]?.toLowerCase()}Status`
                    ]
                  }`}
                >
                  {data?.TRADE_TYPE?.split("/")?.[0]}
                </span>
                <span className={styles.confidence}>
                  (Confidence: {(data?.SIGNAL_STRENGTH * 20).toFixed()}
                  %) -
                </span>
                <span className={styles.time}>
                  {60 - remainingMinutes} minutes ago
                </span>
              </>
            ) : (
              <HideContentIcon
                clickHandler={(e) => {
                  e.stopPropagation();
                  setShowServiceActivationModal(true);
                }}
              />
            )}
          </div>
        </div>

        {aiMarketType === "crypto" && (
          <div
            className={`${styles.signal} ${
              bestSignal === "15m" ? styles.strongSignal : styles.poorSignal
            }`}
          >
            <span className={styles.signalLabel}>15m Signal:</span>
            <div
              className={styles.signalInfo}
              style={
                !isServiceActive
                  ? { alignSelf: "flex-end", marginLeft: "auto" }
                  : {}
              }
            >
              {isServiceActive ? (
                <>
                  {currentStatus === "loading" ? (
                    <Spin size="small" />
                  ) : (
                    <>
                      <span
                        className={`${styles.signalType} ${
                          styles?.[
                            `${
                              liveData?.prediction?.toLowerCase() || "hold"
                            }Status`
                          ]
                        }`}
                      >
                        {liveData?.prediction || "Hold"}
                      </span>
                      <span className={styles.confidence}>
                        (Confidence:{" "}
                        {(liveData?.confidence * 100 || 50).toFixed()}
                        %) -
                      </span>
                      <span className={styles.time}>
                        {liveData
                          ? getRelativeTime(liveData?.signal_timestamp)
                          : "10 minutes ago"}
                      </span>
                    </>
                  )}
                </>
              ) : (
                <HideContentIcon
                  clickHandler={(e) => {
                    e.stopPropagation();
                    setShowServiceActivationModal(true);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.infoSection}>
        <div className={styles.info}>
          <span>Category:</span>
          <Popover
            content={<SignalMoreInfo data={data} />}
            placement="bottom"
            className={styles.categoryTooltip}
          >
            <FaCircleInfo color="#2A86FF" />
            <span>{data?.CATEGORY_INFO?.category || "Blockchain"}</span>
          </Popover>
        </div>

        <div className={styles.info}>
          <span>Current Price:</span>
          <span>${formatPrice(data?.CURRENT_PRICE, 3)}</span>
        </div>

        <div className={styles.info}>
          <span>Stop Loss:</span>
          {isServiceActive ? (
            <span>
              {formatPrice(data?.STOP_LOSS_PERCENT * stopLossCFC, 2)}%
            </span>
          ) : (
            <HideContentIcon />
          )}
        </div>

        <div className={styles.info}>
          <span>Take Profit:</span>
          {isServiceActive ? (
            <span>
              {formatPrice(data?.TAKE_PROFIT_PERCENT * takeProfitCFC, 2)}%
            </span>
          ) : (
            <HideContentIcon />
          )}
        </div>
      </div>

      <button
        className={styles.runAiButton}
        onClick={() => {
          if (!isServiceActive || aiMarketType !== "crypto") {
            return;
          }
          setShowLiveSuggestionModal(true);
          setLiveSuggestionTarget(data?.SYMBOL_NAME);
        }}
        disabled={!isServiceActive || aiMarketType !== "crypto"}
      >
        <FaBrain color="#1CA8FC" />
        Run AI Now
      </button>

      {showLiveSuggestionModal && (
        <LiveSuggestionModal
          open={showLiveSuggestionModal}
          onClose={() => {
            setShowLiveSuggestionModal(false);
            setLiveSuggestionTarget(null);
          }}
          coin={liveSuggestionTarget}
        />
      )}

      <AIAssistantServiceActivationModal
        showActivationModal={showServiceActivationModal}
        setShowActivationModal={setShowServiceActivationModal}
      />
    </div>
  );
}

export default TradeAssistantCard;
