import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../../styles/Components/integration/IntegratedExchangeList.module.css";
import {
  getExchangeBalanceChart,
  getExchangeComprehensiveTradeHistory,
  getExchangePortfolio,
  getExchangeTradeHistory,
  getIntegratedExchanges,
  setSelectedPortfolioExchange,
} from "../../../redux/exchange/actions";
import { Button, Dropdown, Menu, message, Spin } from "antd";
import { IoIosArrowDown } from "react-icons/io";

// img link: https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/kraken.svg
function IntegratedExchangeList({ page = "portfolio" }) {
  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      portfolioLoading,
      selectedPortfolioExchange,
      exchangeHistoryLoading,
      getIntegratedList,
      integratedDataError,
    },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const exchanges = integratedData?.filter((ex) => ex.id !== "cryptocrispy");
  // ?.filter((item) => item?.status === "Active");

  const selectedExchangeIndex = exchanges?.findIndex(
    (item) => item.id === selectedPortfolioExchange,
  );

  const exchangesForMobile =
    selectedExchangeIndex >= 3
      ? [...exchanges].slice(0, 2)
      : [...exchanges].slice(0, 3);

  const exchangeMenuItems = exchanges?.slice(3)?.map((exchange) => {
    return {
      label: (
        <span className={styles.dropdownMenuItem}>
          <img
            className={styles.exchangeImg}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchange.id}.svg`}
            alt={exchange.name}
          />
          {exchange.name}
        </span>
      ),
      key: exchange.id,
      disabled: exchange?.status !== "Active",
    };
  });

  const exchangeMenu = (
    <Menu
      items={exchangeMenuItems}
      selectedKeys={[selectedPortfolioExchange]}
      onClick={(e) => selectExchangeHandler(e.key)}
    ></Menu>
  );

  const selectExchangeHandler = (id) => {
    const exchangeStatus = integratedData?.find((ex) => ex.id === id)?.status;
    if (exchangeStatus !== "Active") {
      return message.info("Exchange is expired!");
    }

    if (page === "portfolio" && portfolioLoading) {
      return;
    }

    if (page === "history" && exchangeHistoryLoading) return;

    dispatch(setSelectedPortfolioExchange(id));
  };

  const refreshIntegratedExchanges = () => {
    dispatch(
      getIntegratedExchanges(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
      ),
    );
  };

  // Set first integrated exchange as default selected exchange
  useEffect(() => {
    if (integratedData.length && !selectedPortfolioExchange) {
      const realExchanges = integratedData?.filter(
        (ex) => ex.id !== "cryptocrispy",
      );
      if (realExchanges?.length) {
        dispatch(setSelectedPortfolioExchange(realExchanges[0].id));
      }
    }
  }, [dispatch, integratedData, selectedPortfolioExchange]);

  useEffect(() => {
    if (
      userData?.email &&
      !integratedDataLoading &&
      !getIntegratedList &&
      !integratedDataError
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [
    dispatch,
    integratedDataLoading,
    userData?.email,
    getIntegratedList,
    integratedDataError,
  ]);

  useEffect(() => {
    if (userData?.email) {
      console.log("user data");
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.email && selectedPortfolioExchange) {
      console.log("get portfolio and history");
      dispatch(
        getExchangePortfolio(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedPortfolioExchange,
        ),
      );
      dispatch(
        getExchangeComprehensiveTradeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedPortfolioExchange,
        ),
      );
      dispatch(
        getExchangeTradeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedPortfolioExchange,
        ),
      );

      dispatch(
        getExchangeBalanceChart(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedPortfolioExchange,
        ),
      );
    }
  }, [dispatch, selectedPortfolioExchange, userData]);

  return (
    <div className={styles.container}>
      {console.log("run list")}
      {/*{title && <h4 className={styles.title}>Integrated Exchange:</h4>}*/}
      {integratedDataLoading ? (
        <div className={styles.loader}>
          <Spin tip={"Please wait"} />
        </div>
      ) : integratedDataError ? (
        <h4>
          Failed to fetched integrated exchange list!{" "}
          <span
            className={styles.retryBtn}
            onClick={refreshIntegratedExchanges}
          >
            Retry
          </span>
        </h4>
      ) : exchanges.length ? (
        <>
          <ul className={styles.exchangeListDesktopVersion}>
            {exchanges.map((item, i) => (
              <li
                className={`${styles.exchangeItem} ${
                  selectedPortfolioExchange === item.id
                    ? styles.selectedExchange
                    : ""
                } 
                ${
                  (page === "portfolio"
                    ? portfolioLoading
                    : exchangeHistoryLoading) &&
                  selectedPortfolioExchange !== item.id
                    ? styles.disable
                    : ""
                } 
                ${item.id === "cryptocrispy" ? styles.virtual : ""} ${item?.status !== "Active" ? styles.disable : ""}`}
                onClick={() => selectExchangeHandler(item.id)}
                key={item.id}
                style={
                  item?.status !== "Active" ? { cursor: "not-allowed" } : {}
                }
              >
                <img
                  className={styles.exchangeImg}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${item.id}.svg`}
                  alt={item.name}
                />
                {item.name}
              </li>
            ))}
          </ul>
          <ul className={styles.exchangeListMobileVersion}>
            {selectedExchangeIndex >= 3 && (
              <li
                className={`${styles.exchangeItem} ${styles.selectedExchange}`}
              >
                <img
                  className={styles.exchangeImg}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchanges[selectedExchangeIndex].id}.svg`}
                  alt={exchanges[selectedExchangeIndex].name}
                />
                {exchanges[selectedExchangeIndex].name}
              </li>
            )}
            {exchangesForMobile.map((item, i) => (
              <li
                className={`${styles.exchangeItem} ${
                  selectedPortfolioExchange === item.id
                    ? styles.selectedExchange
                    : ""
                } ${
                  (page === "portfolio"
                    ? portfolioLoading
                    : exchangeHistoryLoading) &&
                  selectedPortfolioExchange !== item.id
                    ? styles.disable
                    : ""
                } ${item.id === "cryptocrispy" ? styles.virtual : ""}`}
                onClick={() => selectExchangeHandler(item.id)}
                key={item.id}
              >
                <img
                  className={styles.exchangeImg}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${item.id}.svg`}
                  alt={item.name}
                />
                {item.name}
              </li>
            ))}
            {integratedData?.length > 3 && (
              <li>
                <Dropdown
                  overlay={exchangeMenu}
                  trigger={["click"]}
                  disabled={
                    (page === "portfolio" && portfolioLoading) ||
                    (page === "history" && exchangeHistoryLoading)
                  }
                >
                  <Button className={styles.moreBtn}>
                    More <IoIosArrowDown />
                  </Button>
                </Dropdown>
              </li>
            )}
          </ul>
        </>
      ) : (
        <p className={styles.emptyText}>
          No exchange integrated yet.{" "}
          <Link className={styles.emptyLink} to={"/integration"}>
            Integrate now!
          </Link>
        </p>
      )}
    </div>
  );
}

export default IntegratedExchangeList;
