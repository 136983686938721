import moment from "moment";

export const capitalizeString = (str) => {
  return str ? str[0]?.toUpperCase() + str.slice(1) : "";
};

export const routeConvertor = {
  alert: "alert-system",
  mybot: "bot-automation",
  myais: "ai-assistant",
  integ: "integration",
  profl: "profile/info",
  dashb: "dashboard",
  insai: "Insight",
  myapi: "developer-api",
  price: "pricing-billing",
  dashm: "mini-dashboard",
  signl: "mini-ai-signals",
  mnbot: "mini-bots",
  plnrf: "mini-pricing-plan",
};

export const getRelativeTime = (date) => {
  const now = moment();
  const targetDate = moment(date);
  const duration = moment.duration(now.diff(targetDate));

  if (duration.years() > 0) {
    return `${duration.years()} year${duration.years() > 1 ? "s" : ""} ago`;
  } else if (duration.months() > 0) {
    return `${duration.months()} month${duration.months() > 1 ? "s" : ""} ago`;
  } else if (duration.weeks() > 0) {
    return `${duration.weeks()} week${duration.weeks() > 1 ? "s" : ""} ago`;
  } else if (duration.days() > 0) {
    return `${duration.days()} day${duration.days() > 1 ? "s" : ""} ago`;
  } else if (duration.hours() > 0) {
    return `${duration.hours()} hour${duration.hours() > 1 ? "s" : ""} ago`;
  } else if (duration.minutes() > 0) {
    return `${duration.minutes()} minute${
      duration.minutes() > 1 ? "s" : ""
    } ago`;
  } else {
    return `${duration.seconds()} second${
      duration.seconds() > 1 ? "s" : ""
    } ago`;
  }
};

const calcRemainingTime = (date) => {
  const now = moment();
  const targetDate = moment(date);
  return moment.duration(targetDate.diff(now));
};

export const getCustomRemainingTime = (date, suffix, prefix) => {
  const duration = calcRemainingTime(new Date(date));

  if (duration.years() > 0) {
    return `${prefix ? `${prefix} ` : ""}${duration.years()} year${
      duration.years() > 1 ? "s" : ""
    }${suffix ? ` ${suffix}` : ""}`;
  } else if (duration.months() > 0) {
    return `${prefix ? `${prefix} ` : ""}${duration.months()} month${
      duration.months() > 1 ? "s" : ""
    }${suffix ? ` ${suffix}` : ""}`;
  } else if (duration.weeks() > 0) {
    return `${prefix ? `${prefix} ` : ""}${duration.weeks()} week${
      duration.weeks() > 1 ? "s" : ""
    }${suffix ? ` ${suffix}` : ""}`;
  } else if (duration.days() > 0) {
    return `${prefix ? `${prefix} ` : ""}${duration.days()} day${
      duration.days() > 1 ? "s" : ""
    }${suffix ? ` ${suffix}` : ""}`;
  } else if (duration.hours() > 0) {
    return `${prefix ? `${prefix} ` : ""}${duration.hours()} hour${
      duration.hours() > 1 ? "s" : ""
    }${suffix ? ` ${suffix}` : ""}`;
  } else if (duration.minutes() > 0) {
    return `${prefix ? `${prefix} ` : ""}${duration.minutes()} minute${
      duration.minutes() > 1 ? "s" : ""
    }${suffix ? ` ${suffix}` : ""}`;
  } else {
    return `${prefix ? `${prefix} ` : ""}${duration.seconds()} second${
      duration.seconds() > 1 ? "s" : ""
    }${suffix ? ` ${suffix}` : ""}`;
  }
};

export const getRemainingTime = (date, showExpired, showRemained = true) => {
  const duration = calcRemainingTime(date);

  if (duration.asSeconds() <= 0 && showExpired) {
    return "Expired";
  }

  if (duration.years() > 0) {
    return `${duration.years()} year${duration.years() > 1 ? "s" : ""}${
      showRemained ? " remaining" : " later"
    }`;
  } else if (duration.months() > 0) {
    return `${duration.months()} month${duration.months() > 1 ? "s" : ""}${
      showRemained ? " remaining" : " later"
    }`;
  } else if (duration.weeks() > 0) {
    return `${duration.weeks()} week${duration.weeks() > 1 ? "s" : ""}${
      showRemained ? " remaining" : " later"
    }`;
  } else if (duration.days() > 0) {
    return `${duration.days()} day${duration.days() > 1 ? "s" : ""}${
      showRemained ? " remaining" : " later"
    }`;
  } else if (duration.hours() > 0) {
    return `${duration.hours()} hour${duration.hours() > 1 ? "s" : ""}${
      showRemained ? " remaining" : " later"
    }`;
  } else if (duration.minutes() > 0) {
    return `${duration.minutes()} minute${duration.minutes() > 1 ? "s" : ""}${
      showRemained ? " remaining" : " later"
    }`;
  } else {
    return `${duration.seconds()} second${duration.seconds() > 1 ? "s" : ""}${
      showRemained ? " remaining" : " later"
    }`;
  }
};

export const formattedSubscription = {
  monthly: "Monthly Basic",
  yearly: "Yearly Basic",
  daily: "Daily Basic",
  monthly_pro: "Monthly Pro",
  daily_pro: "Daily Pro",
  yearly_pro: "Yearly Pro",
};
