import axios from "axios";
import {
  GET_PROFILE_DATA_ERROR,
  GET_PROFILE_DATA_REQUEST,
  GET_PROFILE_DATA_SUCCESS,
  UPDATE_PROFILE_ATTRIBUTE,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from "./consts";
import { notify } from "../../utility/Toastify/toastify";
import { errorHandler } from "../../utility/helper";
import { axiosErrorHandler } from "../../utility/action-helper";

export const getProfileData =
  (email, user, isFast = "", retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_PROFILE_DATA_REQUEST });

    let data = {
      platform_mode: "profile",
      email,
      user,
      request_type: "get_profile_attribute",
    };

    if (!!isFast) data.request_type = "get_profile_attribute_fast";

    await axios
      .post(
        "https://jvp2fozucc.execute-api.us-east-1.amazonaws.com/dev/api",
        data,
      )
      .then((res) => {
        dispatch({ type: GET_PROFILE_DATA_SUCCESS, payload: res?.data });
        if (isFast) {
          dispatch(getProfileData(email, user));
        }
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getProfileData(email, user, isFast, retryCount + 1),
          GET_PROFILE_DATA_ERROR,
          user,
          email,
          `get_profile_attribute`,
        );
        // dispatch({ type: GET_PROFILE_DATA_ERROR, payload: err?.response });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const updateProfile =
  (email, user, attribute_names, values, showNotif = true, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    await axios
      .post("https://jvp2fozucc.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "profile",
        email,
        user,
        request_type: "set_profile_attribute",
        attribute_names: attribute_names,
        values: values,
      })
      .then((res) => {
        dispatch({ type: UPDATE_PROFILE_SUCCESS });

        if (showNotif) {
          notify("Successfully updated!", "success");
        }
        dispatch(getProfileData(email, user));
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            updateProfile(
              email,
              user,
              attribute_names,
              values,
              showNotif,
              retryCount + 1,
            ),
          UPDATE_PROFILE_ERROR,
          user,
          email,
          "set_profile_attribute",
        );
        // dispatch({ type: UPDATE_PROFILE_ERROR });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);

        // notify("An error occurred!", "error");
      });
  };

export const updateProfileAttribute = (attribute, value) => {
  return { type: UPDATE_PROFILE_ATTRIBUTE, payload: { attribute, value } };
};
