import styles from "../styles/ui/AssetDisplay.module.css";
import React from "react";

function AssetDisplay({ asset, style = {}, className = "", isForex = false }) {
  return (
    <div className={`${styles.coin} ${className}`} style={style}>
      <div className={styles.coinImage}>
        <img
          src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${asset}.png`}
          alt={asset}
          className={styles.symbolImage}
        />
        {!isForex && (
          <img
            src="https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/USDT.png"
            alt="tether"
            className={styles.tetherImage}
          />
        )}
      </div>
      <div>
        {asset}
        <span className={styles.usdtLabel}> / USDT</span>
      </div>
    </div>
  );
}

export default AssetDisplay;
