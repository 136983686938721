import { Button, Radio, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getExchangesInfo,
  integrateWithExchange,
} from "../../../redux/exchange/actions";
import { useEffect, useState } from "react";
import styles from "../../../styles/Components/mini-app/shared/NoIntegratedExchange.module.css";
import ExchangesListDropdownMenu from "./ExchangesListDropdownMenu";
import IntegrationForm from "./IntegrationForm";
import { IoCloseCircleSharp } from "react-icons/io5";

function NoIntegratedExchange({
  setExchange,
  exchange,
  createBotHandler,
  title,
}) {
  const {
    main: { userData },
    exchangeState: {
      integrateExchangeLoading,
      integrateExchangeMessage,
      exchangesInfo,
      exchangesLoading,
      integratedData,
      integratedDataLoading,
      integrateExchangeError,
      exchangesConnectionInfo,
      trustedIps,
    },
    botState: { createBotLoading, createBotError, getBotListLoading },
  } = useSelector((state) => state);

  const [exchangeType, setExchangeType] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [isRestricted, setIsRestricted] = useState(false);

  const isIntegratedWithVirtualExchange = integratedData?.find(
    (ex) => ex.id === "cryptocrispy",
  );

  const dispatch = useDispatch();

  const connectionInfo = exchangesConnectionInfo.find(
    (ex) => ex.id === exchange,
  );

  const isValidForIntegration =
    exchange &&
    ((!isIntegratedWithVirtualExchange && exchange === "cryptocrispy") ||
      (apiKey &&
        (!connectionInfo?.mandatory?.includes("passphrase") || passphrase) &&
        (!connectionInfo?.mandatory?.includes("secret") || secretKey)));

  const handleExchangeType = (e) => {
    setExchange("");
    const targetButton = e.target.closest("span");
    if (targetButton) {
      const type = targetButton.id;
      setExchangeType(type);

      if (type === "virtual") {
        setExchange("cryptocrispy");
      }
    }
  };

  const integrateWithVirtualExchange = () => {
    dispatch(
      integrateWithExchange(
        userData["custom:custom_username"],
        userData?.email,
        "cryptocrispy",
        "cryptocrispy",
        "cryptocrispy",
        "",
        "",
        "CryptoCrispy",
        false,
      ),
    );
  };

  const integrateWithExchangeHandler = () => {
    if (
      integrateExchangeLoading ||
      createBotLoading ||
      !isValidForIntegration ||
      getBotListLoading
    )
      return;

    if (exchange !== "cryptocrispy") {
      const ip = isRestricted ? trustedIps[0] : "";
      dispatch(
        integrateWithExchange(
          userData["custom:custom_username"],
          userData?.email,
          apiKey,
          secretKey,
          exchange,
          passphrase,
          ip,
          connectionInfo?.name,
          false,
        ),
      );
    } else {
      integrateWithVirtualExchange();
    }
  };
  useEffect(() => {
    if (
      userData?.["cognito:username"] &&
      !Object.keys(exchangesInfo).length &&
      !exchangesLoading
    ) {
      dispatch(getExchangesInfo());
    }
  }, [dispatch, exchangesInfo, exchangesLoading, userData]);

  useEffect(() => {
    if (Object.keys(integrateExchangeMessage).length > 0) {
      createBotHandler();
    }
  }, [integrateExchangeMessage]);

  useEffect(() => {
    if (
      integratedData.find((ex) => ex.id === exchange) &&
      (exchangeType !== "virtual" || exchange !== "cryptocrispy")
    ) {
      setExchangeType(null);
    }
  }, [exchange, integratedData]);

  return (
    <div>
      <h4>{title || "No exchange integrated yet."}</h4>

      <div className={styles.options} onClick={handleExchangeType}>
        {!isIntegratedWithVirtualExchange && (
          <span
            id={"virtual"}
            className={`${styles.option} ${
              exchangeType === "virtual" ? styles.selected : ""
            }`}
          >
            Test Exchange
          </span>
        )}
        <span
          id={"otherExchange"}
          className={`${styles.option} ${
            exchangeType === "otherExchange" ? styles.selected : ""
          }`}
        >
          Explore exchanges
        </span>
      </div>

      {exchangeType === "otherExchange" && (
        <>
          <ExchangesListDropdownMenu
            setExchange={setExchange}
            selectedExchange={exchange}
            customStyle={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          />

          {exchange && (
            <IntegrationForm
              exchange={exchange}
              apiKey={apiKey}
              setApiKey={setApiKey}
              secretKey={secretKey}
              setSecretKey={setSecretKey}
              passphrase={passphrase}
              setPassphrase={setPassphrase}
              isRestricted={isRestricted}
              setIsRestricted={setIsRestricted}
            />
          )}
        </>
      )}

      {exchangeType && (
        <div className={styles.actionBox}>
          {(integrateExchangeError || createBotError) && (
            <span className={styles.errorMessage}>
              <IoCloseCircleSharp />
              {integrateExchangeError
                ? integrateExchangeError?.response?.data?.MESSAGE
                : "Something went wrong!"}
            </span>
          )}

          {(integrateExchangeLoading ||
            createBotLoading ||
            getBotListLoading) && (
            <Spin
              className={styles.loader}
              size={"small"}
              tip={`${
                integrateExchangeLoading ? "Integrating" : "Creating bot"
              }`}
            />
          )}
          <Button
            loading={
              integrateExchangeLoading || createBotLoading || getBotListLoading
            }
            disabled={!isValidForIntegration}
            className={styles.actionBtn}
            onClick={integrateWithExchangeHandler}
          >
            Integrate and Create Bot
          </Button>
        </div>
      )}
    </div>
  );
}

export default NoIntegratedExchange;
