import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetPrice } from "../../../redux/trade-form/tradeFormSlice";
import useAssetAmount from "./useAssetAmount";

const useAssetPrice = () => {
  const {
    main: { userData },
    tradeForm: { targetCoin, targetExchange, tradeType },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const intervalRef = useRef(null);

  const { isAssetInPortfolio } = useAssetAmount();

  useEffect(() => {
    if (!userData?.email || !targetCoin || !targetExchange) return;

    if (tradeType === "sell" && !isAssetInPortfolio) return;

    dispatch(
      getAssetPrice(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        targetExchange,
        targetCoin,
      ),
    ); // Fetch on mount

    // Set up polling every 60 sec
    intervalRef.current = setInterval(() => {
      dispatch(
        getAssetPrice(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          targetExchange,
          targetCoin,
        ),
      );
    }, 60000);

    return () => clearInterval(intervalRef.current); // Cleanup on unmount
  }, [
    dispatch,
    isAssetInPortfolio,
    targetCoin,
    targetExchange,
    tradeType,
    userData,
  ]);

  return null; // No need to return data since Redux handles state
};

export default useAssetPrice;
