import React from "react";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { FaAngleLeft } from "react-icons/fa";
import TradeTypes from "./TradeTypes";
import StatusBar from "./trade-size/StatusBar";
import SetTradeAmount from "./trade-size/SetTradeAmount";
import PlaceOrderButton from "./PlaceOrderButton";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../../../redux/trade-form/tradeFormSlice";
import ErrorMessage from "./messages/ErrorMessage";
import useAssetAmount from "../hooks/useAssetAmount";
import AISuggestion from "./AISuggestion";
import useCheckOrderLimits from "../hooks/useCheckOrderLimits";
import TradeHandlers from "./TradeHandlers";

function TradeSize() {
  const {
    tradeForm: { assetPrice, assetPriceStatus, tradeType },
  } = useSelector((state) => state);

  const { isAssetInPortfolio } = useAssetAmount();
  const { step2ErrorMessage } = useCheckOrderLimits();

  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.tradeSizeHeader}>
        <span
          className={styles.backBtn}
          onClick={() => dispatch(changeStep(1))}
        >
          <FaAngleLeft />
          Back
        </span>
      </div>
      <StatusBar />

      <TradeTypes />

      <SetTradeAmount />

      <AISuggestion />

      <div className={styles.footer}>
        <div className={styles.footerStatusbar}>
          {!assetPrice && assetPriceStatus === "loading" && (
            <div className={styles.loadingStatus}>
              <Spin size="small" />
              <p>Fetching asset price</p>
            </div>
          )}

          {!isAssetInPortfolio && tradeType === "sell" && (
            <ErrorMessage>This asset is not in your portfolio</ErrorMessage>
          )}

          {assetPrice && step2ErrorMessage && (
            <ErrorMessage>{step2ErrorMessage}</ErrorMessage>
          )}
        </div>
        <div className={styles.handlerBtns}>
          <TradeHandlers />
          <PlaceOrderButton />
        </div>
      </div>
    </>
  );
}

export default TradeSize;
