/** @format */

import styles from "../../styles/shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab.module.css";
import { MdNewspaper, MdSwapHoriz } from "react-icons/md";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Tour } from "antd";
import { FaChartLine, FaFlask, FaUniversity } from "react-icons/fa";

function ExchangesAndPaperTradingTab({ activeTab, pageType }) {
	const [showTour, setShowTour] = useState(false);

	const navigate = useNavigate();
	const simulatedTradingRef = useRef(null);

	const tourSteps = [
		{
			title: <FaChartLine color={"#0C4CFC"} size={18} />,
			description: (
				<p
					style={{
						marginTop: "-16px",
						fontSize: "16px",
						marginBottom: 0,
						color: "#2B303A",
					}}>
					Explore trading risk-free on a test exchange.
				</p>
			),
			target: () => simulatedTradingRef.current,
		},
	];

	const handleIntegratedExchangeNavigation = () => {
		if (activeTab === "exchange") return;
		if (pageType === "portfolio") {
			navigate("/integration/portfolio");
		}
		if (pageType === "history") {
			navigate("/integration/trade-history");
		}
	};
	const handlePaperTradingNavigation = () => {
		if (activeTab === "paper") return;
		if (pageType === "portfolio") {
			navigate("/ai-assistant/portfolio");
		}
		if (pageType === "history") {
			navigate("/ai-assistant/trade-history");
		}
	};

	const handleCloseTour = () => {
		setShowTour(false);
		localStorage.setItem("hasSeenExchangeTour", "true");
	};

	useEffect(() => {
		if (activeTab === "exchange") {
			const hasSeenTour = localStorage.getItem("hasSeenExchangeTour");
			if (!hasSeenTour) {
				setShowTour(true);
			}
		}
	}, [activeTab]);

	return (
		<ul className={styles.container}>
			<li
				className={`${styles.tabItem} ${
					activeTab === "exchange" ? styles.activeTab : ""
				}`}
				onClick={handleIntegratedExchangeNavigation}>
				{/*<MdSwapHoriz size={18} />*/}
				<FaUniversity size={18} />
				{/*<span className={styles.showOnDesktop}>Integrated </span>*/}
				Real Exchanges
			</li>
			<li
				className={`${styles.tabItem} ${
					activeTab === "paper" ? styles.activeTab : ""
				}`}
				onClick={handlePaperTradingNavigation}
				ref={simulatedTradingRef}>
				{/*<MdNewspaper />*/}
				<FaFlask />
				Test Exchange
			</li>
			<Tour
				open={showTour}
				steps={tourSteps}
				onClose={handleCloseTour}
				animated
			/>
		</ul>
	);
}

export default ExchangesAndPaperTradingTab;
