import { Modal, Spin } from "antd";
import React, { useEffect } from "react";
import ExchangesList from "./trade-modal/ExchangesList";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStep,
  getTargetPortfolio,
  resetForm,
  setSuggestedCoin,
  setTargetCoin,
  setTargetExchange,
  setTradeType,
} from "../../redux/trade-form/tradeFormSlice";
import styles from "../../styles/shared/trade-form/TradeModal.module.css";
import SelectTradeType from "./trade-modal/SelectTradeType";
import SelectAsset from "./trade-modal/SelectAsset";
import NotEnoughBalance from "./trade-modal/messages/NotEnoughBalance";
import useCheckBalance from "./hooks/useCheckPortfolio";
import EmptyPortfolio from "./trade-modal/messages/EmptyPortfolio";
import TradeSize from "./trade-modal/TradeSize";
import useAssetAmount from "./hooks/useAssetAmount";
import NotAvailableAsset from "./trade-modal/messages/NotAvailableAsset";
import AISuggestion from "./trade-modal/AISuggestion";
import useAISuggestion from "./hooks/useAISuggestion";
import useCheckOrderLimits from "./hooks/useCheckOrderLimits";
import capitalize from "antd/es/_util/capitalize";
import TradeHandlers from "./trade-modal/TradeHandlers";
import ErrorMessage from "./trade-modal/messages/ErrorMessage";
import OrderResult from "./trade-modal/OrderResult";
import { showRatingModal } from "../../redux/rating-modal/actions";
import { useLocation } from "react-router-dom";

function TradeModal({
  show,
  setShow,
  // mode,
  data,
}) {
  const {
    tradeForm: {
      targetPortfolioStatus,
      targetCoin,
      targetExchange,
      tradeType,
      step,
      orderStatus,
      suggestedCoin,
    },
    main: { userData },
    exchangeState: { availableCoinsStatus },
  } = useSelector((state) => state);

  // const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  const { canBuy } = useCheckBalance();
  const { isAssetAvailable } = useAssetAmount();
  const { isLoading: loadingSuggestion } = useAISuggestion();
  useCheckOrderLimits();
  const { errorMessage, step1Validation } = useCheckOrderLimits();

  const loadingMessage =
    targetPortfolioStatus === "loading" || availableCoinsStatus === "loading"
      ? "Fetching portfolio..."
      : loadingSuggestion
        ? "Loading AI trade suggestion"
        : "";
  const isLoading = targetPortfolioStatus === "loading" || loadingMessage;

  const location = useLocation();

  const currentPage = location.pathname.includes("ai-assistant")
		? "ai"
		: location.pathname.includes("insight")
		? "insight"
		: location?.pathname?.includes("integration")
		? "integration"
		: null;

  const canProceed = !!(
    targetExchange &&
    targetCoin &&
    targetPortfolioStatus === "success" &&
    isAssetAvailable &&
    tradeType &&
    step1Validation
  );

  const closeModal = () => {
    dispatch(resetForm());
    setShow(false);
    if (currentPage && data?.coin) {
			dispatch(showRatingModal(currentPage));
		}
  };

  const goNext = () => {
    if ((tradeType === "buy" && !canBuy) || !canProceed) return;
    dispatch(changeStep(2));
  };

  const fetchPortfolio = () => {
    return dispatch(
      getTargetPortfolio(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        targetExchange,
      ),
    );
  };

  // set form default values if received it as data prop
  useEffect(() => {
    if (data) {
      if (data?.exchange && !targetExchange) {
        dispatch(setTargetExchange(data?.exchange));
      }
      if (data?.tradeType && !tradeType) {
        dispatch(setTradeType(data?.tradeType));
      }
      if (data?.coin && !suggestedCoin) {
        // dispatch(setTargetCoin(data?.coin));
        dispatch(setSuggestedCoin(data?.coin));
      }
    }
  }, [data, dispatch, suggestedCoin, targetExchange, tradeType]);

  return (
    <Modal
      centered
      footer={null}
      width={800}
      open={show}
      onCancel={closeModal}
      maskClosable={false}
      closable={orderStatus !== "loading"}
    >
      {step === 1 && (
        <>
          {/*{mode === "trade-btn" && <ExchangesList mode={mode} />}*/}
          <ExchangesList />
          {/*{console.log("modal")}*/}
          {/*{mode === "trade-btn" && <SelectAsset />}*/}
          <SelectAsset />
          <AISuggestion />
          <SelectTradeType />

          <div className={styles.footer}>
            {isLoading && (
              <div className={styles.loadingStatus}>
                <Spin size="small" />
                <p>{loadingMessage}</p>
              </div>
            )}

            <NotEnoughBalance />
            <EmptyPortfolio />
            <NotAvailableAsset />

            {targetPortfolioStatus === "error" && (
              <span className={styles.retryBtn} onClick={fetchPortfolio}>
                Retry to fetch portfolio
              </span>
            )}

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <div className={styles.handlerBtns}>
              <TradeHandlers />
              <span
                className={`${styles.nextBtn} ${(tradeType === "buy" && !canBuy) || !canProceed ? styles.disabled : ""}`}
                onClick={goNext}
              >
                Manual {capitalize(tradeType || "order")}
              </span>
            </div>
          </div>
        </>
      )}

      {step === 2 && <TradeSize />}

      {step === 3 && <OrderResult />}
    </Modal>
  );
}

export default TradeModal;
