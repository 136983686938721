import { Button, Checkbox, Empty, message, Modal, Select, Spin } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../styles/shared/live-suggestion/LiveSuggestionButton.module.css";
import { IoMdArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableCoins } from "../../redux/exchange/actions";
import { getLiveSuggestion } from "../../redux/live-suggestion/liveSuggestionSlice";
import SelectExchange from "../SelectExchange";
import { Link, useSearchParams } from "react-router-dom";
import alert from "../../components/alerts/Alert";
import useServiceStatus from "../../hooks/useServiceStatus";

const LiveSuggestionModal = ({ show, setShow, coins }) => {
  const {
    main: { userData },
    exchangeState: { availableCoinsData, exchangesLoading },
    liveSuggestion: { suggestions, status, errorMessage, coinStatus },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [selectedExchange, setSelectedExchange] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [doBacktest, setDoBacktest] = useState(true); // State for checkbox
  // state to check user request for live or not
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const symbolsInputRef = useRef(null);

  const coinSignal = suggestions?.find((s) => s?.symbol === selectedSymbol);
  const liveStatus = coinStatus?.[selectedSymbol];

  const exchangeAssets = availableCoinsData?.[selectedExchange] || [];

  const loadingMessage =
    status === "loading"
      ? "Fetching signals..."
      : coinStatus?.[selectedSymbol] === "loading"
        ? "Fetching coins information..."
        : exchangesLoading
          ? "Fetching exchanges..."
          : "";

  const handleCloseModal = useCallback(() => {
    setSelectedExchange("");
    setSelectedSymbol("");
    setIsSubmitted(false);
    setShow(false);
  }, [setShow]);

  const handleExchangeChange = (value) => {
    setSelectedExchange(value);
    setSelectedSymbol(""); // Reset selected coin when exchange changes
    if (availableCoinsData?.[value]) return;
    return dispatch(getAvailableCoins(value));
  };

  const handleSymbolChange = (value) => {
    symbolsInputRef?.current?.blur();
    setSelectedSymbol(value);
  };

  const handleSubmit = async () => {
    if (!selectedSymbol || !selectedExchange) {
      message.error("Please select both an exchange and a coin.");
      return;
    }

    setIsSubmitted(true);

    const exchange =
      selectedExchange === "cryptocrispy" || selectedExchange === "swyftx"
        ? "binance"
        : selectedExchange;
    return dispatch(
      getLiveSuggestion(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        exchange,
        selectedSymbol,
        doBacktest,
      ),
    );
  };

  useEffect(() => {
    if (
      !!coinSignal &&
      selectedSymbol &&
      liveStatus === "success" &&
      selectedExchange &&
      isSubmitted
    ) {
      searchParams.set("liveSymbol", selectedSymbol);
      setSearchParams(searchParams);

      handleCloseModal();
      return alert("", "Successfully processed", "success", {
        cancel: "Close",
      });
    }
  }, [
    coinSignal,
    liveStatus,
    selectedExchange,
    selectedSymbol,
    isSubmitted,
    handleCloseModal,
    searchParams,
    setSearchParams,
  ]);

  // useEffect(() => {
  //   if (userData?.email && !integratedDataLoading && !getIntegratedList) {
  //     dispatch(
  //       getIntegratedExchanges(
  //         userData?.["custom:custom_username"],
  //         userData?.["cognito:username"],
  //       ),
  //     );
  //   }
  // }, [dispatch, getIntegratedList, integratedDataLoading, userData]);

  // Automatically set default exchange if no integrations are available
  // useEffect(() => {
  //   if (integratedData.length === 0 && !integratedDataLoading) {
  //     setSelectedExchange(defaultExchange);
  //   }
  //
  //   if (integratedDataLoading) {
  //     setSelectedExchange(null);
  //   }
  // }, [integratedData?.length, integratedDataLoading]);

  // FETCH COINS LIST
  // useEffect(() => {
  //   if (!topCoins?.length && !get200CoinsLoading && !get200CoinsError) {
  //     dispatch(
  //       get200Coins(
  //         userData?.["cognito:username"],
  //         userData?.["custom:custom_username"],
  //       ),
  //     );
  //   }
  // }, [dispatch, get200CoinsError, get200CoinsLoading, topCoins?.length]);

  // useEffect(() => {
  //   if (selectedExchange && selectedExchange !== "cryptocrispy") {
  //     dispatch(
  //       getAssetList(
  //         userData?.["custom:custom_username"],
  //         userData?.["cognito:username"],
  //         selectedExchange,
  //       ),
  //     );
  //   }
  // }, [selectedExchange]);

  return (
    <Modal open={show} onCancel={handleCloseModal} centered footer={null}>
      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select an Exchange
        </h4>
        <SelectExchange
          value={selectedExchange}
          onChange={handleExchangeChange}
          style={{ width: "100%" }}
        />
      </div>

      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select a Coin
        </h4>
        <Select
          style={{ width: "100%" }}
          ref={symbolsInputRef}
          placeholder="Select a coin"
          value={selectedSymbol}
          onChange={handleSymbolChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          disabled={!selectedExchange}
        >
          {coinStatus?.[selectedSymbol] === "loading" ? (
            <Select.Option disabled>Loading...</Select.Option>
          ) : exchangeAssets.length > 0 ? (
            exchangeAssets?.map((asset) => (
              <Select.Option
                key={asset}
                value={asset}
                className={styles.option}
              >
                {asset}
              </Select.Option>
            ))
          ) : (
            <Select.Option disabled className={styles.emptyOption}>
              <Empty imageStyle={{ width: "80px", height: "60px" }} />
            </Select.Option>
          )}
        </Select>

        {!exchangeAssets.length &&
          coinStatus?.[selectedSymbol] !== "loading" &&
          selectedExchange && (
            <p className={styles.errorMessage}>
              Please <Link to="/integration">integrate</Link> first!
            </p>
          )}
      </div>

      <div className={styles.box}>
        <Checkbox
          checked={doBacktest}
          onChange={(e) => setDoBacktest(e.target.checked)}
        >
          Do Backtest
        </Checkbox>
      </div>

      <div className={styles.footer}>
        {(coinStatus?.[selectedSymbol] === "loading" ||
          status === "loading" ||
          exchangesLoading) && (
          <div className={styles.loader}>
            <Spin size="small" />
            <p>{loadingMessage}</p>
          </div>
        )}
        <Button
          onClick={handleSubmit}
          loading={status === "loading"}
          className={styles.submitButton}
          disabled={!selectedSymbol || !selectedSymbol}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

function LiveSuggestionButton({
  title = "",
  btnClassName = "",
  btnStyle = {},
}) {
  const [showModal, setShowModal] = useState(false);

  const { isActive } = useServiceStatus("aiAssistant");

  const clickHandler = () => {
    if (!isActive) return message.info("Activate AI Assistant service first!");

    setShowModal(true);
  };

  return (
    <>
      <span
        className={`${styles.mainBtn} ${btnClassName} ${!isActive ? styles.disabled : ""}`}
        style={btnStyle}
        onClick={clickHandler}
      >
        {title || "Ask for live suggestion"}
      </span>

      {showModal && (
        <LiveSuggestionModal show={showModal} setShow={setShowModal} />
      )}
    </>
  );
}

export default LiveSuggestionButton;
