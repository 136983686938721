export const GET_EXCHANGES_INFO_REQUEST = "GET_EXCHANGES_INFO_REQUEST";
export const GET_EXCHANGES_INFO_SUCCESS = "GET_EXCHANGES_INFO_SUCCESS";
export const GET_EXCHANGES_INFO_ERROR = "GET_EXCHANGES_INFO_ERROR";

export const INTEGRATE_EXCHANGE_REQUEST = "INTEGRATE_EXCHANGE_REQUEST";
export const INTEGRATE_EXCHANGE_SUCCESS = "INTEGRATE_EXCHANGE_SUCCESS";
export const INTEGRATE_EXCHANGE_ERROR = "INTEGRATE_EXCHANGE_ERROR";

export const GET_INTEGRATED_EXCHANGES_REQUEST =
  "GET_INTEGRATED_EXCHANGES_REQUEST";
export const GET_INTEGRATED_EXCHANGES_SUCCESS =
  "GET_INTEGRATED_EXCHANGES_SUCCESS";
export const GET_INTEGRATED_EXCHANGES_ERROR = "GET_INTEGRATED_EXCHANGES_ERROR";

export const EXCHANGE_TRADE_HISTORY_REQUEST = "EXCHANGE_TRADE_HISTORY_REQUEST";
export const EXCHANGE_TRADE_HISTORY_SUCCESS = "EXCHANGE_TRADE_HISTORY_SUCCESS";
export const EXCHANGE_TRADE_HISTORY_ERROR = "EXCHANGE_TRADE_HISTORY_ERROR";

export const EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_REQUEST =
  "EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_REQUEST";
export const EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_SUCCESS =
  "EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_SUCCESS";
export const EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_ERROR =
  "EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_ERROR";

export const ORDER_TRADE_REQUEST = "ORDER_TRADE_REQUEST";
export const ORDER_TRADE_SUCCESS = "ORDER_TRADE_SUCCESS";
export const ORDER_TRADE_ERROR = "ORDER_TRADE_ERROR";

export const GET_EXCHANGE_PRICE_REQUEST = "GET_EXCHANGE_PRICE_REQUEST";
export const GET_EXCHANGE_PRICE_SUCCESS = "GET_EXCHANGE_PRICE_SUCCESS";
export const GET_EXCHANGE_PRICE_ERROR = "GET_EXCHANGE_PRICE_ERROR";

export const GET_EXCHANGE_PORTFOLIO_REQUEST = "GET_EXCHANGE_PORTFOLIO_REQUEST";
export const GET_EXCHANGE_PORTFOLIO_SUCCESS = "GET_EXCHANGE_PORTFOLIO_SUCCESS";
export const GET_EXCHANGE_PORTFOLIO_ERROR = "GET_EXCHANGE_PORTFOLIO_ERROR";

export const GET_ASSETS_REQUEST = "GET_ASSETS_REQUEST";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";
export const GET_ASSETS_ERROR = "GET_ASSETS_ERROR";

export const GET_CONNECTION_INFO_REQUEST = "GET_CONNECTION_INFO_REQUEST";
export const GET_CONNECTION_INFO_SUCCESS = "GET_CONNECTION_INFO_SUCCESS";
export const GET_CONNECTION_INFO_ERROR = "GET_CONNECTION_INFO_ERROR";

export const SET_SELECTED_PORTFOLIO_EXCHANGE =
  "SET_SELECTED_PORTFOLIO_EXCHANGE";
export const SET_PREVIOUS_PATH = "SET_PREVIOUS_PATH";
export const SET_EXCHANGE_FOR_CONNECTION = "SET_EXCHANGE_FOR_CONNECTION";

// LINK WITH EXCHANGE
export const LINK_WITH_EXCHANGE_REQUEST = "LINK_WITH_EXCHANGE_REQUEST";
export const LINK_WITH_EXCHANGE_SUCCESS = "LINK_WITH_EXCHANGE_SUCCESS";
export const LINK_WITH_EXCHANGE_ERROR = "LINK_WITH_EXCHANGE_ERROR";

// UNLINK FROM EXCHANGE
export const UNLINK_FROM_EXCHANGE_REQUEST = "UNLINK_FROM_EXCHANGE_REQUEST";
export const UNLINK_FROM_EXCHANGE_SUCCESS = "UNLINK_FROM_EXCHANGE_SUCCESS";
export const UNLINK_FROM_EXCHANGE_ERROR = "UNLINK_FROM_EXCHANGE_ERROR";

export const GET_EXCHANGE_PORTFOLIO_FAST_REQUEST =
  "GET_EXCHANGE_PORTFOLIO_FAST_REQUEST";
export const GET_EXCHANGE_PORTFOLIO_FAST_SUCCESS =
  "GET_EXCHANGE_PORTFOLIO_FAST_SUCCESS";
export const GET_EXCHANGE_PORTFOLIO_FAST_ERROR =
  "GET_EXCHANGE_PORTFOLIO_FAST_ERROR";
export const UPDATE_EXCHANGE_PORTFOLIO_DATA = "UPDATE_EXCHANGE_PORTFOLIO_DATA";
export const CHECK_EXCHANGE_IP_REQUEST = "CHECK_EXCHANGE_IP_REQUEST";
export const CHECK_EXCHANGE_IP_SUCCESS = "CHECK_EXCHANGE_IP_SUCCESS";
export const CHECK_EXCHANGE_IP_ERROR = "CHECK_EXCHANGE_IP_ERROR";
export const GET_TRUSTED_IPS_REQUEST = "GET_TRUSTED_IPS_REQUEST";
export const GET_TRUSTED_IPS_SUCCESS = "GET_TRUSTED_IPS_SUCCESS";
export const GET_TRUSTED_IPS_ERROR = "GET_TRUSTED_IPS_ERROR";

export const UPDATE_EXCHANGE_HISTORY_SUCCESS =
  "UPDATE_EXCHANGE_HISTORY_SUCCESS";

export const GET_EXCHANGE_BALANCE_CHART_REQUEST =
  "exchange/request-balance-chart";
export const GET_EXCHANGE_BALANCE_CHART_SUCCESS =
  "exchange/fetch-balance-chart";
export const GET_EXCHANGE_BALANCE_CHART_ERROR = "exchange/failed-balance-chart";

export const GET_COIN_FROM_EXCHANGE_REQUEST = "COIN_FROM_EXCHANGE_REQUEST";
export const GET_COIN_FROM_EXCHANGE_SUCCESS = "COIN_FROM_EXCHANGE_SUCCESS";
export const GET_COIN_FROM_EXCHANGE_ERROR = "COIN_FROM_EXCHANGE_ERROR";

export const GET_EXCHANGE_FROM_COIN_REQUEST = "GET_EXCHANGE_FROM_COIN_REQUEST";
export const GET_EXCHANGE_FROM_COIN_SUCCESS = "GET_EXCHANGE_FROM_COIN_SUCCESS";
export const GET_EXCHANGE_FROM_COIN_ERROR = "GET_EXCHANGE_FROM_COIN_ERROR";
