import React, { useState } from "react";
import { Dropdown, Button, message, Modal } from "antd";
import styles from "../../../styles/Components/new-insight/signals-section/StrategyDropdown.module.css";
import { IoIosArrowDown } from "react-icons/io";
import { HiPencil, HiTrash } from "react-icons/hi";
import {
  deleteStrategy,
  getStrategySignal,
  setCurrentStrategy,
} from "../../../redux/new-insight/actions";
import { useDispatch, useSelector } from "react-redux";
import CreateStrategyModal from "../create-strategy/CreateStrategyModal";
import EditStrategyModal from "./EditStrategyModal";
import { useSearchParams } from "react-router-dom";
import { MdOutlineFilterAlt } from "react-icons/md";
import { getRelativeTime } from "../../../utility/formatter-helper";

const StrategyDropdown = ({ data, title, loading, type }) => {
  const {
    main: { userData },
  } = useSelector((state) => state);
  const [showFeaturesModal, setShowFeaturesModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [open, setOpen] = useState(false);
  // TARGET STRATEGY TO EDITION
  const [selectedStrategy, setSelectedStrategy] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  // const [filteredData, setFilteredData] = useState(data);
  const [selectedTag, setSelectedTag] = useState("");

  const filteredData =
    type !== "system" && selectedTag
      ? data.filter((item) => item.strategy_tag === selectedTag)
      : data;

  const dispatch = useDispatch();

  const confirmToDeleteStrategy = (date) => {
    setOpen(false);
    Modal.confirm({
      title: "Warning",
      centered: true,
      content: "Are you sure you want to delete this strategy?",
      okText: "Yes, I am",
      okButtonProps: {
        style: {
          backgroundColor: "#0c3fce",
          borderColor: "#0c3fce",
        },
      },
      onOk: async () => {
        await dispatch(
          deleteStrategy(
            userData["custom:custom_username"],
            userData?.email,
            date,
          ),
        );
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
    });
  };

  const handleEditStrategy = (e, data) => {
    const {
      strategy_label,
      strategy_tag,
      strategy_name,
      list_feature_info,
      createdDate,
    } = data;
    e.stopPropagation();

    setSelectedStrategy({
      strategyTag: strategy_tag,
      strategyName: strategy_name,
      strategyLabel: strategy_label,
      strategyInfo: { strategy_label, strategy_tag, strategy_name },
      strategyList: [...JSON.parse(list_feature_info)],
      strategyId: createdDate,
    });

    setOpen(false);
    setShowEditModal(true);
    // TODO: UNCOMMENT IT
    // setSelectedStrategy(data);
  };

  const getSignals = (date, item) => {
    setSearchParams({});

    // Store strategy data in the URL
    searchParams.set("strategy", date);
    setSearchParams(searchParams);
    dispatch(setCurrentStrategy(item));

    dispatch(
      getStrategySignal(
        userData["custom:custom_username"],
        userData?.email,
        date,
        type === "system",
      ),
    );
  };

  const handleTagClick = (e, tag) => {
    e.stopPropagation();
    setSelectedTag(tag === "All" ? "" : tag);
  };

  const uniqueTags = [
    "All",
    ...new Set(data.map((item) => item.strategy_tag).filter(Boolean)),
  ];

  const submenuItems = uniqueTags.map((tag) => ({
    key: tag === "All" ? "" : tag,
    label: (
      <span
        style={{ display: "block" }}
        className={styles.strategyName}
        onClick={(e) => handleTagClick(e, tag)}
      >
        {tag}
      </span>
    ),
  }));

  const items = [
    ...(type !== "system"
      ? [
          {
            key: "filter",
            label: (
              <span style={{ display: "flex", alignItems: "center" }}>
                <MdOutlineFilterAlt size={16} /> Filter by Tag
              </span>
            ),
            children: submenuItems,
          },
        ]
      : []),
    ...filteredData.map((item, index) => {
      const {
        strategy_name: name,
        strategy_label: label = "",
        createdDate: date,
        strategy_tag: tag = "",
        list_feature_info: features,
      } = item;

      const featuresNum = JSON.parse(features)?.length;

      return {
        key: index,
        label: (
          <div
            className={styles.strategyItem}
            onClick={() => getSignals(date, item)}
          >
            <div className={styles.strategyInfo}>
              <div className={styles.infoBox}>
                <span className={styles.infoTitle}>Name:</span>
                <span className={styles.strategyName}>{name}</span>
              </div>
              <div className={styles.infoBox}>
                <span className={styles.infoTitle}>Number of Features:</span>
                <span className={styles.strategyName}>{featuresNum}</span>
              </div>
              <div className={styles.infoBox}>
                <span className={styles.infoTitle}>Created Date:</span>
                <span className={styles.strategyName}>
                  {getRelativeTime(date)}
                </span>
              </div>
              {label && (
                <div className={styles.infoBox}>
                  <span className={styles.infoTitle}>Label:</span>
                  <span className={styles.label}>{label}</span>
                </div>
              )}
              {tag && (
                <div className={styles.infoBox}>
                  <span className={styles.infoTitle}>Tag:</span>
                  <span className={styles.tag}>{tag}</span>
                </div>
              )}
            </div>
            {type === "user" && (
              <div className={styles.strategyHandlers}>
                <span onClick={(e) => handleEditStrategy(e, item)}>
                  <HiPencil size={14} />
                </span>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmToDeleteStrategy(item.createdDate);
                  }}
                >
                  <HiTrash size={14} />
                </span>
              </div>
            )}
          </div>
        ),
      };
    }),
  ];

  const handleCreateClick = () => {
    message.destroy();
    setShowFeaturesModal(true);
  };

  const handleButtonClick = () => {
    if (type === "user" && data.length === 0) {
      message.info(
        <p style={{ marginBottom: 0 }}>
          No strategy available!{" "}
          <span
            style={{ color: "#0C4CFC", cursor: "pointer" }}
            onClick={handleCreateClick}
          >
            Create
          </span>{" "}
          the first one now.
        </p>,
      );
    }
  };

  return (
    <div className={styles.container}>
      {data.length > 0 ? (
        <Dropdown
          menu={{
            items,
          }}
          open={open}
          onOpenChange={setOpen}
          className={styles.dropdown}
          trigger="click"
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <Button
            className={`${styles.btn} ${
              type === "system" ? styles.systemBtn : styles.userBtn
            }`}
          >
            {title} <IoIosArrowDown />
          </Button>
        </Dropdown>
      ) : (
        <Button
          onClick={handleButtonClick}
          loading={loading}
          className={`${styles.btn} ${
            type === "system" ? styles.systemBtn : styles.userBtn
          }`}
        >
          {title} <IoIosArrowDown />
        </Button>
      )}

      {showFeaturesModal && (
        <CreateStrategyModal
          show={showFeaturesModal}
          closeHandler={() => setShowFeaturesModal(false)}
          type={"create"}
        />
      )}

      {showEditModal && (
        <EditStrategyModal
          setShow={setShowEditModal}
          show={showEditModal}
          data={selectedStrategy}
        />
      )}
    </div>
  );
};

export default StrategyDropdown;
