/** @format */

import { useEffect } from "react";
import usePageTitle from "../hooks/usePageTitle";
import useGeoLocation from "../hooks/useGeolocation";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { isMobile, isTablet } from "react-device-detect";
import { Button, Result } from "antd";

function VerifyUserLocation() {
	usePageTitle("Verify location");
	const [searchParams] = useSearchParams();
	const email = searchParams.get("email");
	const user = searchParams.get("user");

	const {
		location: { lat, lon },
		getLocation,
		error,
		loading,
		permissionDenied,
	} = useGeoLocation(user, email);

	const navigate = useNavigate();
	const token = searchParams.get("token");

	if (token) axios.defaults.headers.common["Authorization"] = token;

	useEffect(() => {
		localStorage.setItem("process", "verifyLocation");
		getLocation();
	}, []);

	useEffect(() => {
		if (!isMobile && !isTablet) {
			console.log("navigate");
			navigate(-1);
		}
	}, [isMobile]);

	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
			}}>
			{permissionDenied ? (
				<Result
					status='warning'
					title='Location Access Required'
					subTitle='Please enable location access in your browser settings to complete verification.'
					extra={[
						<Button
							key='retry'
							type='primary'
							onClick={getLocation}
							loading={loading}>
							Try Again
						</Button>,
					]}
					style={{ maxWidth: 400 }}
				/>
			) : (
				<>
					<h3 style={{ textAlign: "center", maxWidth: "320px" }}>
						Verify Location
						<p>
							Please enable location access in your browser to complete
							verification. Thank you!
						</p>
					</h3>
					{error && !permissionDenied && (
						<Button
							type='primary'
							loading={loading}
							disabled={loading}
							onClick={getLocation}
							style={{ marginTop: "20px" }}>
							Retry
						</Button>
					)}
				</>
			)}
		</div>
	);
}

export default VerifyUserLocation;
