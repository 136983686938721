import { useDispatch, useSelector } from "react-redux";
import {
  setFinalTradeType,
  setStopLossTriggerPrice,
  setTakeProfitTriggerPrice,
  setTradeSize,
} from "../../../redux/trade-form/tradeFormSlice";
import { setTradeType } from "../../../redux/trade/actions";
import { message } from "antd";
import { roundToPrecision } from "../utils/helper";
import useAssetAmount from "./useAssetAmount";
import useCheckPortfolio from "./useCheckPortfolio";
import useCheckOrderLimits from "./useCheckOrderLimits";

function useAiSuggestion() {
  const {
    tradeForm: {
      aiSuggestion,
      aiSuggestionStatus,
      aiSuggestionError,
      tradeType,
      assetPrice,
    },
  } = useSelector((state) => state);

  const { nativeAmount, usdAmount } = useAssetAmount();
  const { budget } = useCheckPortfolio();
  const { amountPrecision } = useCheckOrderLimits();

  const dispatch = useDispatch();

  const isLoading = aiSuggestionStatus === "loading";
  const prediction = aiSuggestion?.prediction?.toLowerCase();
  const { sl_percent, tp_percent } = aiSuggestion?.position_params || {};
  const isError = aiSuggestionStatus === "error";
  const date = aiSuggestion?.signal_timestamp;
  const confidence = aiSuggestion?.confidence?.toFixed(2);
  const positionSize = +aiSuggestion?.position_params?.position_size / 100;

  const applyAllSuggestion = () => {
    if (!prediction) return message.error("Something went wrong");

    if (prediction !== "hold") {
      dispatch(setTradeType(prediction));
      dispatch(setFinalTradeType(prediction));
    }

    const native =
      tradeType === "buy"
        ? (positionSize * budget) / assetPrice
        : positionSize * nativeAmount;
    const usd =
      tradeType === "buy" ? positionSize * budget : positionSize * usdAmount;
    dispatch(
      setTradeSize(
        // roundToPrecision(native, amountPrecision),
        native,
        usd,
        positionSize * 100,
      ),
    );
    dispatch(setStopLossTriggerPrice(+sl_percent?.split("%")?.[0] / 100));
    dispatch(setTakeProfitTriggerPrice(+tp_percent?.split("%")?.[0] / 100));

    return message.success("All AI suggestions are applied.");
  };

  return {
    isLoading,
    prediction,
    sl_percent: +sl_percent?.split("%")?.[0] / 100,
    tp_percent: +tp_percent?.split("%")?.[0] / 100,
    isError,
    error: aiSuggestionError,
    date,
    confidence,
    applyAllSuggestion,
    positionSize,
  };
}

export default useAiSuggestion;
