import DashboardLayout from "../../components/layout/DashboardLayout";
import classes from "../../styles/pages/bot/BotAndAutomation.module.css";
import { useParams } from "react-router-dom";
import BotConfiguration from "../../components/bot/BotConfiguration";
import React, { useEffect, useState } from "react";
import BotBackTest from "../../components/bot/BotBackTest";
import BotReports from "../../components/bot/BotReports";
import ServiceStatusBar from "../../components/mini-app/shared/ServiceStatusBar";
import { useSelector } from "react-redux";
import DisplayRatingModal from "../../shared/DisplayRatingModal";
import usePageTitle from "../../hooks/usePageTitle";

function BotAndAutomation() {
  const {
    profile: { profileData },
    botState: { botList },
  } = useSelector((state) => state);
  const { section } = useParams();
  const [showDemo, setShowDemo] = useState(true);
  usePageTitle("Bot & Automation");

  // useEffect(() => {
  //   return () => {
  //     if (profileData?.pricing_plan) {
  //       if (
  //         !profileData?.bot_rate &&
  //         profileData?.suggested_features &&
  //         !profileData?.bot_cancel_rate_date
  //       ) {
  //         showRatingModal(true);
  //         setVisitedPage("bot");
  //       }
  //     }
  //   };
  // }, [
  //   profileData?.bot_cancel_rate_date,
  //   profileData?.bot_rate,
  //   profileData?.pricing_plan,
  //   profileData?.suggested_features,
  //   setVisitedPage,
  //   showRatingModal,
  // ]);

  return (
    <DashboardLayout hasDemo={showDemo}>
      <div className={classes.main}>
        <ServiceStatusBar
          status={profileData?.mybot_active}
          expirationDate={profileData?.mybot_active_until}
          service={"mybot"}
          platform
        />
        {!section && (
          <BotConfiguration
            hideDemo={() => setShowDemo(false)}
            showDemo={() => setShowDemo(true)}
          />
        )}
        {section === "backtest" && <BotBackTest />}
        {section === "bot-performance" && <BotReports />}
      </div>

      {/* <DisplayRatingModal
        page="bot"
        showRatingModal={showRatingModal}
        setVisitedPage={setVisitedPage}
        condition={botList.length > 0}
      /> */}
    </DashboardLayout>
  );
}

export default BotAndAutomation;
