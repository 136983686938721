/** @format */

import styles from "../../../../styles/Components/bot/bot-reports/BotPerformanceReport.module.css";
import { FaArrowLeft } from "react-icons/fa";
import { setSelectedBot } from "../../../../redux/bot/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Empty, Spin } from "antd";
import BotPerformanceSummaryCard from "./BotPerformanceSummaryCard";
import { AiFillTrophy, AiFillWarning } from "react-icons/ai";
import { GoIssueClosed } from "react-icons/go";
import { FaSackDollar } from "react-icons/fa6";
import TradeReportTable from "../../bot-backtest/reports/TradeReportTable";
import { useEffect, useState } from "react";
import { showRatingModal } from "../../../../redux/rating-modal/actions";

const { Panel } = Collapse;

function BotPerformanceReport() {
	const {
		botState: { getBotReportLoading, botReportData, selectedBot },
	} = useSelector((state) => state);

	const [activePanel, setActivePanel] = useState([]);

	const tradeReport = botReportData?.trade_report
		? Object.entries(JSON.parse(botReportData?.trade_report)).map(
				([currency, details]) => {
					return {
						currency,
						...details,
					};
				}
		  )
		: [];

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleGoBack = () => {
		navigate("/bot-automation/bot-performance");
		dispatch(setSelectedBot({}));
	};

	const changeActivePanel = (keys) => {
		setActivePanel(keys);
	};

	useEffect(() => {
		if (Object.keys(selectedBot).length === 0) {
			navigate("/bot-automation/bot-performance");
		}
	}, [selectedBot]);

	useEffect(() => {
		if (!getBotReportLoading && Object.keys(botReportData).length > 0) {
			const timer = setTimeout(() => {
				dispatch(showRatingModal("bot"));
			}, 10000); // 10 seconds delay

			return () => clearTimeout(timer); // Cleanup the timer if component unmounts
		}
	}, [getBotReportLoading, botReportData]);

	return (
		<div className={styles.container}>
			<span className={styles.logo}>CryptoCrispy</span>
			<div className={styles.backBtn} onClick={handleGoBack}>
				<FaArrowLeft />
			</div>
			<h3 className={styles.title}>Bot Performance Report</h3>
			{getBotReportLoading && (
				<Spin className={styles.loader} tip={"Please wait"} />
			)}

			{!getBotReportLoading && Object.keys(botReportData).length > 0 && (
				<>
					<div className={styles.header}>
						<BotPerformanceSummaryCard />

						{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
						<img
							src={`data:image/png;base64,${botReportData?.plot_image_base64}`}
							alt={"plot image"}
							className={styles.plotImg}
						/>
					</div>
					{tradeReport.length > 0 &&
						tradeReport.map((item, i) => (
							<Collapse
								key={item.currency}
								bordered={false}
								activeKey={activePanel}
								onChange={changeActivePanel}>
								<Panel
									header={
										<div className={styles.panelHeader}>
											<ul className={styles.panelInfo}>
												<li className={styles.coinName}>
													<img
														src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item.currency}.png`}
														alt={item.currency}
														className={styles.coinIcon}
													/>
													{item.currency}
												</li>{" "}
												-{" "}
												<li>
													<AiFillTrophy color={"#f1c40f"} />
													Win Rate:{" "}
													<span
														style={{
															color: `${
																item.win_rate >= 50 ? "#16a085" : "#e74c3c"
															}`,
														}}>
														{item.win_rate}%
													</span>
												</li>
												|{" "}
												<li>
													<GoIssueClosed color={"#0C4CFC"} />
													Closed Positions: {item.closed_positions}
												</li>{" "}
												|{" "}
												<li>
													<FaSackDollar color={"#16a085"} />
													Profitable Positions: {item.profitable_positions}
												</li>{" "}
												|{" "}
												<li>
													<AiFillWarning color={"#e74c3c"} />
													Non-Profitable Positions:{" "}
													{item.non_profitable_positions}
												</li>
											</ul>
											<div className={styles.moreBtn}>
												{activePanel.includes(item.currency) ? "Hide" : "See"}{" "}
												Order History
												{/*<MdKeyboardDoubleArrowRight />*/}
											</div>
										</div>
									}
									key={item.currency}
									className={styles.panel}
									// style={{ backgroundColor: "white" }}
								>
									<h3>Order History</h3>
									<TradeReportTable data={item.trade_history} />
								</Panel>
							</Collapse>
						))}
				</>
			)}
			{!getBotReportLoading && Object.keys(botReportData).length === 0 && (
				<div className={styles.emptyDataMessage}>
					<Empty />
					<h4>Sorry, there is no report for this bot. </h4>
					<p>
						This may be because the bot is new, inactive, or has not generated
						enough data. Please check back later or try another bot.
					</p>
				</div>
			)}
		</div>
	);
}

export default BotPerformanceReport;
