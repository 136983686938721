import useServiceStatus from "../../hooks/useServiceStatus";
import { useState } from "react";
import TradeModal from "./TradeModal";
import styles from "../../styles/shared/trade-form/TradeModal.module.css";

// the mode prop specify the structure of form
// the data prop specify the required data if the mod is not equal to "trade-btn"

function TradeButton({
  btnClassName = "",
  service,
  children,
  data,
  mode,
  defaultStyle = false,
  style = {},
  onClick,
}) {
  const [showTradeModal, setShowTradeModal] = useState(false);

  const { isActive } = useServiceStatus(service);

  const clickHandler = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
    //   if the place of button related to a certain service and the service is not active, disabled trade functionality and show the service activation modal
    if (service && !isActive) {
      return console.log("disabled");
    }

    setShowTradeModal(true);
  };

  return (
    <>
      <div
        onClick={clickHandler}
        className={`${defaultStyle ? styles.mainBtn : ""}${btnClassName}`}
        style={{ cursor: "pointer", ...style }}
      >
        {children}
      </div>

      {showTradeModal && (
        <TradeModal
          show={showTradeModal}
          setShow={setShowTradeModal}
          mode={mode}
          data={data}
        />
      )}
    </>
  );
}

export default TradeButton;
