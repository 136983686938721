import axios from "axios";
import {
  ACCLAIM_GIFT_ERROR,
  ACCLAIM_GIFT_REQUEST,
  ACCLAIM_GIFT_SUCCESS,
  ACTIVATE_SERVICE_WITH_CREDIT_CARD_ERROR,
  ACTIVATE_SERVICE_WITH_CREDIT_CARD_REQUEST,
  ACTIVATE_SERVICE_WITH_CREDIT_CARD_SUCCESS,
  ACTIVATE_SERVICE_WITH_CREDIT_ERROR,
  ACTIVATE_SERVICE_WITH_CREDIT_REQUEST,
  ACTIVATE_SERVICE_WITH_CREDIT_SUCCESS,
  ACTIVATE_SERVICE_WITH_CRYPTO_ERROR,
  ACTIVATE_SERVICE_WITH_CRYPTO_REQUEST,
  ACTIVATE_SERVICE_WITH_CRYPTO_SUCCESS,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHARGE_CREDIT_WITH_CRYPTO_ERROR,
  CHARGE_CREDIT_WITH_CRYPTO_REQUEST,
  CHARGE_CREDIT_WITH_CRYPTO_SUCCESS,
  DISCOUNT_ERROR,
  DISCOUNT_REQUEST,
  DISCOUNT_SUCCESS,
  GET_FREE_PLAN_ERROR,
  GET_FREE_PLAN_REQUEST,
  GET_FREE_PLAN_SUCCESS,
  GET_PRICING_PLANS_ERROR,
  GET_PRICING_PLANS_REQUEST,
  GET_PRICING_PLANS_SUCCESS,
  GET_REFERRAL_CODE_ERROR,
  GET_REFERRAL_CODE_REQUEST,
  GET_REFERRAL_CODE_SUCCESS,
  GET_REFERRAL_REWARD_ERROR,
  GET_REFERRAL_REWARD_REQUEST,
  GET_REFERRAL_REWARD_SUCCESS,
  GET_STRIPE_CHECKOUT_SESSION_ERROR,
  GET_STRIPE_CHECKOUT_SESSION_REQUEST,
  GET_STRIPE_CHECKOUT_SESSION_SUCCESS,
  GET_USER_SUBSCRIPTIONS_ERROR,
  GET_USER_SUBSCRIPTIONS_REQUEST,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  INCREASE_CREDIT_WITH_CARD_ERROR,
  INCREASE_CREDIT_WITH_CARD_REQUEST,
  INCREASE_CREDIT_WITH_CARD_SUCCESS,
  REDEEM_GIFT_ERROR,
  REDEEM_GIFT_REQUEST,
  REDEEM_GIFT_SUCCESS,
  REFUND_ERROR,
  REFUND_REQUEST,
  REFUND_SUCCESS,
  RENEW_ERROR,
  RENEW_REQUEST,
  RENEW_SUCCESS,
  SEND_CHAT_ERROR,
  SEND_CHAT_REQUEST,
  SEND_CHAT_SUCCESS,
  WITHDRAW_ERROR,
  WITHDRAW_REQUEST,
  WITHDRAW_SUCCESS,
} from "./consts";
import alert from "../../components/alerts/Alert";
import { getProfileData } from "../profile/actions";
import { axiosErrorHandler } from "../../utility/action-helper";

export const getCheckoutLink =
  (email, user, plan_mode, success_url, cancel_url, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_STRIPE_CHECKOUT_SESSION_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "get_stripe_checkout_session",
          plan_mode,
          success_url,
          cancel_url,
        },
      );
      const data = res.data;
      dispatch({
        type: GET_STRIPE_CHECKOUT_SESSION_SUCCESS,
        payload: data.checkout_session_url,
      });
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () =>
          getCheckoutLink(
            email,
            user,
            plan_mode,
            success_url,
            cancel_url,
            retryCount + 1,
          ),
        GET_STRIPE_CHECKOUT_SESSION_ERROR,
        user,
        email,
        "get_stripe_checkout_session",
      );
      // dispatch({ type: GET_STRIPE_CHECKOUT_SESSION_ERROR, payload: err.message });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const resetCheckoutLink = () => async (dispatch) => {
  dispatch({ type: "reset-checkout-link" });
};

export const getSubscriptions =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_USER_SUBSCRIPTIONS_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "get_user_subscriptions",
        },
      );
      const data = res.data;

      dispatch({
        type: GET_USER_SUBSCRIPTIONS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => getSubscriptions(email, user, retryCount + 1),
        GET_USER_SUBSCRIPTIONS_ERROR,
        user,
        email,
        "get_user_subscriptions",
      );
      // dispatch({
      //   type: GET_USER_SUBSCRIPTIONS_ERROR,
      //   payload: err.message,
      // });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const cancelSubscription =
  (email, user, plan_mode, endTime, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "cancell_subscription",
          plan_mode,
        },
      );
      const data = res.data;
      dispatch({
        type: CANCEL_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
      alert(
        null,
        `Your subscription will be cancelled at ${endTime}`,
        "success",
        {
          cancel: "Ok",
        },
      );
      dispatch(getSubscriptions(email, user));
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => cancelSubscription(email, user, plan_mode, retryCount + 1),
        CANCEL_SUBSCRIPTION_ERROR,
        user,
        email,
        "cancell_subscription",
      );
      // dispatch({
      //   type: CANCEL_SUBSCRIPTION_ERROR,
      //   payload: err.message,
      // });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const refund =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: REFUND_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "refund_request",
        },
      );
      const data = res.data;

      dispatch({
        type: REFUND_SUCCESS,
        payload: data.message,
      });
      dispatch(getProfileData(email, user));
      alert(
        // "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        "",
        "Thank you for choosing our service. If you have not used it at all in the past 15 days, we will check our records and issue a full refund without any questions.\n We value your trust and honesty and we hope you are happy with your purchase.",
        "success",
        {
          cancel: "Close",
        },
      );
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => refund(email, user, retryCount + 1),
        REFUND_ERROR,
        user,
        email,
        "refund_request",
      );
      // dispatch({
      //   type: REFUND_ERROR,
      //   payload: err.message,
      // });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const sendChat =
  (email, user, chat_text, plan_type, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: SEND_CHAT_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "send_cancellation_chat",
          chat_text,
          plan_type,
        },
      );
      const data = res.data;

      dispatch({
        type: SEND_CHAT_SUCCESS,
        payload: data.message,
      });
      dispatch(getProfileData(email, user));
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => sendChat(email, user, chat_text, retryCount + 1),
        SEND_CHAT_ERROR,
        user,
        email,
        "send_cancellation_chat",
      );
      // dispatch({
      //   type: SEND_CHAT_ERROR,
      //   payload: err.message,
      // });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const renew =
  (email, user, plan_mode, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: RENEW_REQUEST, payload: plan_mode });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "renew_subscription",
          plan_mode,
        },
      );
      const data = res.data;
      dispatch({
        type: RENEW_SUCCESS,
        payload: data.message,
      });
      alert(
        null,
        "Your subscription has been renewed successfully!",
        "success",
        {
          cancel: "Ok",
        },
      );
      // message.success("Your subscription has been renewed successfully!");
      dispatch(getSubscriptions(email, user));
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => renew(email, user, plan_mode, retryCount + 1),
        RENEW_ERROR,
        user,
        email,
        "renew_subscription",
      );
      // dispatch({
      //   type: RENEW_ERROR,
      //   payload: err.message,
      // });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const discount =
  (email, user, plan_mode, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: DISCOUNT_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "discount_for_continue",
          plan_mode,
        },
      );
      const data = res.data;
      dispatch({
        type: DISCOUNT_SUCCESS,
        payload: data.message,
      });
      dispatch(getProfileData(email, user));
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => discount(email, user, plan_mode, retryCount + 1),
        DISCOUNT_ERROR,
        user,
        email,
        "discount_for_continue",
      );
      // dispatch({
      //   type: DISCOUNT_ERROR,
      //   payload: err.message,
      // });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const redeemGift =
  (email, user, code, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: REDEEM_GIFT_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "redeem_gift_code",
          gift_code: code,
        },
      );
      const data = res.data;
      if (data.STATUS_CODE === 200) {
        dispatch({ type: REDEEM_GIFT_SUCCESS, payload: data.MESSAGE });
      } else {
        dispatch({ type: REDEEM_GIFT_ERROR, payload: data.MESSAGE });
      }
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => redeemGift(email, user, code, retryCount + 1),
        REDEEM_GIFT_ERROR,
        user,
        email,
        "redeem_gift_code",
      );
      // dispatch({ type: REDEEM_GIFT_ERROR, payload: err.message });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const acclaimGift =
  (email, user, date, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: ACCLAIM_GIFT_REQUEST });
    try {
      const res = await axios.post(
        "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
        {
          platform_mode: "pricing_plan",
          email,
          user,
          request_type: "acclaim_gift",
          createdDate: date,
        },
      );
      const data = res.data;
      if (data.Message === "The gift has been redeemed successfully!") {
        dispatch({ type: ACCLAIM_GIFT_SUCCESS, payload: data.Message });
      } else {
        dispatch({ type: ACCLAIM_GIFT_ERROR, payload: data.Message });
      }
    } catch (err) {
      axiosErrorHandler(
        err,
        retryCount,
        dispatch,
        () => acclaimGift(email, user, date, retryCount + 1),
        ACCLAIM_GIFT_ERROR,
        user,
        email,
        "acclaim_gift",
      );
      // dispatch({ type: ACCLAIM_GIFT_ERROR, payload: err.message });
      // const { STATUS_CODE, MESSAGE } = err?.response.data;
      // errorHandler(STATUS_CODE, MESSAGE);
    }
  };

export const getFreePlan =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_FREE_PLAN_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "pricing_plan",
        email,
        user,
        request_type: "get_free_tier_stat",
      })
      .then((res) => {
        dispatch({ type: GET_FREE_PLAN_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getFreePlan(email, user, retryCount + 1),
          GET_FREE_PLAN_ERROR,
          user,
          email,
          "get_free_tier_stat",
        );
        // dispatch({ type: GET_FREE_PLAN_ERROR, payload: err?.response });
      });
  };

export const getReferralCode =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_REFERRAL_CODE_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "pricing_plan",
        email,
        user,
        request_type: "get_referral_code",
      })
      .then((res) => {
        dispatch({
          type: GET_REFERRAL_CODE_SUCCESS,
          payload: res?.data?.referral_code,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getReferralCode(email, user, retryCount + 1),
          GET_REFERRAL_CODE_ERROR,
          user,
          email,
          "get_referral_code",
        );
        // dispatch({ type: GET_REFERRAL_CODE_ERROR, payload: err?.err.response });
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const getReferralRewards =
  (email, user, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_REFERRAL_REWARD_REQUEST });
    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "pricing_plan",
        email,
        user,
        request_type: "get_referral_reward_list",
      })
      .then((res) => {
        dispatch({
          type: GET_REFERRAL_REWARD_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => getReferralRewards(email, user, retryCount + 1),
          GET_REFERRAL_REWARD_ERROR,
          user,
          email,
          "get_referral_reward_list",
        );
      });
  };

export const chargeCreditWithCart =
  (
    user,
    email,
    amount,
    success_url = window.location.href,
    cancel_url = window.location.href,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: INCREASE_CREDIT_WITH_CARD_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "pricing_plan",
        user,
        email,
        request_type: "get_one_off_stripe_checkout_session",
        amount,
        activation_mode: "increase_credit",
        success_url,
        cancel_url,
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: INCREASE_CREDIT_WITH_CARD_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            chargeCreditWithCart(
              user,
              email,
              amount,
              success_url,
              cancel_url,
              retryCount + 1,
            ),
          INCREASE_CREDIT_WITH_CARD_ERROR,
          user,
          email,
          "get_one_off_stripe_checkout_session/increase_credit",
        );
      });
  };

export const chargeCreditWithCrypto =
  (
    user,
    email,
    amount,
    success_url = window.location.href,
    cancel_url = window.location.href,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: CHARGE_CREDIT_WITH_CRYPTO_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "pricing_plan",
        user,
        email,
        request_type: "get_one_off_plisio_checkout_session",
        amount,
        activation_mode: "increase_credit",
        success_url,
        cancel_url,
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: CHARGE_CREDIT_WITH_CRYPTO_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            chargeCreditWithCrypto(
              user,
              email,
              amount,
              success_url,
              cancel_url,
              retryCount + 1,
            ),
          CHARGE_CREDIT_WITH_CRYPTO_ERROR,
          user,
          email,
          "get_one_off_plisio_checkout_session/increase_credit",
        );
      });
  };

export const activateServiceWithCredit =
  (
    user,
    email,
    activation_mode,
    amount,
    success_url,
    cancel_url,
    showAlert = false,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: ACTIVATE_SERVICE_WITH_CREDIT_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        user,
        email,
        activation_mode,
        amount,
        success_url,
        cancel_url,
        platform_mode: "pricing_plan",
        request_type: "activate_service_using_credit",
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: ACTIVATE_SERVICE_WITH_CREDIT_SUCCESS,
          payload: res?.data,
        });

        if (showAlert) {
          alert(null, "Successfully activated.", "success", {
            cancel: "Ok",
          });
        }

        dispatch(getProfileData(email, user));
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            activateServiceWithCredit(
              user,
              email,
              activation_mode,
              amount,
              success_url,
              cancel_url,
              retryCount + 1,
            ),
          ACTIVATE_SERVICE_WITH_CREDIT_ERROR,
          user,
          email,
          `activate_service_using_credit/${activation_mode}`,
        );
      });
  };

export const activateServiceWithCrypto =
  (
    user,
    email,
    activation_mode,
    amount,
    success_url,
    cancel_url,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: ACTIVATE_SERVICE_WITH_CRYPTO_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        user,
        email,
        activation_mode,
        amount,
        success_url,
        cancel_url,
        platform_mode: "pricing_plan",
        request_type: "get_one_off_plisio_checkout_session",
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: ACTIVATE_SERVICE_WITH_CRYPTO_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            activateServiceWithCrypto(
              user,
              email,
              activation_mode,
              amount,
              success_url,
              cancel_url,
              retryCount + 1,
            ),
          ACTIVATE_SERVICE_WITH_CRYPTO_ERROR,
          user,
          email,
          `get_one_off_plisio_checkout_session/${activation_mode}`,
        );
      });
  };

export const activateServiceWithCard =
  (
    user,
    email,
    activation_mode,
    amount,
    success_url,
    cancel_url,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: ACTIVATE_SERVICE_WITH_CREDIT_CARD_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        user,
        email,
        activation_mode,
        amount,
        success_url,
        cancel_url,
        platform_mode: "pricing_plan",
        request_type: "get_one_off_stripe_checkout_session",
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: ACTIVATE_SERVICE_WITH_CREDIT_CARD_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            activateServiceWithCard(
              user,
              email,
              activation_mode,
              amount,
              success_url,
              cancel_url,
              retryCount + 1,
            ),
          ACTIVATE_SERVICE_WITH_CREDIT_CARD_ERROR,
          user,
          email,
          `get_one_off_stripe_checkout_session/${activation_mode}`,
        );
      });
  };

export const requestWithdraw =
  (email, user, amount, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: WITHDRAW_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "pricing_plan",
        user,
        email,
        amount,
        request_type: "request_withdraw_cashble_reward",
      })
      .then((res) => {
        console.log(res);
        dispatch({ type: WITHDRAW_SUCCESS, payload: res?.data?.message });
        alert(null, res?.data?.message, "success", {
          cancel: "Ok",
        }).catch((err) => {
          console.log(err);
          axiosErrorHandler(
            err,
            retryCount,
            dispatch,
            () => requestWithdraw(email, user, amount, retryCount + 1),
            WITHDRAW_ERROR,
            user,
            email,
            "request_withdraw_cashble_reward",
          );
        });
      });
  };

export const resetActivationWithCreditData = () => (dispatch) => {
  return dispatch({ type: "reset-activationWithCreditData" });
};

export const resetActivationWithCryptoData = () => (dispatch) => {
  return dispatch({ type: "reset-activationWithCryptoData" });
};

export const resetActivationWithCardData = () => (dispatch) => {
  return dispatch({ type: "reset-activationWithCardData" });
};

export const getPricingPlan = () => (dispatch) => {
  dispatch({ type: GET_PRICING_PLANS_REQUEST });

  fetch(
    "https://s3.ap-southeast-2.amazonaws.com/www.cryptocrispy.com.au/CryptoCrispyPricingPlan.json",
  )
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      dispatch({
        type: GET_PRICING_PLANS_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_PRICING_PLANS_ERROR });
    });
};
