/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorHandler, refreshLimit } from "../../../utility/helper";
import { MdOutlineUpdate } from "react-icons/md";
import axios from "axios";
import styles from "../../../styles/Components/trading-assistant/tradeAssistantCards.module.sass";
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaCoins,
  FaPauseCircle,
} from "react-icons/fa";
import { getRelativeTime } from "../../../utility/formatter-helper";
import { getLiveSuggestion } from "../../../redux/live-suggestion/liveSuggestionSlice";
import refreshIcon from "../../../asset/Icons/notification(1).gif";
import AiAssistantTableActionButtons from "./AiAssistantTableActionButtons";
import OtherCoins from "../../../shared/other-coins-signal/OtherCoins";
import TradeAssistantSkeletonCard from "./TradeAssistantSkeletonCard";
import TradeAssistantCard from "./TradeAssistantCard";

const TradeAssistantCards = ({ data = [] }) => {
  const [priceRefreshTime, setPriceRefreshTime] = useState(60);
  const [cardsData, setCardsData] = useState([]);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [passedMinutes, setPassedMinutes] = useState(0);

  const refreshInterval = useRef(null);
  const countdownInterval = useRef(null);
  const lastProcessedTimestamp = useRef(null);

  const dispatch = useDispatch();
  const {
    tradingAssistant: {
      getTradeAssistantLoading,
      tradeAssistant,
      aiMarketType,
      allTradeSuggestionData,
    },
    main: { userData },
    profile: { profileData, loading },
    liveSuggestion: { suggestions, status, coinStatus },
    otherCoinsAnalyze: { analyze },
  } = useSelector((state) => state);

  const { minutes_passed, minutes_remaining } = tradeAssistant;
  const stopLossCFC = tradeAssistant?.coefficients?.stop_loss;
  const takeProfitCFC = tradeAssistant?.coefficients?.take_profit;

  const symbols = cardsData
    ?.filter((item) => item?.SYMBOL_NAME)
    ?.map((item) => item?.SYMBOL_NAME);

  const liveSuggestionDate = getRelativeTime(
    suggestions?.[0]?.signal_timestamp,
  );

  const handleUpdatePrice = async () => {
    clearInterval(refreshInterval.current);
    clearInterval(countdownInterval.current);
    setPriceRefreshTime(0);

    await axios
      .post(
        "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          user: userData?.["custom:custom_username"],
          email: userData?.["cognito:username"],
          request_type: "get_price_bulk",
          market_type: "crypto",
          symbol_names: symbols,
        },
      )
      .then((res) => {
        const priceData = res?.data?.PRICE_LIST;
        const priceList = [];
        priceData.map((item) => priceList.push(item?.PRICE));

        setCardsData((prevData) => {
          return prevData.map((item, i) => {
            const tradeType = item?.TRADE_TYPE;
            const priceColor =
              item?.CURRENT_PRICE < priceList[i] ? "#70AF85" : "#EF4B4B";

            return {
              ...item,
              CURRENT_PRICE: priceList[i],
              STOP_LOSS: refreshLimit(
                item.STOP_LOSS_PERCENT * stopLossCFC,
                priceList[i],
                tradeType,
                "stopLoss",
              ),
              TAKE_PROFIT: refreshLimit(
                item.TAKE_PROFIT_PERCENT * takeProfitCFC,
                priceList[i],
                tradeType,
                "takeProfit",
              ),
              priceColor,
            };
          });
        });
        setPriceRefreshTime(60);
      })
      .catch((err) => {
        const { STATUS_CODE, MESSAGE } = err?.response?.data;
        if (MESSAGE) {
          errorHandler(STATUS_CODE, MESSAGE);
        }
        console.log(err);
      });
  };

  useEffect(() => {
    if (data && data.length) {
      const allSignals = [...data, ...allTradeSuggestionData];
      const signalSymbols = suggestions?.map((item) => item?.symbol);

      const matchedSymbols = allSignals
        ?.filter((item) => signalSymbols?.includes(item?.SYMBOL_NAME))
        ?.sort((a, b) => (b?.TRADE_TYPE.includes("Hold") ? 1 : -1))
        ?.sort((a, b) => {
          const aPrediction = suggestions?.find(
            (s) => s?.symbol === `${a?.SYMBOL_NAME}`,
          )?.prediction;
          const bPrediction = suggestions?.find(
            (s) => s?.symbol === `${b?.SYMBOL_NAME}`,
          )?.prediction;
          return bPrediction === "Hold" ? -1 : 1;
        });

      const nonMatchedNonHolds = allSignals?.filter(
        (item) =>
          !signalSymbols?.includes(item?.SYMBOL_NAME) &&
          !item?.TRADE_TYPE?.includes("Hold"),
      );

      const highestHoldSignals = allSignals
        .filter(
          (item) =>
            !signalSymbols?.includes(item?.SYMBOL_NAME) &&
            item.TRADE_TYPE?.includes("Hold"),
        )
        ?.sort((a, b) => b?.SIGNAL_STRENGTH - a?.SIGNAL_STRENGTH);

      const finalSortedData =
        aiMarketType !== "crypto"
          ? allSignals?.sort((a, b) =>
              b?.TRADE_TYPE.includes("Hold") ? -1 : 1,
            )
          : [
              ...analyze,
              ...matchedSymbols,
              ...nonMatchedNonHolds,
              ...highestHoldSignals,
            ];

      setCardsData(finalSortedData);
    }
  }, [data, allTradeSuggestionData, analyze, suggestions, aiMarketType]);

  // Price refresh effect
  useEffect(() => {
    if (cardsData && cardsData.length && aiMarketType === "crypto") {
      refreshInterval.current = setInterval(handleUpdatePrice, 60 * 1000);
      return () => {
        if (refreshInterval.current) {
          clearInterval(refreshInterval.current);
        }
      };
    }
  }, [cardsData, userData, aiMarketType]);

  // Countdown effect
  useEffect(() => {
    if (cardsData && cardsData.length && aiMarketType === "crypto") {
      countdownInterval.current = setInterval(() => {
        if (priceRefreshTime > 0)
          setPriceRefreshTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
    };
  }, [priceRefreshTime, cardsData, aiMarketType]);

  // Loading state effect
  useEffect(() => {
    if (getTradeAssistantLoading) {
      clearInterval(refreshInterval.current);
      clearInterval(countdownInterval.current);
    }

    if (!getTradeAssistantLoading && tradeAssistant?.ASSISTANT_SUGGESTIONS) {
      setPriceRefreshTime(60);
    }
  }, [getTradeAssistantLoading, tradeAssistant]);

  // Minutes update effect
  useEffect(() => {
    if (tradeAssistant.minutes_remaining) {
      setRemainingMinutes(+minutes_remaining);
      setPassedMinutes(+minutes_passed);
    }
  }, [tradeAssistant, minutes_remaining, minutes_passed]);

  // Interval for minutes countdown
  useEffect(() => {
    if (remainingMinutes > 0) {
      const interval = setInterval(() => {
        setRemainingMinutes((prev) => prev - 1);
        setPassedMinutes((prev) => prev + 1);
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [remainingMinutes]);

  // Live suggestion refresh effect
  useEffect(() => {
    if (!liveSuggestionDate || !suggestions?.length) return;

    if (
      liveSuggestionDate === "15 minutes ago" &&
      lastProcessedTimestamp.current !== liveSuggestionDate
    ) {
      suggestions.forEach((s) => {
        dispatch(
          getLiveSuggestion(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            s?.exchange_id || "kucoin",
            s.symbol,
          ),
        );
      });

      lastProcessedTimestamp.current = getRelativeTime(
        suggestions[0]?.signal_timestamp,
      );
    }
  }, [dispatch, liveSuggestionDate, suggestions, userData]);

  const renderSignalIcon = (prediction) => {
    if (prediction === "Sell")
      return <FaArrowCircleDown size={18} color="#EF4B4B" />;
    if (prediction === "Buy")
      return <FaArrowCircleUp size={18} color="#70AF85" />;
    return <FaPauseCircle size={18} color="#82A0D8" />;
  };

  return (
    <div className={styles.container}>
      <AiAssistantTableActionButtons />

      <div className={styles.cardsGrid}>
        {getTradeAssistantLoading ? (
          <>
            {[...Array(6)].map((_, index) => (
              <TradeAssistantSkeletonCard key={index} />
            ))}
          </>
        ) : (
          <>
            <div className={`${styles.card} ${styles.otherCoins}`}>
              <OtherCoins className={styles.otherCoinsBtn} active>
                <h3>
                  <FaCoins />
                  Choose any Coin
                </h3>
                <p>Add new coin from any exchange to see our suggestion!</p>
              </OtherCoins>
            </div>
            {cardsData.map((item, index) => (
              <TradeAssistantCard
                data={item}
                key={index}
                remainingMinutes={remainingMinutes}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default TradeAssistantCards;
