import { useSelector } from "react-redux";

function UseCheckPortfolio() {
  const {
    tradeForm: { tradeType, targetPortfolioStatus, targetPortfolio },
  } = useSelector((state) => state);

  const balanceAmount = targetPortfolio?.find(
    (i) => i?.assetName === "USDT",
  )?.availableBalance;

  const isEnoughBalance = balanceAmount && parseFloat(balanceAmount) > 0;

  const canBuy =
    tradeType !== "buy" ||
    (balanceAmount && parseFloat(balanceAmount) > 0) ||
    targetPortfolioStatus !== "success";

  const isPortfolioEmpty =
    targetPortfolioStatus !== "success" ||
    targetPortfolio?.filter((asset) => asset?.assetName !== "USDT")?.length ===
      0;

  return { isEnoughBalance, canBuy, isPortfolioEmpty, budget: balanceAmount };
}

export default UseCheckPortfolio;
