import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCaretRight } from "react-icons/ai";
import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { message, Select, Tooltip } from "antd";
import { setTargetCoin } from "../../../redux/trade-form/tradeFormSlice";
import useCheckBalance from "../hooks/useCheckPortfolio";
import useAISuggestion from "../hooks/useAISuggestion";
import SectionLabel from "./SectionLabel";

function SelectAsset() {
  const {
    tradeForm: {
      tradeType,
      targetExchange,
      targetCoin,
      targetPortfolio,
      targetPortfolioStatus,
      suggestedCoin,
    },
    exchangeState: { availableCoinsStatus, availableCoinsData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { canBuy, isPortfolioEmpty } = useCheckBalance();
  const { isLoading } = useAISuggestion();

  // Memoize portfolio assets
  const portfolioAssets = useMemo(() => {
    return targetPortfolio?.map((asset) => asset?.assetName) || [];
  }, [targetPortfolio]);

  // Memoize assets calculation
  const assets = useMemo(() => {
    if (tradeType === "buy" || !tradeType) {
      return availableCoinsData?.[targetExchange] || [];
    } else if (tradeType === "sell") {
      return (
        availableCoinsData?.[targetExchange]?.filter(
          (coin) => portfolioAssets?.includes(coin) && coin !== "USDT",
        ) || []
      );
    }
    return [];
  }, [tradeType, availableCoinsData, targetExchange, portfolioAssets]);

  const selectAsset = (value) => {
    dispatch(setTargetCoin(value));
  };

  const useSuggestedAsset = () => {
    if (!assets?.includes(suggestedCoin))
      return message.info("Exchange doesn’t support this asset.");

    dispatch(setTargetCoin(suggestedCoin));
  };

  return (
    <div className={styles.box}>
      <SectionLabel label="Asset" style={{ marginBottom: ".5rem" }}>
        {suggestedCoin && (
          <Tooltip>
            <span
              className={styles.applySuggestion}
              style={{ marginLeft: "0.5rem" }}
              onClick={useSuggestedAsset}
            >
              Select {suggestedCoin}
            </span>
          </Tooltip>
        )}
      </SectionLabel>

      <Select
        showSearch
        className={styles.selectAsset}
        placeholder="Select an asset"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
        }
        value={assets.includes(targetCoin) ? targetCoin : undefined}
        onChange={selectAsset}
        disabled={
          !assets.length ||
          targetPortfolioStatus === "loading" ||
          (tradeType === "buy" && !canBuy) ||
          (tradeType === "sell" && isPortfolioEmpty) ||
          isLoading
        }
      >
        {availableCoinsStatus === "loading" ? (
          <Select.Option value="">Loading...</Select.Option>
        ) : (
          assets?.map((asset) => (
            <Select.Option key={asset} value={asset}>
              {asset}/USDT
            </Select.Option>
          ))
        )}
      </Select>

      {(targetPortfolioStatus !== "success" ||
        availableCoinsStatus === "loading") && (
        <div className={styles.overlayMask}></div>
      )}

      {!assets.length && <div className={styles.overlayMask}></div>}
    </div>
  );
}

export default SelectAsset;
