import { Link, useLocation } from "react-router-dom";

// STYLES
import styles from "../../styles/Components/layout/Navbar.module.css";

// ICONS

import { HiOutlineHome } from "react-icons/hi";
import { FiMoreHorizontal } from "react-icons/fi";
import { AiOutlineApi, AiOutlineBell, AiOutlineRobot } from "react-icons/ai";
import { SiProbot } from "react-icons/si";
import { BiAnalyse } from "react-icons/bi";
import { FaRegChartBar } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { DiCode } from "react-icons/di";
import { TbHelpSquare } from "react-icons/tb";

const itemsData = [
  {
    name: "AI Assistant",
    // icon: aiIcon,
    icon: <AiOutlineRobot />,
    path: "/ai-assistant/assistant",
  },
  {
    name: "Alert System",
    // icon: alertIcon,
    icon: <AiOutlineBell />,
    path: "/alert-system",
  },
  {
    name: "Bot & Automation",
    // icon: botIcon,
    icon: <SiProbot />,
    path: "/bot-automation",
  },
  {
    name: "Insights",
    // icon: insightIcon,
    icon: <BiAnalyse />,
    path: "/insight",
  },
  {
    name: "Integration",
    // icon: integrationIcon,
    icon: <AiOutlineApi />,
    path: "/integration",
  },
  {
    name: "Trade Simulator",
    // icon: tradeIcon,
    icon: <FaRegChartBar />,
    path: "/trade-simulator",
  },
  {
    name: "Pricing & Billing",
    // icon: pricingIcon,
    icon: <MdAttachMoney />,
    path: "/pricing-billing",
  },
  {
    name: "Developer API",
    // icon: apiIcon,
    icon: <DiCode />,
    path: "/developer-api",
  },
  {
    name: "Help",
    // icon: helpIcon,
    icon: <TbHelpSquare />,
    path: "/faq/all",
  },
  {
    name: "Dashboard",
    // icon: dashboardIcon,
    icon: <HiOutlineHome />,
    path: "/dashboard",
  },
];

function Navbar() {
  // Handling active item
  const location = useLocation(); // get the current location
  const pathName = location?.pathname?.split("/")[1]; // get the first part of the pathname

  return (
    <div className={styles.container}>
      <ul className={styles.navList}>
        {itemsData.slice(0, 4).map((i) => (
          <li className={styles.navItem} key={i.name}>
            <Link
              className={`${styles.navLink} ${
                pathName === i.path.split("/")[1] ? styles.activeLink : ""
              }`}
              // style={{ color: "#2B303A" }}
              to={i.path}
            >
              {i.icon}
              {/*<img className={styles.navIcon} src={i.icon} alt={i.name} />*/}
              {i.name === "AI Assistant" ? "AI Signals" : i.name.split(" ")[0]}
            </Link>
          </li>
        ))}
        <li className={`${styles.navItem} ${styles.navMore}`}>
          {/*<HiMenu style={{ fontSize: "17px", display: "flex" }} />*/}
          <FiMoreHorizontal size={20} />
          More
          <ul className={styles.dropdown}>
            {itemsData.slice(4).map((i) => (
              <li
                className={`${styles.dropdownItem} ${
                  i.name === "Trade Simulator" ? styles.beta : ""
                }`}
                key={i.name}
              >
                <Link
                  className={`${styles.navLink}  ${
                    pathName === i.path.split("/")[1] ? styles.activeLink : ""
                  }`}
                  style={{ color: "#2B303A", flexDirection: "row" }}
                  to={i.path}
                >
                  {/*<img className={styles.navIcon} src={i.icon} alt={i.name} />*/}
                  {i.icon}
                  {i.name}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
