import styles from "../styles/ui/PageHeader.module.css";
import useServiceStatus from "../hooks/useServiceStatus";
import { getRemainingTime } from "../utility/formatter-helper";
import React, { useState } from "react";
import ServiceActivationModal from "../shared/service-activation/ServiceActivationModal";

const servicesKey = {
  aiAssistant: "myais",
  insight: "insai",
  bot: "mybot",
  alert: "alert",
};

// service param can be: insight, tradeSimulator, bot, aiAssistant, alert,
function PageHeader({ title, service }) {
  const [showActivationModal, setShowActivationModal] = useState(false);

  const { isActive, expirationDate } = useServiceStatus(service);

  const serviceStatus = isActive ? "Active" : "Inactive";

  const handleClick = () => {
    if (isActive) return;
    setShowActivationModal(true);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.serviceInfo}>
        <span
          className={styles.serviceStatus}
          style={{ cursor: `${isActive ? "default" : "pointer"}` }}
          onClick={handleClick}
        >
          {serviceStatus}
        </span>
        {isActive && (
          <span className={styles.serviceExpiration}>
            Active Until: {getRemainingTime(expirationDate, true)}
          </span>
        )}
      </div>

      {showActivationModal && (
        <ServiceActivationModal
          service={servicesKey[service]}
          show={showActivationModal}
          setShow={setShowActivationModal}
        />
      )}
    </div>
  );
}

export default PageHeader;
