/** @format */

import { useDispatch, useSelector } from "react-redux";
import BotOverallActivityTable from "./bot-reports/BotOverallActivityTable";
import styles from "../../styles/Components/bot/BotReport.module.css";
import { Button, Dropdown, Menu, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import {
	checkBotStatus,
	deactivateBot,
	getBotActivity,
	getBotPerformanceReport,
	getBotPerformanceReportPdf,
} from "../../redux/bot/actions";
import BotSectionTitle from "./BotSectionTitle";
import { AiOutlineStop } from "react-icons/ai";
import { IoPlayOutline } from "react-icons/io5";
import alert from "../alerts/Alert";
import { formatCustomDate } from "../../utility/helper";
import { FaRegFilePdf } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import React, { useEffect, useState, useRef } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import DisplayBotStatus from "../../shared/bot-activation-process/DisplayBotStatus";
import { LuRefreshCcw } from "react-icons/lu";
import ActivateBotServiceModal from "./ActivateBotServiceModal";
import { RiLockFill } from "react-icons/ri";

function BotReports() {
	const {
		main: { userData },
		botState: {
			selectedBot,
			activateBotLoading,
			deactivateBotLoading,
			performancePdfLoading,
			performancePdfLink,
			getBotActivityLoading,
			getBotActivityError,
		},
		profile: { profileData },
	} = useSelector((state) => state);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const mounted = useRef(true);

	const [showBotActivationModal, setShowBotActivationModal] = useState(false);
	const [showServiceActivationModal, setShowServiceActivationModal] =
		useState(false);

	const isBot = selectedBot?.trader && selectedBot?.trader !== "Manual";
	const isBotActive = isBot && selectedBot?.status === "Active";

	const isServiceActive = profileData?.mybot_active === "True";

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			mounted.current = false;
		};
	}, []);

	const handleRefresh = () => {
		if (getBotActivityLoading) return;

		dispatch(
			getBotActivity(
				userData?.["custom:custom_username"],
				userData?.["cognito:username"]
			)
		);
	};

	const handleShowReport = () => {
		if (!Object.keys(selectedBot).length)
			return message.info("Select a bot first!");

		setDropdownVisible(false); // Close dropdown before navigation

		const id =
			selectedBot?.trader_id === "Manual"
				? selectedBot?.trader_id
				: selectedBot?.trader_id;

		dispatch(
			getBotPerformanceReport(
				userData?.["custom:custom_username"],
				userData?.["cognito:username"],
				id
			)
		);

		navigate(`/bot-automation/bot-performance/report/${id}`);
	};

	const botActivation = () => {
		if (!isServiceActive) {
			setShowServiceActivationModal(true);
			return;
		}

		if (activateBotLoading || deactivateBotLoading) return;

		if (isBotActive) {
			dispatch(
				deactivateBot(
					userData?.["custom:custom_username"],
					userData?.["cognito:username"],
					selectedBot?.trader_id
				)
			);
		} else {
			if (selectedBot?.exchange_id === "None") {
				return alert(
					"",
					"You cannot activate this bot because it is not integrated with any exchange.",
					"info",
					{
						cancel: "Ok",
					}
				);
			}
			dispatch(
				checkBotStatus(
					userData?.["custom:custom_username"],
					userData?.["cognito:username"],
					selectedBot?.trader_id,
					selectedBot?.exchange_id
				)
			);
			setShowBotActivationModal(true);
		}
	};

	const menu = (
		<Menu className={styles.menu}>
			{selectedBot?.start_date && (
				<Menu.Item key='pdf'>
					{performancePdfLoading ? (
						<span className={styles.loading}>
							<Spin size={"small"} />
							Preparing pdf
						</span>
					) : (
						<a
							href={performancePdfLink}
							download={`${selectedBot?.trader}_${formatCustomDate(
								selectedBot?.start_date
							)}-${formatCustomDate(selectedBot?.end_date)}.pdf`}
							onClick={() => setDropdownVisible(false)}>
							<FaRegFilePdf />
							Download Pdf
						</a>
					)}
				</Menu.Item>
			)}
			<Menu.Item key='report'>
				<span onClick={handleShowReport}>
					<TbReport />
					Show report
				</span>
			</Menu.Item>
		</Menu>
	);

	useEffect(() => {
		if (selectedBot?.start_date && mounted.current) {
			dispatch(
				getBotPerformanceReportPdf(
					userData["custom:custom_username"],
					userData["cognito:username"],
					selectedBot?.trader_id
				)
			);
		}
	}, [selectedBot]);

	return (
		<div className={styles.container}>
			<BotSectionTitle title={"Bot Performance"}>
				Don't Forget to check emails.
			</BotSectionTitle>
			<ul className={styles.btns}>
				<li>
					<Button
						className={`${styles.btn} ${styles.refreshBtn}`}
						onClick={handleRefresh}
						loading={getBotActivityLoading}>
						{!getBotActivityLoading && <LuRefreshCcw />}
						Refresh
					</Button>
				</li>
				{isBot && (
					<li>
						<Button
							onClick={botActivation}
							loading={activateBotLoading || deactivateBotLoading}
							className={`${
								isBotActive ? styles.deactivateBtn : styles.activateBtn
							}`}>
							{!isServiceActive && <RiLockFill />}
							{isBotActive ? (
								<>
									{!deactivateBotLoading && isServiceActive && (
										<AiOutlineStop />
									)}
									Deactivate
								</>
							) : (
								<>
									{!activateBotLoading && isServiceActive && <IoPlayOutline />}
									Activate Bot
								</>
							)}
						</Button>
					</li>
				)}
				<li>
					<Dropdown
						overlay={menu}
						trigger={["click"]}
						open={dropdownVisible}
						onOpenChange={(visible) => setDropdownVisible(visible)}>
						<Button className={styles.btn}>
							<MdOutlineMoreVert />
							Show reports
						</Button>
					</Dropdown>
				</li>
			</ul>
			<BotOverallActivityTable />

			<DisplayBotStatus
				show={showBotActivationModal}
				onClose={setShowBotActivationModal}
				botId={selectedBot?.trader_id}
				exchangeId={selectedBot?.exchange_id}
			/>

			{showServiceActivationModal && (
				<ActivateBotServiceModal
					setShowActivationModal={setShowServiceActivationModal}
					showActivationModal={showServiceActivationModal}
				/>
			)}
		</div>
	);
}

export default BotReports;
