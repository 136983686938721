import { useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import styles from "../../styles/pages/profile/profile.module.sass";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button, Form, Input, Spin, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { CognitoUser } from "amazon-cognito-identity-js";
import PoolData from "../Auth/AWSConfig/PoolData";
import axios from "axios";
import { expiredTokenHandler, formatDate } from "../../utility/helper";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData, updateProfile } from "../../redux/profile/actions";
import Avatar from "react-avatar";
import { notify } from "../../utility/Toastify/toastify";
import RiskAppetite from "../../shared/RiskAppetite";
import { AiTwotoneEdit } from "react-icons/ai";
import { IoSettingsSharp } from "react-icons/io5";
import TopExchanges from "../../shared/start-wizard/TopExchanges";
import ExchangesModal from "../../shared/ExchangesModal";
import { setLogout } from "../../redux/main/action/main";
import NumberAndPostalCodeForm from "../../components/profile/NumberAndPostalCodeForm";
import usePageTitle from "../../hooks/usePageTitle";

const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};

const Index = () => {
  let {
    main: { userData },
    profile: { profileData, loading, error, updateLoading },
  } = useSelector((state) => state);

  let params = useParams();
  const [btnLoading, setBtnLoading] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [fileList, setFileList] = useState([]);
  const [infoModalShow, setInfoModalShow] = useState(false);
  const [passwordStep, setPasswordStep] = useState(1);
  const [newEmail, setNewEmail] = useState("");
  const [riskAmount, setRiskAmount] = useState(
    profileData?.ai_assistant_risk_appetite || "",
  );
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  usePageTitle("Settings");

  const [imgCropOpen, setImgCropOpen] = useState(false); // Control ImgCrop visibility

  const onChange = ({ fileList: newFileList }) => {
    const lastFile = newFileList?.[newFileList.length - 1];

    setFileList([lastFile]);

    if (lastFile?.originFileObj) {
      setUploading(true);
      getBase64(lastFile.originFileObj, (editedBase64) => {
        resizeImage(editedBase64, 300, 300, (compressedBase64) => {
          const hexString = base64ToHex(compressedBase64);
          axios
            .post(
              "https://jvp2fozucc.execute-api.us-east-1.amazonaws.com/dev/api",
              {
                platform_mode: "profile",
                email: userData?.email,
                user: userData["custom:custom_username"],
                request_type: "set_profile_image",
                file: hexString,
              },
            )
            .then((res) => {
              setProfileImg(""); // Reset profileImg state
              dispatch(
                getProfileData(
                  userData?.email,
                  userData["custom:custom_username"],
                ),
              );
            })
            .catch((err) => {
              console.error("Upload Error: ", err);
              if (err?.response?.status === 450) {
                expiredTokenHandler();
              }
            })
            .finally(() => setUploading(false));
        });
      });
    }
  };

  // Convert File to Base64
  function getBase64(file, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result); // Now we pass the entire Base64 image for editing
    };
    reader.onerror = function (error) {
      console.error("FileReader Error: ", error);
    };
  }

  // Resize & Compress Image (AFTER Editing)
  function resizeImage(base64, maxWidth, maxHeight, callback) {
    const img = new Image();
    img.src = base64;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;
        if (width > height) {
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Convert to compressed Base64
      const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7); // 70% quality
      callback(compressedBase64);
    };

    img.onerror = function (error) {
      console.error("Image Processing Error:", error);
    };
  }

  // Convert Base64 to Hex
  function base64ToHex(str) {
    const raw = atob(str.split(",")[1]); // Remove metadata
    let result = "";
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += hex.length === 2 ? hex : "0" + hex;
    }
    return result;
  }

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);
    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const [showMoreExchanges, setShowMoreExchanges] = useState(false);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleDeleteAccount = () => {
    dispatch(setLogout());
    const user = PoolData.getCurrentUser();
    user?.signOut();

    window.location.href =
      "https://cryptocrispy.com.au/delete_account_request.html";
  };

  const onsubmitChangePassword = (data) => {
    setBtnLoading(true);
    const getUser = () => {
      return new CognitoUser({
        Username: userData?.email,
        Pool: PoolData,
      });
    };

    getUser().confirmPassword(data?.confirmationCode, data?.newPassword, {
      onSuccess: (data) => {
        notify("Your password has been successfully changed", "success");
        setBtnLoading(false);
        setPasswordStep(1);
      },
      onFailure: (err) => {
        notify(err?.message, "error");
        setBtnLoading(false);
      },
    });
  };
  const sendVerificationCode = (data) => {
    setBtnLoading(true);
    const getUser = () => {
      return new CognitoUser({
        Username: userData?.email,
        Pool: PoolData,
      });
    };
    getUser().forgotPassword({
      onSuccess: (data) => {
        notify("verification code sent", "success");
        setBtnLoading(false);
      },
      onFailure: (err) => {
        notify(err?.message, "error");
        setBtnLoading(false);
      },
      inputVerificationCode: (data) => {
        notify(data, "success");
        setPasswordStep(2);
        setBtnLoading(false);
      },
    });
  };
  const handleUpdateRiskAmount = (e) => {
    e.preventDefault();
    let attribute_names = ["ai_assistant_risk_appetite"];

    dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        attribute_names,
        [riskAmount],
      ),
    );
  };

  const rateAmountHandler = {
    1: "Very Cautious",
    2: "Cautious",
    3: "Balanced",
    4: "Adventurous",
    5: "Very Adventurous",
  };

  const changeEmailSubmitHandler = async () => {
    await dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        ["receive_alerts_emails"],
        [newEmail],
      ),
    );
  };

  const handleRiskChange = (e) => setRiskAmount(e.target.value);

  return (
    <DashboardLayout loading={loading} error={error}>
      <div className={styles.container}>
        <div className={styles.sideBar}>
          <div>
            <IoSettingsSharp />
            <h3>settings</h3>
          </div>
          <ul>
            <li
              className={params?.section === "info" ? styles.activeSection : ""}
              id={"info"}
            >
              <Link to={"/profile/info"}> Account Summary </Link>
            </li>
            <li
              className={
                params?.section === "password" ? styles.activeSection : ""
              }
              id={"password"}
            >
              <Link to={"/profile/password"}> Reset Password</Link>
            </li>
            <li
              className={
                params?.section === "alert-email" ? styles.activeSection : ""
              }
              id={"alertEmail"}
            >
              <Link to={"/profile/alert-email"}> Alert Email</Link>
            </li>
            <li
              className={
                params?.section === "ai-assistant" ? styles.activeSection : ""
              }
            >
              <Link to={"/profile/ai-assistant"}> AI Assistant</Link>
            </li>
            <li
              className={
                params?.section === "integration" ? styles.activeSection : ""
              }
            >
              <Link to={"/profile/integration"}> Integration</Link>
            </li>
            <li
              className={`${
                params?.section === "delete-account" ? styles.activeSection : ""
              } ${styles.dangerZone}`}
            >
              <Link to={"/profile/delete-account"}>Delete Account</Link>
            </li>
          </ul>
        </div>
        <div className={styles.mainContent}>
          {params?.section === "delete-account" && (
            <div className={styles.deleteAccountBox}>
              <p>
                When you <span>delete your account</span>, all the data o your
                account will be <span>permanently removed</span>.
              </p>
              <Button
                type={"primary"}
                className={styles.deleteBtn}
                onClick={handleDeleteAccount}
              >
                Delete Account
              </Button>
            </div>
          )}
          {params.section === "info" && (
            <div>
              <div className={styles.profileImg}>
                <div>
                  {!loading && !profileData?.avatar_url ? (
                    <Avatar
                      className={styles.avatar}
                      name={userData?.["custom:custom_username"]}
                      size="100%"
                      round={true}
                    />
                  ) : (
                    <img src={profileData?.avatar_url} alt="" />
                  )}
                  {uploading && (
                    <div className={styles.loader}>
                      <Spin />
                    </div>
                  )}
                  <span>
                    <ImgCrop
                      showGrid
                      rotationSlider
                      showReset
                      modalTitle="Edit Profile Image"
                      modalProps={{ open: imgCropOpen }}
                      onModalCancel={() => setImgCropOpen(false)}
                    >
                      <Upload
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        disabled={uploading}
                      >
                        <AiTwotoneEdit />
                      </Upload>
                    </ImgCrop>
                  </span>
                </div>
              </div>
              <div className={styles.editButton}>
                <Button onClick={() => setInfoModalShow(true)}>
                  <AiTwotoneEdit /> Complete Info
                </Button>
              </div>
              <div className={styles.profileInfo}>
                <div>
                  <span>Created Date:</span>
                  <span>{formatDate(new Date(profileData?.createdDate))}</span>
                </div>
                <div>
                  <span>Username:</span>
                  <span>{profileData?.user}</span>
                </div>
                <div>
                  <span>Email:</span>
                  <span>{profileData?.email}</span>
                </div>
                <div>
                  <span>Credit:</span>
                  <span>
                    {(profileData?.credit / 100).toFixed(2)}
                    <span className={styles.usdSymbol}>USD</span>
                  </span>
                </div>
                <div>
                  <span>Receive Alerts Emails:</span>
                  <span>{profileData?.receive_alerts_emails}</span>
                </div>
                <div>
                  <span>AI Assistant Risk Appetite:</span>
                  <span>
                    {rateAmountHandler[profileData?.ai_assistant_risk_appetite]}
                  </span>
                </div>
              </div>
            </div>
          )}
          {params.section === "password" && (
            <div>
              {passwordStep === 1 && (
                <p>
                  Click the "Send Confirmation Code" button below to receive a
                  confirmation code via email.
                </p>
              )}

              {passwordStep === 1 ? (
                <Form
                  layout="vertical"
                  className={styles.form}
                  name="sendVerificationCode"
                  onFinish={sendVerificationCode}
                >
                  <Form.Item
                    name="email"
                    label="Email"
                    className={styles.formItem}
                    initialValue={userData?.email}
                  >
                    <Input
                      label={"Email"}
                      placeholder={userData?.email}
                      disabled={true}
                      initialValue={userData?.email}
                      value={userData?.email}
                      style={{ borderRadius: 8 }}
                      className={styles.input}
                    />
                  </Form.Item>
                  <Form.Item name="changePassword">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size={"large"}
                      loading={btnLoading}
                    >
                      Send confirmation code
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form
                  layout="vertical"
                  className={styles.form}
                  name="changePassword"
                  onFinish={onsubmitChangePassword}
                >
                  <p>
                    Once you receive the code, please enter it in the field
                    below:
                  </p>
                  <Form.Item
                    name="confirmationCode"
                    label="Confirmation Code"
                    className={styles.formItem}
                    initialValue={""}
                    autoComplete="off"
                  >
                    <Input
                      label={"Confirmation Code"}
                      placeholder={"confirmationCode"}
                      style={{ borderRadius: 8 }}
                      className={styles.input}
                      autoComplete="off"
                    />
                  </Form.Item>

                  <p>Please enter your new password in the field below:</p>
                  <Form.Item
                    name="newPassword"
                    label="Password"
                    autoComplete="off"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    className={styles.formItem}
                  >
                    <Input.Password
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      placeholder={"Password"}
                      autoComplete="off"
                      style={{ borderRadius: 8 }}
                      className={styles.input}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!",
                            ),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      placeholder={"Confirm Password"}
                      style={{ borderRadius: 8 }}
                      className={styles.input}
                    />
                  </Form.Item>

                  <p>
                    Once you have filled out the form, click the "Submit" button
                    to update your password.
                  </p>
                  <Form.Item name="changePassword">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size={"large"}
                      loading={btnLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          )}
          {params.section === "alert-email" && (
            <div>
              <Form
                layout="vertical"
                className={styles.form}
                name="changeEmail"
                onFinish={changeEmailSubmitHandler}
              >
                <Form.Item
                  name="email"
                  label="Change Alert Email"
                  className={styles.formItem}
                >
                  <Input
                    value={newEmail}
                    defaultValue={profileData?.receive_alerts_emails}
                    onChange={(event) => setNewEmail(event.target.value)}
                    placeholder={"new alert email"}
                    style={{ borderRadius: 8, height: 55 }}
                    className={styles.input}
                  />
                </Form.Item>
                <Form.Item name="changePassword">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size={"large"}
                    loading={updateLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
          {params.section === "ai-assistant" && (
            <>
              <div className={styles.riskAmount}>
                <span className={styles.riskTitle}>
                  AI Assistant Risk Appetite
                </span>
                <p className={styles.riskDescription}>
                  Choose Your Risk Appetite Level:
                </p>
                <RiskAppetite
                  changeHandler={handleRiskChange}
                  amount={riskAmount}
                />
                <div className={styles.btn}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size={"large"}
                    onClick={handleUpdateRiskAmount}
                    loading={updateLoading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </>
          )}
          {params?.section === "integration" && (
            <div className={styles.exchanges}>
              <TopExchanges showMore={setShowMoreExchanges} />
            </div>
          )}
        </div>
      </div>
      <NumberAndPostalCodeForm
        show={infoModalShow}
        setShow={setInfoModalShow}
      />
      <ExchangesModal show={showMoreExchanges} setShow={setShowMoreExchanges} />
    </DashboardLayout>
  );
};

export default Index;
