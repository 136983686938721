import { FaExclamationCircle } from "react-icons/fa";
import styles from "../../styles/Components/pricing-plan/PricingDiscountButton.module.css";
import useGeoLocation from "../../hooks/useGeolocation";
import { useEffect, useRef, useState } from "react";
import { message, Modal, QRCode, Spin } from "antd";
import { LuScanLine } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import { getProfileData } from "../../redux/profile/actions";
import useUserTier from "../../hooks/useUserTier";

function PricingDiscountButton({ button, className, style = {} }) {
  const {
    main: { userData },
  } = useSelector((state) => state);
  const [showModal, setShowModal] = useState(false);

  const { canTry } = useUserTier();

  const { getLocation } = useGeoLocation();

  const clickHandler = () => {
    if (userData?.email === "sami.pawl@gmail.com") {
      return setShowModal(true);
    }
    if (!canTry) {
      return message.info(
        "Your location already has been verified! You can retry later.",
      );
    }
    if (isMobile || isTablet) {
      getLocation();
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <div
        className={`${styles.container} ${className || ""}`}
        style={style}
        onClick={clickHandler}
      >
        {button ? (
          button
        ) : (
          <>
            {" "}
            <FaExclamationCircle
              className={styles.attentionIcon}
              size={20}
              color="#0C4CFC"
            />
            <span>Are you eligible for a low-income country discount?</span>
          </>
        )}
      </div>

      {showModal && (
        <DiscountProcessModal
          show={showModal}
          setShow={setShowModal}
          // type={!isMobile || !isTablet ? "process" : "alert"}
          type="alert"
        />
      )}
    </>
  );
}

export default PricingDiscountButton;

const DiscountProcessModal = ({ show, setShow, type }) => {
  const {
    main: { userData },
    profile: { profileData },
    alertSystem: { telegramBotToken, telegramBotTokenStatus },
  } = useSelector((state) => state);

  const [showQrCode, setShowQrCode] = useState(false);
  const intervalRef = useRef(null);

  const dispatch = useDispatch();

  const { canTry } = useUserTier();

  const qrValue = `https://app-responsive.cryptocrispy.com.au/verify-location?user=${
    userData?.["custom:custom_username"]
  }&email=${userData?.email}&token=${telegramBotToken}`;

  const closeModal = () => {
    setShowQrCode(false);
    setShow(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const handleGenerateButtonClick = () => {
    setShowQrCode(true);

    // Clear any existing interval before starting a new one
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    // check profile data if it has not been vrefied yet
    if (canTry) {
      intervalRef.current = setInterval(() => {
        dispatch(
          getProfileData(userData?.email, userData?.["custom:custom_username"]),
        );
      }, 5000);
    }
  };

  // Clear interval on unmount
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      message.destroy("checking-message");
    };
  }, []);

  useEffect(() => {
    if (!canTry) {
      message.loading({
        content: "Checking...",
        key: "checking-message", // Unique key for the message
        duration: 60,
      });
    }
  }, [canTry]);

  return (
    <Modal open={show} onCancel={closeModal} footer={null} centered>
      {type === "alert" && (
        <div className={styles.alertContent}>
          <h4 className={styles.alertTitle}>
            <LuScanLine color="#0C4CFC" size={22} />
            Scan QR code using your mobile device to log in, and complete the
            action there.
          </h4>

          {!showQrCode ? (
            <span
              className={styles.generateBtn}
              onClick={handleGenerateButtonClick}
            >
              Generate QR code
            </span>
          ) : telegramBotTokenStatus === "loading" ? (
            <div className={styles.loader}>
              <Spin />
            </div>
          ) : (
            <QRCode value={qrValue} size={200} level="H" />
          )}
        </div>
      )}

      {/*{type === "process" && (*/}
      {/*  <div className={styles.alertContent}>{isLoading ? <Spin /> : "t"}</div>*/}
      {/*)}*/}
    </Modal>
  );
};
