import styles from "../../../styles/Components/trade-assistant/assistant/SignalMoreInfo.module.css";

function SignalMoreInfo({ data = {} }) {
  const {
    SYMBOL_NAME: symbol,
    SIGNAL_STRENGTH: strength,
    EXPECTED_RETURN: expectedReturn,
    SUGGESTED_AMOUNT_PERCENTAGE: suggestedAmount,
    SUGGESTED_LEVERAGE: suggestedLeverage,
    CATEGORY_INFO: { subcategory, name, description },
  } = data;

  const strengthStatus =
    strength === 1
      ? "Very Weak"
      : strength === 2
        ? "Weak"
        : strength === 3
          ? "Moderate"
          : strength === 4
            ? "Strong"
            : "Excellent";

  return (
    <div className={styles.container}>
      <h4 className={styles.header}>Signal Details</h4>
      <ul className={styles.infoList}>
        <li className={styles.infoItem}>
          <span className={styles.label}>Signal Strength:</span>
          <span className={styles.info}>
            {strengthStatus} ({strength * 20}%)
          </span>
        </li>

        <li className={styles.infoItem}>
          <span className={styles.label}>Expected Return:</span>
          <span className={styles.info}>{expectedReturn}</span>
        </li>

        <li className={styles.infoItem}>
          <span className={styles.label}>Recommended Size%:</span>
          <span className={styles.info}>
            {Math.trunc(suggestedAmount * 100)}%
          </span>
        </li>

        <li className={styles.infoItem}>
          <span className={styles.label}>Suggested Leverage:</span>
          <span className={styles.info}>{suggestedLeverage}</span>
        </li>

        <li className={styles.infoItem}>
          <span className={styles.label}>Subcategory:</span>
          <span className={styles.info}>{subcategory}</span>
        </li>

        <li className={styles.infoItem}>
          <span className={styles.label}>Name:</span>
          <span className={styles.info}>{name}</span>
        </li>

        <li className={styles.infoItem}>
          <span className={styles.label}>Description:</span>
          <span className={styles.info}>{description}</span>
        </li>
      </ul>
    </div>
  );
}

export default SignalMoreInfo;
