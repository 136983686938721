import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { MdErrorOutline } from "react-icons/md";

function ErrorMessage({ children }) {
  return (
    <div className={styles.balanceWarning}>
      <MdErrorOutline size={16} />
      {children}
    </div>
  );
}

export default ErrorMessage;
