import styles from "../../../styles/Components/integration/portfolio/ExchangePortfolioTableActionButtons.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import alert from "../../alerts/Alert";
import { showWarningForSellCurrencies } from "../../../utility/helper";
import {
  sellAllCryptoAssets,
  sellAllFiatAssets,
} from "../../../redux/bot/actions";
import { FiRefreshCcw } from "react-icons/fi";
import {
  getExchangeBalanceChart,
  getExchangePortfolio,
} from "../../../redux/exchange/actions";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiDollarCircle, BiSolidBank } from "react-icons/bi";
import TradeButton from "../../../shared/trade-form/TradeButton";

function ExchangePortfolioTableActionButtons() {
  const {
    main: { userData },
    exchangeState: {
      portfolioLoading,
      portfolioData,
      selectedPortfolioExchange,
    },
    botState: { sellAllFiatAssetsLoading, sellAllCryptoAssetsLoading },
  } = useSelector((state) => state);

  const portfolioFiatAmount = portfolioData?.find(
    (item) => item?.assetName?.toLowerCase()?.replace("/usdt", "") === "usdt",
  );

  const isAssetInPortfolio = portfolioData?.find(
    (item) => item?.assetName?.toLowerCase()?.replace("/usdt", "") !== "usdt",
  );

  const dispatch = useDispatch();

  const handleSellAllFiatAsset = () => {
    if (sellAllFiatAssetsLoading || portfolioLoading) return;
    if (!portfolioData.length || !portfolioFiatAmount) {
      return alert(
        null,
        "You have no fiat currencies in your portfolio.",
        "info",
        {
          cancel: "Close",
        },
      );
    }
    if (+portfolioFiatAmount?.availableBalanceUSD < 1) {
      return alert(
        null,
        "Your fiat currencies are below 1$. Selling all your fiat currencies is not allowed.",
        "info",
        {
          cancel: "Close",
        },
      );
    }
    return showWarningForSellCurrencies(
      () =>
        dispatch(
          sellAllFiatAssets(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedPortfolioExchange,
          ),
        ),
      "fiat",
    );
  };

  const handleRefresh = () => {
    if (portfolioLoading) return;
    dispatch(
      getExchangePortfolio(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedPortfolioExchange,
      ),
    );

    dispatch(
      getExchangeBalanceChart(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        selectedPortfolioExchange,
      ),
    );
  };
  const handleSellAllCryptoAssets = () => {
    if (sellAllCryptoAssetsLoading || portfolioLoading) return;

    if (!portfolioData.length || !isAssetInPortfolio) {
      return alert(
        null,
        "You have no crypto assets in your portfolio.",
        "info",
        {
          cancel: "Close",
        },
      );
    }
    return showWarningForSellCurrencies(
      () =>
        dispatch(
          sellAllCryptoAssets(
            userData?.["custom:custom_username"],
            userData?.["cognito:username"],
            selectedPortfolioExchange,
          ),
        ),
      "crypto",
    );
  };

  const actionMenuItems = [
    {
      label: "Sell All Fiat",
      icon: <BiSolidBank />,
      key: "1",
    },
    {
      label: "Sell All Crypto",
      icon: <BiDollarCircle />,
      key: "2",
    },
  ];

  const clickMenuHandler = (key) => {
    switch (key) {
      // case "1":
      //   return;
      case "1":
        handleSellAllFiatAsset();
        break;
      case "2":
        handleSellAllCryptoAssets();
        break;
      default:
        return;
    }
  };

  const actionMenu = (
    <Menu
      className={styles.actionMenu}
      items={actionMenuItems}
      onClick={(e) => clickMenuHandler(e.key)}
    />
  );

  return (
    <>
      <ul className={styles.container}>
        <Dropdown overlay={actionMenu}>
          <Button className={styles.moreBtn}>
            More Actions <MdKeyboardArrowDown />
          </Button>
        </Dropdown>

        <Button
          className={`${styles.sellFiatBtn} ${styles.desktopBtn}`}
          loading={sellAllFiatAssetsLoading}
          onClick={handleSellAllFiatAsset}
        >
          Sell All Fiat
        </Button>

        <Button
          className={`${styles.sellCryptoBtn} ${styles.desktopBtn}`}
          loading={sellAllCryptoAssetsLoading}
          onClick={handleSellAllCryptoAssets}
        >
          Sell All Crypto
        </Button>

        <TradeButton mode="trade-btn" defaultStyle>
          Buy / Sell
        </TradeButton>

        <Button
          onClick={handleRefresh}
          loading={portfolioLoading}
          type={"primary"}
          className={styles.refreshBtn}
        >
          {!portfolioLoading && <FiRefreshCcw />}
          <span className={styles.desktopView}>Refresh</span>
        </Button>
      </ul>
    </>
  );
}

export default ExchangePortfolioTableActionButtons;
