import styles from "../../../styles/shared/trade-form/TradeModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import useCheckPortfolio from "../hooks/useCheckPortfolio";
import useAssetAmount from "../hooks/useAssetAmount";
import useCheckOrderLimits from "../hooks/useCheckOrderLimits";
import {
  changeStep,
  placeOrder,
} from "../../../redux/trade-form/tradeFormSlice";

function PlaceOrderButton() {
  const {
    tradeForm: {
      targetExchange,
      targetCoin,
      assetPrice,
      finalTradeType,
      tradeType,
      tradeNativeAmount,
      tradeUsdAmount,
      slTriggerPrice,
      tpTriggerPrice,
      isLimitSet,
    },
    main: { userData },
  } = useSelector((state) => state);

  const { isTradePermitted: enabled } = useCheckOrderLimits();
  const { isEnoughBalance } = useCheckPortfolio();

  const dispatch = useDispatch();

  // console.log(
  //   useCheckOrderLimits(),
  //   isEnoughBalance,
  //   !isLimitSet || (tpTriggerPrice > assetPrice && slTriggerPrice < assetPrice),
  //   (1 - slTriggerPrice) * assetPrice,
  //   assetPrice,
  //   (1 + tpTriggerPrice) * assetPrice,
  // );

  const handleTrade = () => {
    if (!enabled) return;

    const type =
      finalTradeType === "buy" && isLimitSet ? "buy_with_oco" : finalTradeType;

    const triggerPrice1 =
      finalTradeType === "sell_sl" || finalTradeType === "buy_db"
        ? slTriggerPrice
        : finalTradeType === "sell_tp" || finalTradeType === "buy_bb"
          ? tpTriggerPrice
          : slTriggerPrice;

    const triggerPrice2 = tpTriggerPrice;

    dispatch(
      placeOrder(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        type,
        targetExchange,
        targetCoin,
        tradeNativeAmount,
        triggerPrice1,
        triggerPrice2,
      ),
    );
    dispatch(changeStep(3));
  };

  return (
    <span
      className={`${styles.nextBtn} ${!enabled ? styles.disabled : ""}`}
      onClick={handleTrade}
    >
      Place Order
    </span>
  );
}

export default PlaceOrderButton;
