import useCheckBalance from "../../hooks/useCheckPortfolio";
import ErrorMessage from "./ErrorMessage";

function NotEnoughBalance() {
  const { canBuy } = useCheckBalance();

  if (canBuy) return null;

  return <ErrorMessage>Insufficient balance!</ErrorMessage>;
}

export default NotEnoughBalance;
