/** @format */

import {
	SHOW_RATING_MODAL,
	HIDE_RATING_MODAL,
	SET_LAST_SHOWN_DATE,
} from "./actions";

const initialState = {
	showModal: false,
	currentPage: null,
	lastShownDate: null,
};

const ratingModalReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_RATING_MODAL:
			return {
				...state,
				showModal: true,
				currentPage: action.payload,
			};
		case HIDE_RATING_MODAL:
			return {
				...state,
				showModal: false,
				currentPage: null,
			};
		case SET_LAST_SHOWN_DATE:
			return {
				...state,
				lastShownDate: action.payload,
			};
		default:
			return state;
	}
};

export default ratingModalReducer;
