import { Empty } from "antd";
import TradesTable from "./TradesTable";

export default function HistoryBox({ history }) {
  return (
    <>
      {!history ? (
        <>
          <Empty />
        </>
      ) : (
        <TradesTable data={history} />
      )}
    </>
  );
}
