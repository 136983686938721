import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableCoins } from "../../redux/exchange/actions";
import { IoMdAlert, IoMdArrowDropright } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { Button, Empty, Modal, Select, Spin } from "antd";

import styles from "../../styles/shared/other-coins-signal/OtherCoins.module.css";
import { Link } from "react-router-dom";
import { analyzeOtherCoins } from "../../redux/analyze-other-coins/otherCoinsAnalyzeSlice";
import SelectExchange from "../SelectExchange";
import useServiceStatus from "../../hooks/useServiceStatus";

function OtherCoins({
  btnTitle,
  disabledCoins = [],
  btnStyle = {},
  active,
  children,
  className = "",
}) {
  const [openModal, setOpenModal] = useState(false);

  const { isActive } = useServiceStatus("aiAssistant");

  const clickHandler = () => {
    if (!isActive) return;

    setOpenModal(true);
  };

  return (
    <>
      <span
        className={`${className} 
        
        `}
        style={btnStyle}
        onClick={clickHandler}
      >
        {children || btnTitle || <FaPlus />}
      </span>

      {openModal && (
        <OtherCoinsModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          disabledCoins={disabledCoins}
        />
      )}
    </>
  );
}

// ${styles.button} ${!active ? styles.disabled : ""}

export default OtherCoins;

function OtherCoinsModal({ open, onClose, disabledCoins }) {
  const {
    exchangeState: {
      assetsLoading,
      assetsList,
      availableCoinsStatus,
      availableCoinsData,
      exchangesLoading,
    },
    otherCoinsAnalyze: { analyze, status, errorMessage },
    main: { userData },
    tradingAssistant: { aiMarketType },
  } = useSelector((state) => state);

  const [selectedExchange, setSelectedExchange] = useState("");
  const [selectedSymbols, setSelectedSymbol] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const symbolsInputRef = useRef(null);

  const dispatch = useDispatch();
  const exchangeAssets = availableCoinsData?.[selectedExchange] || [];
  const coinsStatus = availableCoinsStatus?.[selectedExchange];

  const loadingMessage =
    status === "loading"
      ? "Fetching signals..."
      : coinsStatus === "loading"
        ? "Fetching coins information..."
        : exchangesLoading
          ? "Fetching exchanges..."
          : "";

  const assets = exchangeAssets?.filter((asset) => {
    const baseCurrency = asset?.split("/")?.[0];

    return !disabledCoins?.includes(baseCurrency?.toUpperCase());
  });

  const closeModal = () => {
    if (status === "loading") return;

    setSelectedExchange("");
    setSelectedSymbol([]);
    setIsDone(false);
    onClose();
  };

  const handleExchangeChange = (exchange) => {
    setSelectedExchange(exchange);
    // dispatch(
    //   getAssetList(
    //     userData?.["custom:custom_username"],
    //     userData?.["cognito:username"],
    //     exchange,
    //   ),
    // );
    setSelectedSymbol([]);
    if (availableCoinsData?.[exchange]) return;
    dispatch(getAvailableCoins(exchange));
  };

  const handleSymbolChange = (value) => {
    setSelectedSymbol(value);
  };

  const handleSubmit = () => {
    if (status === "loading") return;
    setIsDone(true);

    const coins = selectedSymbols.map((symbol) => symbol.split("/USDT")[0]);

    return dispatch(
      analyzeOtherCoins(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
        aiMarketType,
        coins,
        selectedExchange,
      ),
    );
  };

  // automatically close the modal when the analyze finished or failed
  useEffect(() => {
    const coinAnalyze = selectedSymbols.map((symbol) =>
      analyze?.find((data) => data?.SYMBOL_NAME === symbol),
    );
    console.log(coinAnalyze);
    if (
      status !== "loading" &&
      // (analyze?.length || status === "error") &&
      analyze.length &&
      isDone
    ) {
      closeModal();
    }
  }, [analyze?.length, closeModal, isDone, status]);

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      centered
      footer={null}
      title="Get other coins signal"
      maskClosable={false}
    >
      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select an Exchange
        </h4>
        <SelectExchange
          value={selectedExchange}
          onChange={handleExchangeChange}
          loading={status === "loading"}
          style={{ width: "100%" }}
        />
      </div>

      <div className={styles.box}>
        <h4>
          <IoMdArrowDropright />
          Select a Coin
        </h4>
        <Select
          style={{ width: "100%" }}
          ref={symbolsInputRef}
          placeholder="Select a coin"
          value={selectedSymbols}
          onChange={handleSymbolChange}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          mode="multiple"
          // options={symbolOptions}
          disabled={
            !selectedExchange ||
            // (selectedExchange && !assetsLoading && assetsList.length === 0)
            (selectedExchange && coinsStatus === "loading") ||
            status === "loading"
          }
        >
          {coinsStatus === "loading" ? (
            <Select.Option disabled>Loading...</Select.Option>
          ) : assets.length > 0 ? (
            assets?.map((asset) => (
              <Select.Option
                key={asset}
                value={asset}
                className={styles.option}
              >
                {asset}
              </Select.Option>
            ))
          ) : (
            <Select.Option disabled className={styles.emptyOption}>
              <Empty imageStyle={{ width: "80px", height: "60px" }} />
            </Select.Option>
          )}
        </Select>

        {selectedExchange &&
          coinsStatus === "success" &&
          exchangeAssets.length === 0 && (
            <p className={styles.errorMsg}>
              You need o <Link to="/integration">integrate</Link> with this
              exchange first!
            </p>
          )}
      </div>

      <div className={styles.footer}>
        {(coinsStatus === "loading" ||
          status === "loading" ||
          exchangesLoading) && (
          <div className={styles.loader}>
            <Spin size="small" />
            <p>{loadingMessage}</p>
          </div>
        )}
        {/*{status === "loading" && (*/}
        {/*  <div className={styles.loader}>*/}
        {/*    <Spin size="small" />*/}
        {/*    <p>Fetching signals...</p>*/}
        {/*  </div>*/}
        {/*)}*/}

        {selectedExchange &&
          selectedSymbols.length > 0 &&
          status === "error" &&
          isDone && (
            <div className={styles.errorMessage}>
              <IoMdAlert color="red" />
              <p>Something went wrong.Try again.</p>
            </div>
          )}

        <Button
          onClick={handleSubmit}
          loading={status === "loading"}
          className={styles.submitButton}
          disabled={!selectedSymbols.length || !selectedExchange}
        >
          {status === "error" && isDone ? "Retry" : "Confirm"}
        </Button>
      </div>
    </Modal>
  );
}
