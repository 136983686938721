import styles from "../../../../styles/shared/trade-form/TradeModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "antd";
import { setFinalTradeType } from "../../../../redux/trade-form/tradeFormSlice";

const options = {
  buy: [
    {
      label: "Discount buy",
      description: "Buy at a lower price than the current market price.",
      value: "buy_db",
    },
    {
      label: "Breakout buy",
      description: "Buy at a higher price than the current market price.",
      value: "buy_bb",
    },
  ],
  sell: [
    {
      label: "Stop loss",
      description: "Sell at a lower price than the current market price.",
      value: "sell_sl",
    },
    {
      label: "Take profit",
      description: "Sell at a higher price than the current market price.",
      value: "sell_tp",
    },
  ],
};

function LimitOrderOptions() {
  const {
    tradeForm: { finalTradeType, tradeType },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const items = options?.[tradeType];

  const handleClick = (value) => {
    return () => dispatch(setFinalTradeType(value));
  };

  return (
    <div
      className={styles.tradeTypeBtns}
      style={{ justifyContent: "flex-start" }}
    >
      {items?.map((item) => (
        <span
          onClick={handleClick(item.value)}
          className={`${styles.tradeTypeBtn} ${finalTradeType === item.value ? styles.selectedTradeType : ""}`}
        >
          {item.label}
          <Tooltip color="geekblue" title={item.description}>
            <IoMdInformationCircleOutline />
          </Tooltip>
        </span>
      ))}
    </div>
  );
}

export default LimitOrderOptions;
