import {
  CHECK_EXCHANGE_IP_ERROR,
  CHECK_EXCHANGE_IP_REQUEST,
  CHECK_EXCHANGE_IP_SUCCESS,
  EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_ERROR,
  EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_REQUEST,
  EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_SUCCESS,
  EXCHANGE_TRADE_HISTORY_ERROR,
  EXCHANGE_TRADE_HISTORY_REQUEST,
  EXCHANGE_TRADE_HISTORY_SUCCESS,
  GET_ASSETS_ERROR,
  GET_ASSETS_REQUEST,
  GET_ASSETS_SUCCESS,
  GET_COIN_FROM_EXCHANGE_ERROR,
  GET_COIN_FROM_EXCHANGE_REQUEST,
  GET_COIN_FROM_EXCHANGE_SUCCESS,
  GET_CONNECTION_INFO_ERROR,
  GET_CONNECTION_INFO_REQUEST,
  GET_CONNECTION_INFO_SUCCESS,
  GET_EXCHANGE_BALANCE_CHART_ERROR,
  GET_EXCHANGE_BALANCE_CHART_REQUEST,
  GET_EXCHANGE_BALANCE_CHART_SUCCESS,
  GET_EXCHANGE_PORTFOLIO_ERROR,
  GET_EXCHANGE_PORTFOLIO_FAST_ERROR,
  GET_EXCHANGE_PORTFOLIO_FAST_REQUEST,
  GET_EXCHANGE_PORTFOLIO_FAST_SUCCESS,
  GET_EXCHANGE_PORTFOLIO_REQUEST,
  GET_EXCHANGE_PORTFOLIO_SUCCESS,
  GET_EXCHANGE_PRICE_ERROR,
  GET_EXCHANGE_PRICE_REQUEST,
  GET_EXCHANGE_PRICE_SUCCESS,
  GET_EXCHANGES_INFO_ERROR,
  GET_EXCHANGES_INFO_REQUEST,
  GET_EXCHANGES_INFO_SUCCESS,
  GET_INTEGRATED_EXCHANGES_ERROR,
  GET_INTEGRATED_EXCHANGES_REQUEST,
  GET_INTEGRATED_EXCHANGES_SUCCESS,
  GET_TRUSTED_IPS_ERROR,
  GET_TRUSTED_IPS_REQUEST,
  GET_TRUSTED_IPS_SUCCESS,
  INTEGRATE_EXCHANGE_ERROR,
  INTEGRATE_EXCHANGE_REQUEST,
  INTEGRATE_EXCHANGE_SUCCESS,
  LINK_WITH_EXCHANGE_ERROR,
  LINK_WITH_EXCHANGE_REQUEST,
  LINK_WITH_EXCHANGE_SUCCESS,
  ORDER_TRADE_ERROR,
  ORDER_TRADE_REQUEST,
  ORDER_TRADE_SUCCESS,
  SET_EXCHANGE_FOR_CONNECTION,
  SET_PREVIOUS_PATH,
  SET_SELECTED_PORTFOLIO_EXCHANGE,
  UNLINK_FROM_EXCHANGE_ERROR,
  UNLINK_FROM_EXCHANGE_REQUEST,
  UNLINK_FROM_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_HISTORY_SUCCESS,
  UPDATE_EXCHANGE_PORTFOLIO_DATA,
} from "./consts";

const initialState = {
  exchangesInfo: {},
  exchanges: [],
  exchangesError: "",
  exchangesLoading: false,
  exchangesConnectionInfo: [],
  integrateExchangeLoading: false,
  integrateExchangeMessage: {},
  integrateExchangeError: "",
  integratedData: [],
  getIntegratedList: false,
  integratedDataLoading: false,
  integratedDataFetched: false,
  integratedDataError: "",
  exchangeHistoryLoading: false,
  exchangeHistory: [],
  exchangeHistoryError: "",
  exchangeComprehensiveHistoryLoading: false,
  exchangeComprehensiveHistory: [],
  exchangeComprehensiveHistoryError: "",
  orderTradeLoading: false,
  orderTradeData: {},
  orderTradeError: "",
  priceLoading: false,
  priceData: {},
  priceError: "",
  portfolioLoading: false,
  portfolioData: [],
  fastPortfolioLoading: false,
  fastPortfolioData: [],
  fastPortfolioError: "",
  fetchPortfolioFast: false,
  fee: "",
  portfolioError: "",
  assetsLoading: false,
  assetsList: [],
  assetsError: "",
  connectionInfoLoading: false,
  connectionInfoData: {},
  connectionInfoError: "",
  selectedPortfolioExchange: "",
  previousPath: "",
  exchangeForConnection: "",
  linkWithExchangeLoading: false,
  linkWithExchangeMessage: "",
  linkWithExchangeError: "",
  unlinkFromExchangeLoading: false,
  unlinkFromExchangeMessage: "",
  unlinkFromExchangeError: "",
  trustedIps: [],
  getTrustedIpsStatus: "",
  checkExchangeIpResponse: {},
  checkExchangeIpStatus: "",
  isMaximumHistory: false,
  isMaximumComprehensiveHistory: false,
  balanceChartStatus: "",
  balanceChartData: {},
  balanceChartError: "",
  availableExchangesData: {},
  availableExchangesStatus: {},
  availableExchangesError: {},
  availableCoinsData: {},
  availableCoinsStatus: {},
  availableCoinsError: {},
};

export const exchangeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_EXCHANGES_INFO_REQUEST:
      return {
        ...state,
        exchangesLoading: true,
        exchangesError: "",
      };
    case GET_EXCHANGES_INFO_SUCCESS:
      const exchangesName = payload?.all_name_list?.filter(
        (ex) => ex?.toLowerCase() !== "cryptocrispy",
      );
      const exchangesId = payload?.all_id_list?.filter(
        (ex) => ex !== "cryptocrispy",
      );

      const allExchanges = exchangesName.map((exchange, i) => {
        return {
          name: exchange,
          id: exchangesId[i],
        };
      });

      return {
        ...state,
        exchangesLoading: false,
        exchangesInfo: payload,
        exchangesConnectionInfo: payload?.list_echanges_and_connections,
        exchanges: [...allExchanges],
      };
    case GET_EXCHANGES_INFO_ERROR:
      return {
        ...state,
        exchangesLoading: false,
        exchangesError: payload,
      };
    case "clear-integration-process-data":
      return {
        ...state,
        integrateExchangeLoading: false,
        integrateExchangeError: "",
        integrateExchangeMessage: {},
      };
    case INTEGRATE_EXCHANGE_REQUEST:
      return {
        ...state,
        integrateExchangeLoading: true,
        integrateExchangeError: "",
        integrateExchangeMessage: {},
      };
    case INTEGRATE_EXCHANGE_SUCCESS:
      return {
        ...state,
        integrateExchangeLoading: false,
        integrateExchangeMessage: payload,
      };
    case INTEGRATE_EXCHANGE_ERROR:
      return {
        ...state,
        integrateExchangeLoading: false,
        integrateExchangeError: payload,
      };
    case GET_INTEGRATED_EXCHANGES_REQUEST:
      return {
        ...state,
        integratedDataLoading: true,
        integratedDataError: "",
      };
    case GET_INTEGRATED_EXCHANGES_SUCCESS:
      const exchanges = payload?.map((ex) =>
        ex?.id === "cryptocrispy" ? { ...ex, name: "Test Exchange" } : ex,
      );
      return {
        ...state,
        integratedDataLoading: false,
        integratedData: exchanges,
        getIntegratedList: true,
      };
    case GET_INTEGRATED_EXCHANGES_ERROR:
      return {
        ...state,
        integratedDataLoading: false,
        integratedDataError: payload,
        integratedDataFetched: false,
      };
    case EXCHANGE_TRADE_HISTORY_REQUEST:
      return {
        ...state,
        exchangeHistoryLoading: true,
        exchangeHistoryError: "",
      };
    case EXCHANGE_TRADE_HISTORY_SUCCESS:
      return {
        ...state,
        exchangeHistoryLoading: false,
        exchangeHistory: payload,
      };
    case EXCHANGE_TRADE_HISTORY_ERROR:
      return {
        ...state,
        exchangeHistoryError: payload,
        exchangeHistoryLoading: false,
      };

    case EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_REQUEST:
      return {
        ...state,
        exchangeComprehensiveHistoryLoading: true,
        exchangeComprehensiveHistoryError: "",
      };
    case EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_SUCCESS:
      return {
        ...state,
        exchangeComprehensiveHistoryLoading: false,
        exchangeComprehensiveHistory: payload,
      };
    case EXCHANGE_COMPREHENSIVE_TRADE_HISTORY_ERROR:
      return {
        ...state,
        exchangeComprehensiveHistoryError: payload,
        exchangeComprehensiveHistoryLoading: false,
      };

    case UPDATE_EXCHANGE_HISTORY_SUCCESS:
      return {
        ...state,
        exchangeComprehensiveHistoryLoading: false,
        exchangeComprehensiveHistory: [...state.exchangeHistory, ...payload],
        isMaximumHistory: payload?.length === 0,
      };

    case ORDER_TRADE_REQUEST:
      return {
        ...state,
        orderTradeLoading: true,
        orderTradeError: "",
      };
    case ORDER_TRADE_SUCCESS:
      return {
        ...state,
        orderTradeLoading: false,
        orderTradeData: payload,
      };
    case ORDER_TRADE_ERROR:
      return {
        ...state,
        orderTradeLoading: false,
        orderTradeError: payload,
      };
    case GET_EXCHANGE_PRICE_REQUEST:
      return {
        ...state,
        priceLoading: true,
        priceError: "",
      };
    case GET_EXCHANGE_PRICE_SUCCESS:
      return {
        ...state,
        priceLoading: false,
        priceData: payload,
      };
    case GET_EXCHANGE_PRICE_ERROR:
      return {
        ...state,
        priceError: payload,
        priceLoading: false,
      };
    case GET_EXCHANGE_PORTFOLIO_REQUEST:
      return {
        ...state,
        portfolioLoading: true,
        portfolioData: [],
        portfolioError: "",
      };
    case GET_EXCHANGE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolioLoading: false,
        portfolioData: payload.portfolio,
        fee: payload.transaction_fee,
      };
    case GET_EXCHANGE_PORTFOLIO_ERROR:
      return {
        ...state,
        portfolioLoading: false,
        portfolioError: payload,
      };

    case GET_EXCHANGE_PORTFOLIO_FAST_REQUEST:
      return {
        ...state,
        portfolioLoading: true,
        portfolioError: "",
        fastPortfolioLoading: true,
        fastPortfolioError: "",
      };
    case GET_EXCHANGE_PORTFOLIO_FAST_SUCCESS:
      return {
        ...state,
        portfolioLoading: false,
        portfolioData: payload.portfolio,
        fastPortfolioLoading: false,
        fastPortfolioData: payload?.portfolio,
        fee: payload.transaction_fee,
        fetchPortfolioFast: true,
      };
    case GET_EXCHANGE_PORTFOLIO_FAST_ERROR:
      return {
        ...state,
        fastPortfolioLoading: false,
        fastPortfolioError: payload,
        portfolioLoading: false,
        portfolioError: payload,
      };

    case UPDATE_EXCHANGE_PORTFOLIO_DATA:
      return {
        ...state,
        portfolioData: payload,
        fastPortfolioData: payload,
      };

    case GET_ASSETS_REQUEST:
      return {
        ...state,
        assetsLoading: true,
        assetsError: "",
      };
    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        assetsLoading: false,
        assetsList: payload,
      };
    case GET_ASSETS_ERROR:
      return {
        ...state,
        assetsLoading: false,
        assetsError: payload,
      };
    case GET_CONNECTION_INFO_REQUEST:
      return {
        ...state,
        connectionInfoLoading: true,
        connectionInfoError: "",
      };
    case GET_CONNECTION_INFO_SUCCESS:
      return {
        ...state,
        connectionInfoLoading: false,
        connectionInfoData: payload,
      };
    case GET_CONNECTION_INFO_ERROR:
      return {
        ...state,
        connectionInfoLoading: false,
        connectionInfoError: payload,
      };
    case SET_PREVIOUS_PATH:
      return {
        ...state,
        previousPath: payload,
      };
    case SET_SELECTED_PORTFOLIO_EXCHANGE:
      return {
        ...state,
        selectedPortfolioExchange: payload,
      };
    case SET_EXCHANGE_FOR_CONNECTION:
      return {
        ...state,
        exchangeForConnection: payload,
      };

    case LINK_WITH_EXCHANGE_REQUEST:
      return {
        ...state,
        linkWithExchangeLoading: true,
        linkWithExchangeMessage: "",
        linkWithExchangeError: "",
      };
    case LINK_WITH_EXCHANGE_SUCCESS:
      return {
        ...state,
        linkWithExchangeLoading: false,
        linkWithExchangeMessage: payload,
      };
    case LINK_WITH_EXCHANGE_ERROR:
      return {
        ...state,
        linkWithExchangeLoading: false,
        linkWithExchangeError: payload,
      };

    case UNLINK_FROM_EXCHANGE_REQUEST:
      return {
        ...state,
        unlinkFromExchangeLoading: true,
        unlinkFromExchangeMessage: "",
        unlinkFromExchangeError: "",
      };
    case UNLINK_FROM_EXCHANGE_SUCCESS:
      return {
        ...state,
        unlinkFromExchangeLoading: false,
        unlinkFromExchangeMessage: payload,
      };
    case UNLINK_FROM_EXCHANGE_ERROR:
      return {
        ...state,
        unlinkFromExchangeLoading: false,
        unlinkFromExchangeError: payload,
      };

    case "get-trusted-ips":
      return {
        ...state,
        trustedIps: payload,
      };

    case GET_TRUSTED_IPS_REQUEST:
      return {
        ...state,
        getTrustedIpsStatus: "loading",
      };

    case GET_TRUSTED_IPS_SUCCESS:
      return {
        ...state,
        getTrustedIpsStatus: "done",
        trustedIps: payload,
      };

    case GET_TRUSTED_IPS_ERROR:
      return {
        ...state,
        getTrustedIpsStatus: "error",
      };

    case CHECK_EXCHANGE_IP_REQUEST:
      return {
        ...state,
        checkExchangeIpStatus: "loading",
        checkExchangeIpResponse: {},
      };

    case CHECK_EXCHANGE_IP_SUCCESS:
      const updatedExchangeData = payload?.[0];
      const filteredIntegratedData = state.integratedData?.filter(
        (ex) => ex.id !== updatedExchangeData?.id,
      );

      return {
        ...state,
        checkExchangeIpStatus: "done",
        checkExchangeIpResponse: payload?.[0],
        integratedData: [...filteredIntegratedData, updatedExchangeData],
      };

    case CHECK_EXCHANGE_IP_ERROR:
      return {
        ...state,
        checkExchangeIpStatus: "error",
      };

    case "reset-exchange-status":
      return {
        ...state,
        checkExchangeIpStatus: "",
        checkExchangeIpResponse: {},
      };

    case "update-integrated-data":
      const { exchangeId, name } = payload;
      const newIntegratedData = state.integratedData.filter(
        (ex) => ex.id !== exchangeId,
      );

      const newExchangeData = {
        id: exchangeId,
        status: "Active",
        ip_status: "OK",
        name,
      };

      return {
        ...state,
        integratedData: [...newIntegratedData, newExchangeData],
      };

    case GET_EXCHANGE_BALANCE_CHART_REQUEST:
      return {
        ...state,
        balanceChartData: {},
        balanceChartStatus: "loading",
        balanceChartError: "",
      };
    case GET_EXCHANGE_BALANCE_CHART_SUCCESS:
      return {
        ...state,
        balanceChartStatus: "done",
        balanceChartData: payload,
      };
    case GET_EXCHANGE_BALANCE_CHART_ERROR:
      return {
        ...state,
        balanceChartStatus: "error",
        balanceChartError: payload,
      };

    case GET_COIN_FROM_EXCHANGE_REQUEST:
      return {
        ...state,
        availableCoinsStatus: {
          ...state.availableCoinsStatus,
          [`${payload}`]: "loading",
        },
      };
    case GET_COIN_FROM_EXCHANGE_SUCCESS:
      const coins = payload?.data?.map((i) => i?.Coin);
      return {
        ...state,
        availableCoinsStatus: {
          ...state.availableCoinsStatus,
          [`${payload.exchange}`]: "success",
        },
        availableCoinsData: {
          ...state.availableCoinsData,
          [`${payload.exchange}`]: coins,
          [`${payload.exchange}-limits`]: payload?.data,
        },
      };
    case GET_COIN_FROM_EXCHANGE_ERROR:
      return {
        ...state,
        availableCoinsStatus: {
          ...state.availableCoinsStatus,
          [`${payload.exchange}`]: "error",
        },
      };

    default:
      return state;
  }
};
