import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getExchangesInfo } from "../redux/exchange/actions";

function SelectExchange({
  value,
  onChange,
  style = {},
  loading = false,
  disabled = false,
}) {
  const {
    exchangeState: {
      exchangesInfo,
      exchangesLoading,
      exchangesError,
      exchanges,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !Object.keys(exchangesInfo).length &&
      !exchangesLoading &&
      !exchangesError
    ) {
      dispatch(getExchangesInfo());
    }
  }, [dispatch, exchangesError, exchangesInfo, exchangesLoading]);

  return (
    <Select
      placeholder={
        exchangesLoading || loading ? "Loading..." : "Select an exchange"
      }
      value={value || "Select an exchange"}
      onChange={onChange}
      disabled={exchangesLoading || loading}
      style={style}
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
      }
    >
      {exchanges?.map((exchange) => (
        <Select.Option key={exchange.id} value={exchange.id}>
          {/*<LazyLoadImage*/}
          {/*  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${exchange.id}.svg`}*/}
          {/*  alt={exchange.name}*/}
          {/*  width="25px"*/}
          {/*  height="25px"*/}
          {/*  effect="blur"*/}
          {/*/>*/}
          {exchange.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectExchange;
