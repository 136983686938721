import { Spin, Table, Tooltip } from "antd";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import {
  capitalizeString,
  getRelativeTime,
} from "../../../utility/formatter-helper";
import SignalStrengthUi from "../../trading-assistant/SignalStrengthUi";
import { RiExchangeFundsLine } from "react-icons/ri";
import { getChartData } from "../../../redux/new-insight/actions";
import customStyles from "../../../styles/Components/new-insight/signals-section/InsightSignalsTable.module.css";
import { useNavigate } from "react-router-dom";
import { MdAccessTime } from "react-icons/md";
import useServiceStatus from "../../../hooks/useServiceStatus";
import HideContentIcon from "../../mini-app/shared/HideContentIcon";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaBrain,
  FaPauseCircle,
} from "react-icons/fa";
import AssetDisplay from "../../../ui/AssetDisplay";

const getSuggestionPercentages = (data, sources) => {
  let buyCount = 0;
  let sellCount = 0;
  let holdCount = 0;

  // Filter out "coin" from the sources
  const signalSources = sources?.filter((s) => s !== "coin");
  // console.log(data);
  let total = 0;

  // Iterate through the sources and count the suggestions
  signalSources.forEach((s) => {
    const signalData = data?.[0]?.[s];
    const action = signalData?.suggestion; // Access the suggestion field for the current source

    // check the signal has data and then increase total amount
    if (action) total++;

    if (action === "Buy") {
      buyCount += 1; // Increment buyCount
    } else if (action === "Sell") {
      sellCount += 1; // Increment sellCount
    } else if (action === "Hold") {
      holdCount += 1; // Increment holdCount
    }
  });

  return {
    buy: (buyCount / total) * 100 || 0, // Calculate buy percentage
    sell: (sellCount / total) * 100 || 0, // Calculate sell percentage
    hold: (holdCount / total) * 100 || 0, // Calculate hold percentage
  };
};

function InsightSignalsTable() {
  const {
    newInsightState: {
      signalStatus,
      signalData,
      signalTableColumns,
      chartId,
      systemStrategies,
    },
    liveSuggestion: { suggestions, status, coinStatus },
  } = useSelector((state) => state);

  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const { isActive } = useServiceStatus("insight");

  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const handleClick = async (e, val, col) => {
    if (!val.suggestion) return;
    navigate(`/insight/chart?symbol=${val.coin}`);
    // console.log(val, col);
    // await dispatch(
    //   getChartData(
    //     userData["custom:custom_username"],
    //     userData?.email,
    //     val.coin,
    //     col,
    //     chartId,
    //   ),
    // ).then(() => {
    //   console.log("navigate");
    //   navigate(`/insightv2/chart?symbol=${val.coin}`);
    // });
  };

  const handleTrade = (e, data) => {
    e.stopPropagation();
    if (!isActive) return setShowServiceActivationModal(true);
    console.log(data);
  };

  const newColumn = {
    title: "15m Signal",
    key: "liveSignal",
    dataIndex: "liveSignal",
    align: "center",
    width: 200,
    render: (value, record) => {
      const { coin } = record;
      const liveData = suggestions.find((item) => item.symbol === coin);
      console.log(liveData);

      const predictionIcon =
        liveData?.prediction === "Sell" ? (
          <FaArrowCircleDown size={18} color="#EF4B4B" />
        ) : liveData?.prediction === "Buy" ? (
          <FaArrowCircleUp size={18} color="#70AF85" />
        ) : (
          <FaPauseCircle size={18} color="#82A0D8" />
        );

      const date = liveData ? getRelativeTime(liveData?.signal_timestamp) : "";

      const cardClassName = !liveData
        ? ""
        : liveData?.prediction === "Sell"
          ? customStyles.sell
          : liveData?.prediction === "Buy"
            ? customStyles.buy
            : liveData?.prediction === "Hold"
              ? customStyles.holdBuy
              : "";

      return (
        <div
          className={`${styles.tableContent} ${styles.liveSuggestion} ${cardClassName}  ${customStyles.col}`}
        >
          {coinStatus[coin] === "loading" && !liveData ? (
            <Spin size="small" />
          ) : !liveData && coinStatus[coin] === "error" ? (
            <RiExchangeFundsLine color="grey" size={40} />
          ) : !liveData ? (
            <span>-</span>
          ) : (
            <>
              <span
                className={styles.predictionIcon}
                style={{ right: "5%", top: "5%" }}
              >
                {predictionIcon}
              </span>
              <span>{liveData?.prediction}</span>
              <span>
                Confidence:{" "}
                <span
                  style={{
                    color: `${liveData?.confidence >= 0.5 ? "inherit" : "grey"}`,
                  }}
                >
                  {(liveData?.confidence * 100).toFixed(2)}%
                </span>
              </span>
              <span>{date}</span>
              {/*<div*/}
              {/*  style={{*/}
              {/*    display: "flex",*/}
              {/*    alignItems: "center",*/}
              {/*    justifyContent: "center",*/}
              {/*    gap: ".2rem",*/}
              {/*    cursor: "pointer",*/}
              {/*    color: "#0C4CFC",*/}
              {/*    flexWrap: "wrap",*/}
              {/*  }}*/}
              {/*  onClick={(e) => {*/}
              {/*    e.stopPropagation();*/}
              {/*    // setShowLiveSuggestionModal(true);*/}
              {/*    // setLiveSuggestionTarget(item?.SYMBOL_NAME);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <FaBrain className={styles.liveBtn} color="#0C4CFC" />*/}
              {/*  <span>Run AI Now</span>*/}
              {/*</div>*/}
            </>
          )}
        </div>
      );
    },
  };

  let columns = signalTableColumns.map((column) => {
    const sampleData = signalData?.[0];
    const category = sampleData?.[column]?.other_info?.category;

    return {
      title: (
        <div>
          {category ? (
            <Tooltip title={category} color="geekblue">
              <img
                className={customStyles.categoryIcon}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_category_icons/${category}.png`}
                alt={category}
              />
            </Tooltip>
          ) : (
            ""
          )}
          {capitalizeString(column) === "Coin"
            ? "Asset"
            : capitalizeString(column)}
        </div>
      ),
      key: column,
      dataIndex: column,
      align: "center",
      width: 200,
      render: (value) => {
        if (typeof value === "object" && value !== null) {
          const { suggestion = "", strength = "" } = value;
          const hasData = !!suggestion;

          const cardClassName = !hasData
            ? ""
            : suggestion === "Sell"
              ? customStyles.sell
              : suggestion === "Buy"
                ? customStyles.buy
                : suggestion === "Hold"
                  ? customStyles.holdBuy
                  : "";
          const suggestionIcon = !hasData ? (
            ""
          ) : suggestion === "Sell" ? (
            <FaArrowCircleDown size={18} color="#EF4B4B" />
          ) : suggestion === "Buy" ? (
            <FaArrowCircleUp size={18} color="#70AF85" />
          ) : suggestion === "Hold" ? (
            <FaPauseCircle size={18} color="#82A0D8" />
          ) : (
            ""
          );

          return (
            <div
              className={`${customStyles.col} ${
                isActive ? cardClassName : hasData ? customStyles.notActive : ""
              }`}
              onClick={(e) => handleClick(e, value, column)}
              style={{ cursor: `${hasData ? "pointer" : "unset"}` }}
            >
              {hasData ? (
                <>
                  <div className={styles.insightTableCol}>
                    {isActive && (
                      <span
                        className={`${customStyles.suggestionLabel} ${
                          customStyles?.[`${suggestion}Label`]
                        }`}
                      >
                        {suggestion}
                      </span>
                    )}
                    <span className={` ${!isActive ? styles.hideContent : ""}`}>
                      {isActive ? suggestionIcon : <HideContentIcon />}
                    </span>
                    <SignalStrengthUi level={strength} />
                  </div>
                  <p className={customStyles.signalDate}>
                    <MdAccessTime />
                    {getRelativeTime(value?.date)}
                  </p>
                </>
              ) : (
                <RiExchangeFundsLine style={{ margin: "0 auto" }} size={20} />
              )}
            </div>
          );
        }
        const percentages = getSuggestionPercentages(
          signalData.filter((s) => s.coin === value),
          signalTableColumns,
        );
        return (
          <div
            className={`${styles.tableContent} ${customStyles.col}`}
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <AssetDisplay asset={value} />
            {isActive ? (
              <div className={customStyles.bar}>
                <div className={customStyles.bullets}>
                  {percentages.sell ? (
                    <span style={{ color: "#EF4B4B" }}>
                      {/*<FaCircle size={10} />*/}
                      {percentages.sell.toFixed(0)}%
                    </span>
                  ) : null}
                  {percentages.hold ? (
                    <span style={{ color: "#82A0D8" }}>
                      {/*<FaCircle size={10} />*/}
                      {percentages.hold.toFixed(0)}%
                    </span>
                  ) : null}
                  {percentages.buy ? (
                    <span style={{ color: "#70AF85" }}>
                      {/*<FaCircle size={10} />*/}
                      {percentages.buy.toFixed(0)}%
                    </span>
                  ) : null}
                </div>
                <div className={customStyles.verticalBar}>
                  <div
                    style={{
                      height: `${percentages.sell}%`,
                      backgroundColor: "#EF4B4B",
                    }}
                    className={customStyles.barSegment}
                  ></div>
                  <div
                    style={{
                      height: `${percentages.hold}%`,
                      backgroundColor: "#82A0D8",
                    }}
                    className={customStyles.barSegment}
                  ></div>
                  <div
                    style={{
                      height: `${percentages.buy}%`,
                      backgroundColor: "#70AF85",
                    }}
                    className={customStyles.barSegment}
                  ></div>
                </div>
              </div>
            ) : null}
          </div>
        );
      },
    };
  });
  // Insert the new column at the second position
  columns.splice(1, 0, newColumn);

  useEffect(() => {
    if (
      signalData.length > 0 &&
      signalStatus !== "loading" &&
      signalStatus === "idle"
    ) {
      const transformedData = signalData
        ?.sort((a, b) => {
          const bPrediction = suggestions.find(
            (s) => s.symbol === b.coin,
          )?.prediction;
          return bPrediction === "Hold" ? -1 : 1;
        })
        .map((signal) => {
          // Flatten the nested data structure for each signal
          const flattenedSignal = { coin: signal.coin };
          signalTableColumns.forEach((column) => {
            const columnData = signal[column];

            // Check if columnData is an object (e.g., "Momentum - Awesome Oscillator")
            if (typeof columnData === "object" && columnData !== null) {
              const {
                suggested_amount_percentage: amountSuggestionPercentage,
                stop_loss_percent: stopLossPercentage,
                take_profit_percent: takeProfitPercentage,
                signal_date: date,
                other_info,
              } = columnData;

              flattenedSignal[column] = {
                suggestion: columnData.suggestion,
                strength: columnData["signal strength"],
                roi: columnData["recent ROI"],
                data: {
                  amountSuggestionPercentage,
                  stopLossPercentage,
                  takeProfitPercentage,
                },
                coin: signal.coin,
                date,
                key: `${other_info?.category}/${other_info?.subcategory}/${other_info?.name}`,
                liveSignal: signal.coin,
              };
            } else {
              flattenedSignal[column] = columnData || "-";
            }
          });
          return flattenedSignal;
        });
      setTableData(transformedData);
    }
  }, [signalData, signalStatus, signalTableColumns, suggestions]);
  useEffect(() => {
    if (signalStatus === "error") {
      setTableData([]);
      columns = ["Signals"];
    }
  }, [signalStatus]);

  return (
    <div>
      <Table
        columns={columns}
        className={`${styles.table} ${customStyles.table}`}
        dataSource={tableData}
        scroll={{ y: 700 }}
        pagination={false}
        loading={signalStatus === "loading" || !systemStrategies.length}
      />

      {showServiceActivationModal && (
        <ServiceActivationModal
          service={"insai"}
          show={showServiceActivationModal}
          setShow={setShowServiceActivationModal}
        />
      )}
    </div>
  );
}

export default InsightSignalsTable;
